import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {loginUser} from "../../store/auth";
import toast from "react-hot-toast";
import PublicLayout from "../../layout/PublicLayout";
import Loader from "../../components/loader/loader";
import PublicHeader from "../../components/public-header";
import {Formik} from "formik";
import FormInput from "../../components/form/formInput";
import FormPasswordInput from "../../components/form/formPasswordInput";
import FormButton from "../../components/form/formButton";
import backImg from "../../assets/icon/arrow-back.svg";
import Footer from '../home/Footer';

const PopupLogin = ({setOpenLogin}) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const initialValues = {
        email: "",
        password: "",
        social_login: 0,
    };
    const extractSubdomain = () => {
        const hostname = window.location.hostname;
        const hostnameParts = hostname.split('.');
        return hostnameParts.length > 2 ? hostnameParts[0] : null;
    };
    const [logo, setLogo] = useState(null);
    const subdomain = extractSubdomain();
    useEffect(() => {
        if (subdomain === "zoey") {
            import('../../assets/images/logo.jpg').then(logo => {
                setLogo(logo.default);
            });
        } else {
            import('../../assets/logo/3d-logo.png').then(logo => {
                setLogo(logo.default);
            });
        }
    }, [subdomain]);
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string()
            .min(8, "Must be at least 8 characters")
            .required("Required"),
    });

    const onSubmit = async (data, {setSubmitting}) => {
        setLoading(true);
        let res = await dispatch(loginUser(data));
        setLoading(false);

        if (res?.payload?.success && res?.payload?.data?.id) {
            localStorage.setItem("token", res?.payload?.data?.token);
            setOpenLogin(false)

        } else if (res?.payload?.data?.success === false) {
            toast.error(res?.payload?.data?.status, {duration: 3000});
        }
    };

    return (
        <div className={"login-card"} >
            {loading ? <Loader fullSize/> : ''}
            <div>
                <div className={"pb-header"}>
                    <div className={'pb-logo-box'}>
                        <img src={logo} alt={'logo'}/>
                    </div>
                </div>
                <div className={"pb-hero-box"}>
                    <h3 style={{fontFamily: 'helvetica-lt-std-bold'}}>Please login.</h3>
                    <p style={{fontFamily: 'helvetica-lt-std-light'}}>
                        Please Login to view treatment form.
                    </p>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
            >
                {({
                      values,
                      isSubmitting,
                      handleSubmit,
                      errors,
                      handleChange,
                      touched, 
                  }) => {
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <FormInput
                                    name={"email"}
                                    label={"Email"}
                                    placeholder={"Type your e-mail"}
                                    errors={errors}
                                    touched={touched}
                                    isAuto={true}
                                    values={values}
                                    handleChange={handleChange}
                                />
                                <FormPasswordInput
                                    name={"password"}
                                    isAuto={true}
                                    label={"Password"}
                                    placeholder={"Type your password"}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                                <FormButton
                                    disable={loading || isSubmitting}
                                    text={loading ? "Signing In..." : "Sign In"}
                                />
                            </form>
                        </>
                    );
                }}
            </Formik>
            
        {/* <Footer /> */}
        </div>
    );
};

export default PopupLogin;