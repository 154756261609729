import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {useFormik} from "formik";
import Loader from "../../../../components/loader/loader";
import {Grid} from "@mui/material";
import FormInput from "../../../../components/form/formInput";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {storeClinicCalender} from "../../../../store/clinics/clinicSettings/clinic-calender";

const Calender = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)



    const validationSchema = Yup.object({
        client_id: Yup.string().required("Client id is Required"),
        client_secret: Yup.string().required("Client Secret is Required"),

    });
    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            client_id: '',
            client_secret: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {

            if (clinicData?.id){
                setLoading(true)
                let res = await  dispatch(storeClinicCalender({provider_id:clinicData?.id,...data}))
                if (res?.payload){
                    console.log(res?.payload)
                }
                setLoading(false)
            }
        },
    });

    useEffect(()=>{
        if (clinicData?.id && clinicData?.calender_setting?.length){
            try {
                const calenderData = clinicData?.calender_setting[0]
                setFieldValue('client_id',calenderData?.client_id || '')
                setFieldValue('client_secret',calenderData?.client_secret || '')
            }catch (e) {

            }
        }
    },[clinicData,clinicData?.id])


    return (
        loading ? <Loader/> :
            <form onSubmit={handleSubmit}>
                <Grid container p={'20px'} sx={{justifyContent:'space-between'}}>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"client_id"}
                            label={"Client id"}
                            placeholder={'Type  your Client id'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"client_secret"}
                            label={"Client Secret"}
                            placeholder={'Type  your Client Secret'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                        <SubmitButton val={"Update Calender Settings"}/>
                    </Grid>
                </Grid>
            </form>
    );
};

export default Calender;