import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import toast from "react-hot-toast";
import Loader from "../../../../components/loader/loader";
import {Avatar, Box, FormControl, FormLabel, Grid, IconButton, Tooltip,} from "@mui/material";
import FormInput from "../../../../components/form/formInput";
import CustomButton from "../../../../components/custom-button/customButton";
import SubmitButton from "../../../../components/custom-button/submitButton";
import FormAutoComplete from "../../../../components/form/FormAutoComplete";
import FormStatusInput from "../../../../components/form/FormStatusInput";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
    getClinicsGroupCategoryByID,
    storeClinicsGroupCategory,
    updateClinicsGroupCategory
} from "../../../../store/clinics/clinicSettings/gruop-category";
import { datacolor } from '../../../../components/card-appointment/AppointRescheduleDate';
import Image from "../../../../components/image/image";
import { getAllClinicsTreatmentGroup } from '../../../../store/clinics/clinicSettings/clinic-treatment-group';
// const labelStyles ={
//     color:'#000',
//     fontSize:'16px',
//     mb:'10px',
//     "&.Mui-focused": {
//         color:'#000!important',
//     }
// }

const AddTreatmentGroup = () => {
    const [loading, setLoading] = useState(false)
    const [files,setFiles] = useState([])
    const [comments,setComments] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const clinicTreatmentGroupStore = useSelector(state => state?.clinicTreatmentGroupStore)
    const user = useSelector(state => state?.authStore?.user)


    const validationSchema = yup.object({
        title: yup.string().min(3, "Name is Too Short").required('Group / Treatment  is required'),
        status: yup.string().required('Status is required'),
        parent_id: yup.array().typeError(''),
    
    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            title: '',
            slug: '',
            status: 'In-Active',
            is_show: 1,
            parent_id: null,
            description: '',
            comments:"",
            file_path: '',
            url: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)
            let finalData ={
                provider_id:params?.clinic_id,
                title:data?.title,
                slug:data?.slug,
                status : data?.status,
                is_show : data?.is_show,
                file_path: data?.file_path,
                description:data?.description,
            }

            let res
            if (data?.parent_id?.id){
                finalData['parent_id'] =  data?.parent_id?.id
            }

            if (params?.category_id) {
                finalData["id"] = data?.id
                finalData["url"] = data?.url
                res = await dispatch(updateClinicsGroupCategory(finalData))
            } else {
                res = await dispatch(storeClinicsGroupCategory(finalData))
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                if (errors?.title) {
                    setFieldTouched("title", true)
                    setFieldError("title", errors?.title)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Group / Treatment  ${params?.category_id ? "updated" : "Stored"} Successfully`, {duration: 3000})
                setComments('')
                navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/treatment-group`)

            }

            setLoading(false)
        },
    });


    useEffect(() => {
        (async () => {
            if (params?.category_id) {
                setLoading(true)
                let res = await dispatch(getClinicsGroupCategoryByID(params?.category_id))
                if (res?.payload?.data?.id) {
                    await setFieldValue("title", res?.payload?.data?.title)
                    await setFieldValue("slug", res?.payload?.data?.slug)
                    await setFieldValue('status', res?.payload?.data?.status || 'In-Active')
                    await setFieldValue('is_show', res?.payload?.data?.is_show || 0)
                    await setFieldValue('id', res?.payload?.data?.id)
                    await setFieldValue('file_path', res?.payload?.data?.file_path)
                    await setFieldValue('description', res?.payload?.data?.description)
                    await setFieldValue('url', res?.payload?.data?.url)
                    await setFieldValue('parent_id', res?.payload?.data?.parent)
                }
                setLoading(false)
            }

        })()
    }, [params?.category_id])
    useEffect(() => {
        (async () => {
            if (params?.category_id) {
                setLoading(true)
                let res = await dispatch(getClinicsGroupCategoryByID(params?.category_id))
                if (res?.payload?.data?.id) {
                    await setFieldValue("title", res?.payload?.data?.title)
                    await setFieldValue("slug", res?.payload?.data?.slug)
                    await setFieldValue('status', res?.payload?.data?.status || 'In-Active')
                    await setFieldValue('is_show', res?.payload?.data?.is_show || 0)
                    await setFieldValue('id', res?.payload?.data?.id)
                    await setFieldValue('file_path', res?.payload?.data?.file_path)
                    await setFieldValue('description', res?.payload?.data?.description)
                    await setFieldValue('url', res?.payload?.data?.url)
                    await setFieldValue('parent_id', res?.payload?.data?.parent)
                }
                setLoading(false)
            }

        })()
    }, [params?.category_id])
    useEffect(()=>{      
        (async ()=>{  
            if (!clinicTreatmentGroupStore?.treatmentGroups?.length){
                setLoading(true)
            }
            await dispatch(getAllClinicsTreatmentGroup(params?.clinic_id))
            setLoading(false)
        })()
    },[dispatch,clinicTreatmentGroupStore?.treatmentGroups?.length,params?.clinic_id])

    return (loading ? <Loader/> :
            <Grid container p={5} sx={{ '@media (max-width: 600px)':{padding:"8px", } }}>
                <Grid item xs={12} lg={8}>
                    <form onSubmit={handleSubmit}>
                        <Image
                                label={'Image'}
                                name={"file_path"}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                            />
                        <FormInput
                            name={"title"}
                            label={"Name"}
                            placeholder={'Type  Group / Treatment '}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                        {params?.category_id && ( // Conditionally render URL field for updates
                            <FormInput
                            name={"slug"}
                            label={"Slug"}
                            placeholder={'Slug'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                        )}
                        <FormAutoComplete
                            name={"parent_id"}
                            options={clinicTreatmentGroupStore?.treatmentGroups}
                            values={values}
                            errors={errors}
                            touched={touched}
                             label={'Group / Treatment'}
                            setFieldValue={setFieldValue}
                        />
                        <FormControl fullWidth sx={{mb: '16px'}}>
                            <FormLabel><b>Description</b></FormLabel>
                         <textarea
                                className="MuiInputBase-input MuiOutlinedInput-input"
                                rows={3}
                                placeholder="Enter the Description"
                                value={values.description}
                                onChange={(e) => setFieldValue('description', e.target.value)}
                                style={{
                                    width: '100%',
                                    resize: 'vertical',
                                    padding: '8px',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                    fontFamily: 'inherit',
                                    fontSize: '1rem',
                                }}
                            />
                        </FormControl>
                        {params?.category_id && ( // Conditionally render URL field for updates
                            <FormInput
                                name={"url"}
                                label={"URL"}
                                placeholder={'Enter the URL'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        )}
                        <FormControl sx={{mb: '16px'}}>
                            <FormStatusInput
                                name={"status"}
                                label={"Status"}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                                handleChange={handleChange}
                            />
                            <FormStatusInput
                                name={"is_show"}
                                label={"Is Show"}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                                handleChange={handleChange}
                            />
                        </FormControl>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{mr: '16px'}}>
                                <CustomButton
                                    mainColor={'#ED1E45'}
                                    val={'Cancel'}
                                    handleClick={() => {
                                        navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/treatment-group`)
                                    }}
                                />
                            </Box>
                            <SubmitButton val={params?.category_id ? 'Update Group / Treatment' : 'Add Group / Treatment'}/>
                        </Box>
                    </form>
                </Grid>
            </Grid>
    );
};

export default AddTreatmentGroup;