import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Checkbox,
    Divider,
    FormControl, FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {ReactComponent as BackArrow} from '../../../assets/icon/arrow-back.svg'
import PayByCard from "../payByCard";
import {registerUser} from "../../../store/auth";
import {bookCustomerAppointment, bookCustomerPlanAppointment, findGuestUser} from "../../../store/booking-widget";
import BookingTimer from "../../../components/timer/timer";
import Loader from "../../../components/loader/loader";
import Link from '@mui/material/Link';
import ContactForm from "../ContactForm";
import PlanConfirmation from "./planConfirmation";
import Waveloading from '../../loading/Waveloading';
export const bookingInput ={
    ".MuiInputBase-input": {
        borderColor: " #525252",
        height: "15px",
        minHeight: "unset",
        "&:focus": {
            borderColor: "#525252!important",
            outline: "none",
        }
    },
}
const PlanBookingContact = ({handleReset,provider,providerBookingSettings,selectedPlan,servicesSelectedDate}) => {
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState('')
    const [registerData, setRegisterData] = useState(null)
    const [active, setActive] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const [selectedMethods, setSelectedMethods] = useState([])


    const dispatch = useDispatch()
    const authStore = useSelector(state => state?.authStore)

    const {user} = authStore



    const getSubTotal = () => {
        let total = 0
        if (user?.id && user?.is_membered === 1) {
            total =  parseFloat(selectedPlan?.member_price)
        } else {
            total =  parseFloat(selectedPlan?.regular_price)
        }
        return total
    }
    const isPercentage = providerBookingSettings?.default_tax_in_percentage === 1
    const getFinalTax = () => {
        let total = getSubTotal()
        if (isPercentage) {
            return parseFloat(((total / 100) * parseFloat(providerBookingSettings?.tax_booking_amount)).toFixed(2))
        } else {
            return parseFloat(providerBookingSettings?.tax_booking_amount)
        }
    }
    const getFinalTotal = () => {
        let total = getSubTotal()
        let totalTax = getFinalTax()

        return parseFloat(total) + parseFloat(totalTax)
    }

    const handlePaidBooking = async () => {
        console("booking fixed")
        setLoading(true)

        let token = ''

        if (user?.id) {
            token = localStorage.getItem('token')
        } else {
            let res = await dispatch(registerUser(registerData));
            token = res?.payload?.data?.code
        }

        await handleBookAppointment(token)
        setLoading(false)

    }

    const handleBookAppointment = async (token, cardToken) => {
        setLoading(true)


        let bookingPayload = {
             provider_id: provider?.id,
            plan_id:selectedPlan?.id,
            token:token,
            bookings:servicesSelectedDate,
        }


        let payAtClinic
        let payUsingWallet

        provider?.provider_payment?.forEach((item) => {
            if (item?.payment_method?.name === 'Pay At Clinic' && item?.payment_method?.id === 1) {
                payAtClinic = item?.payment_method?.id
            }
            if (item?.payment_method?.name === 'Use My wallet Point' && item?.payment_method?.id === 2) {
                payUsingWallet = item?.payment_method?.id
            }
        })

        let invoices = []


            const isCheck = checkWalletMethod() && user?.id && (getSubTotal() + getFinalTax()) > parseFloat(user?.wallet_point)
            selectedMethods.forEach((item) => {
                if (item?.payment_method?.name === 'Use My wallet Point' && item?.payment_method?.id === 2) {
                    if (isCheck) {
                        invoices.push({
                            "payment_method_id": item?.payment_method_id,
                            "amount": parseFloat(user?.wallet_point),
                            // "tax":  calculateTax(parseFloat(user?.wallet_point))
                        })
                    } else {
                        invoices.push({
                            "payment_method_id": item?.payment_method_id,
                            "amount": getFinalTotal(),  
                            // "tax": getFinalTax()
                        })
                    }
                } else if (item?.payment_method?.name === 'Pay At Clinic' && item?.payment_method?.id === 1) {
                    invoices.push({
                        "payment_method_id": item?.payment_method_id,
                        "amount": isCheck ? (getSubTotal() + getFinalTax()) - parseFloat(user?.wallet_point) : getFinalTotal(),
                        // "tax": isCheck ? calculateTax((getSubTotal() + getFinalTax()) - parseFloat(user?.wallet_point)) :getFinalTax(),
                    })
                } else if (item?.payment_method?.id === 4 ) {
                    invoices.push({
                        "payment_method_id": item?.payment_method_id,
                        "amount": isCheck ? (getSubTotal() + getFinalTax()) - parseFloat(user?.wallet_point) : getFinalTotal(),
                        // "tax": isCheck ? calculateTax((getSubTotal() + getFinalTax()) - parseFloat(user?.wallet_point)) :getFinalTax(),
                        token: cardToken
                    })
                } else if (item?.payment_method?.id === 3) {
                    invoices.push({
                        "payment_method_id": item?.payment_method_id,
                        "amount": isCheck ? (getSubTotal() + getFinalTax()) - parseFloat(user?.wallet_point) : getFinalTotal(),
                        // "tax": isCheck ? calculateTax((getSubTotal() + getFinalTax()) - parseFloat(user?.wallet_point)) :getFinalTax(),
                        token: cardToken
                    })
                }
            })



        // }else {
        //     invoices.push({"payment_method_id": payAtClinic, "amount": 0, "tax": 0})
        // }

        bookingPayload['invoices'] = invoices


        let res = await dispatch(bookCustomerPlanAppointment(bookingPayload))
        if (res?.payload?.success === 'true') {
            setActive('confirmation')
            setConfirmation(res?.payload?.data)
            handleReset()
            toast.success(res?.payload?.message, {duration: 4000})
        } else {
            const errors = res?.payload?.errors
            console.log(errors) 
            if (errors){
                toast.error(errors,{duration:5000})
            }   
        }
        setLoading(false)
    }

    const handleNextClick = (val) => {
        setActive(val)
    }

    const findGuest = async (val) => {

        let res = await dispatch(findGuestUser({email: val}))

        if (res?.payload?.success === 'true') {
            return res?.payload?.data
        }else {
            return null
        }
    }

    const handleSubmitForm = async (data) => {
        setLoading(true)

        let token = ''

        if (user?.id) {
            token = localStorage.getItem('token')
        } else {
            let finalData = registerData ||  data
            let res = await findGuest(finalData?.email)
            if (res?.token){
                token = res?.token
            }else {
                let res = await dispatch(registerUser({...finalData, default_provider_id: provider?.id}));
                token = res?.payload?.data?.code
            }
        }

        await handleBookAppointment(token)

    }
    const checkWalletMethod = () => {
        return selectedMethods?.length > 0 && selectedMethods.some((method) => method?.payment_method_id === 2)
    }
    const handleCheckbox = (item, e) => {

        const {checked} = e.target
        if (checked) {

            if (checkWalletMethod() && user?.id && (getSubTotal() + getFinalTax()) > parseFloat(user?.wallet_point)) {
                let res = selectedMethods.filter((method) => method?.payment_method_id === 2)

                setSelectedMethods([...res, item])
            } else {
                setSelectedMethods([item])
            }
        } else {
            setSelectedMethods(selectedMethods.filter((row) => row?.id !== item?.id))
        }
    }

    const isChecked = (item, selected) => {
        const found = selected.find((select) => select.id === item?.id);
        return !!found;
    }
    const bookingNow = () => (
        <Grid container>
            <Grid item xs={12} sx={{mt: "16px", mb: '24px'}}>
                <Typography sx={{color: '#666666'}}>
                    Refunds and cancellations are subject to the
                    <Link href="https://3dlifestyle.ca/cancellation-policy/">
                    <Typography
                        as={"span"}
                        sx={{
                            color: "#006AFF",
                            fontWeight: "bold",
                            mx: "3px",
                            cursor: 'pointer',
                            "&:hover": {textDecoration: "underline"}
                        }}
                    >
                        cancellation policy
                    </Typography>
                    </Link>
                    of {provider?.name}.
                    Please contact {provider?.name} to learn more about their privacy practices.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12} sx={{my: "24px",}}>
                <Typography sx={{color: "#666666"}}>
                    Upon booking, 3D lifestyle will automatically create an account for you with {` `}
                    <Link href="https://www.3Dlifestyle.ca" target="_blank" rel="noopener noreferrer">
                        3Dlifestyle.ca
                    </Link>.
                    You can sign back into 3D Lifestyle using your email address at any time.
                </Typography>
            </Grid>
            <Grid xs={12} item>
                {user?.id ?
                    <Button
                        fullWidth
                        onClick={() => handleSubmitForm()}
                        variant={"contained"}
                        sx={{backgroundColor: '#E6DAD1',color:'#000', height: '48px',
                        "&:hover": {
                            backgroundColor: "#E6DAD1",
                            color:'#000'
                          },}}
                    >
                        Book appointment
                    </Button> :
                    <Button
                        fullWidth
                        variant={"contained"}
                        type={'submit'}
                        sx={{backgroundColor: '#E6DAD1',color:'#000', height: '48px',
                        "&:hover": {
                            backgroundColor: "#E6DAD1",
                            color:'#000'
                          },}}
                    >
                        Book appointment
                    </Button>
                }
            </Grid>
            <Grid item xs={12} sx={{mt: "24px",}}>
                <Typography sx={{color: "#666666"}}>
                    By creating this appointment, you acknowledge you will receive automated
                    transactional messages from this merchant.
                </Typography>
            </Grid>
        </Grid>
    )
    const refundPolicy = () => (
        <Grid container>
            <Grid item xs={12} sx={{mt: "16px", mb: '24px'}}>
                <Typography sx={{color: '#666666'}}>
                    Refunds and cancellations are subject to the
                    <Link href="https://3dlifestyle.ca/cancellation-policy/">
                    
                    <Typography
                        as={"span"}
                        sx={{
                            color: "#006AFF",
                            fontWeight: "bold",
                            mx: "3px",
                            cursor: 'pointer',
                            "&:hover": {textDecoration: "underline"}
                        }}
                    >
                        cancellation policy
                    </Typography>
                    </Link>
                    of {provider?.name}.
                    Please contact {provider?.name} to learn more about their privacy practices.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12} sx={{my: "24px",}}>
                <Typography sx={{color: "#666666"}}>
                    Upon booking, 3D lifestyle will automatically create an account for you with {` `}
                    <Link href="https://www.3Dlifestyle.ca" target="_blank" rel="noopener noreferrer">
                        3Dlifestyle.ca
                    </Link>.
                    You can sign back into 3D Lifestyle using your email address at any time.
                </Typography>
            </Grid>
        </Grid>
    )
    const displayPaid = () =>  (
        <Grid container>
            <Grid item xs={12} sx={{mt: "16px", mb: '24px'}}>
                <Typography
                    variant={'h3'}
                    sx={{fontWeight: 'bold', mb: '24px', fontSize: '22px', color: 'black'}}
                >
                    Pay your appointment 
                </Typography>
                <Typography sx={{color: "#666666"}}>
                    A payment of ${getFinalTotal()} is required to book your appointment.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12} sx={{my: '16px'}}>
                <Box  sx={{display: 'flex', justifyContent: "space-between", mt: '8px'}}>
                    <Box>
                        <Typography sx={{color: '#000000', fontSize: '14px'}}>
                            {selectedPlan?.description}
                        </Typography>
                        <Typography sx={{color: "#666666", mt: '8px', fontSize: '14px'}}>
                            {user?.is_membered === 1  ? "Member" : "Regular"}
                        </Typography>
                    </Box>
                    <Typography sx={{color: "#000000"}}>
                        { user?.is_membered === 1 ? "$" + selectedPlan?.member_price : "$" + selectedPlan?.regular_price}
                    </Typography>
                    </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>  
            <Grid item xs={12} sx={{my: '16px'}}>
                <Box sx={{display: 'flex', justifyContent: "space-between", mt: '8px'}}>
                    <Box>
                        <Typography sx={{color: '#000000', fontSize: '14px'}}>
                            Subtotal
                        </Typography>
                    </Box>
                    <Typography sx={{color: "#000000"}}>
                        ${getSubTotal()}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: "space-between", mt: '8px'}}>
                    <Box>
                        <Typography sx={{color: '#000000', fontSize: '14px'}}>
                            Taxes {providerBookingSettings?.default_tax_in_percentage === 1 ? " in % " : ''}
                        </Typography>
                    </Box>
                    <Typography sx={{color: "#000000"}}>
                        ${getFinalTax()}  n
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: "space-between", mt: '34px'}}>
                    <Box>
                        <Typography sx={{color: '#000000', fontSize: '16px', fontWeight: '600'}}>
                            Total
                        </Typography>
                    </Box>
                    <Typography sx={{color: "#000000", fontSize: '16px', fontWeight: '600'}}>
                        ${getFinalTotal()}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} my={"16px"}>
                <Divider/>
            </Grid>  
            <Grid xs={12} item>
                {user?.id ?  
                    <Button
                        fullWidth
                        onClick={() => handleNextClick('pay')}
                        variant={"contained"}
                        sx={{backgroundColor: '#E6DAD1', height: '48px',  "&:hover": {
                            backgroundColor: "#E6DAD1",
                            color:'#000'
                          },}}
                    >
                        Continue
                    </Button>
                    :
                    <Button
                        fullWidth
                        variant={"contained"}
                        type={'submit'}
                        sx={{backgroundColor: '#E6DAD1', height: '48px',
                        "&:hover": {
                            backgroundColor: "#E6DAD1",
                            color:'#000'
                          },}}
                    >
                        Continue
                    </Button>}
            </Grid>
        </Grid>  
    )

    return (
        <Box>
            {loading ? <Waveloading fullSize/> : ''}
            {
                active === 'confirmation' ? <Box sx={{
                    position: "fixed",
                    left: "0",
                    right: '0',
                    bottom: '0',
                    top: '0',
                    zIndex: "1111!important",
                    background: '#fff',
                }}>
                    <PlanConfirmation
                        setConfirmation={setConfirmation}
                        handleReset={handleReset}  
                        confirmation={confirmation}
                    />
                </Box> : <Box>
                    {active === 'pay' &&
                        <Box sx={{width: 'max-content', mb: '16px', cursor: "pointer"}} onClick={() => setActive('')}>
                            <BackArrow/>
                        </Box>}
                    <Typography
                        variant={'h3'}
                        sx={{fontWeight: 'bold', mb: '24px', fontSize: '22px', color: 'black'}}
                    >
                        You’re nearly done. {active === 'pay' ? `Proceed your $${getFinalTotal()} Payment.` : "Enter your details below."}
                    </Typography>
                    <BookingTimer handleReset={handleReset}/>
                    {active === '' ?
                        <>
                            {
                                user?.id ?
                                    <Box>
                                        <Grid container sx={{justifyContent: "space-between"}}>
                                            <Grid item xs={12} md={5.7} sx={{mt: '16px'}}>   
                                                <FormControl fullWidth>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"  
                                                        sx={bookingInput}
                                                        disabled
                                                        value={user?.name}
                                                    />
                                                </FormControl>
                                            </Grid>   
                                            <Grid item xs={12} md={5.7} sx={{mt: '16px',}}>
                                                <FormControl fullWidth>
                                                    <Box style={{display: "flex", alignItems: "flex-end"}}>

                                                        <TextField
                                                            fullWidth
                                                            disabled
                                                            variant="outlined"
                                                            sx={{
                                                                ...bookingInput,

                                                            }}
                                                            value={user?.phone}
                                                        />
                                                    </Box>  
                                                </FormControl>  
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{justifyContent: "space-between", mt: '16px'}}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        variant="outlined"
                                                        sx={bookingInput}
                                                        value={user?.email}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{mt: "16px"}}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"   
                                                        name={"description"}
                                                        sx={bookingInput}
                                                        placeholder={"Appointment notes (optional)"}
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        minRows={4}
                                                        multiline
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    : <ContactForm
                                        handleNext={()=> {
                                            handleNextClick('pay')
                                        }}
                                        setLoading={setLoading}
                                        isPaid={1}
                                        setRegisterData={setRegisterData}
                                        handleSubmitForm={handleSubmitForm}
                                        bookingNow={displayPaid}
                                    />  
                            }
                            { user?.id ? displayPaid():""}
                        </>
                        : active === 'pay' ?
                            <Box>
                                <Grid item xs={12} sx={{mt: "16px", mb: '24px'}}>
                                    {checkWalletMethod() && user?.id && (getSubTotal() + getFinalTax()) > parseFloat(user?.wallet_point) &&
                                        <Typography sx={{mb: '16px', color: 'red'}}>
                                            Your wallet balance less then total amount.
                                            So also choose another way to pay
                                            ${(getSubTotal() + getFinalTax()) - parseFloat(user?.wallet_point)}
                                        </Typography>
                                    }
                                    {provider?.provider_payment.map((item, index) => {
                                        if (item?.status === 1) {
                                            if ((user?.id && item?.payment_method?.id === 2 && user?.wallet_point && parseFloat(user?.wallet_point) > 0) || item?.payment_method?.id !== 1 && item?.payment_method?.id !== 2 && item?.payment_method?.id < 5) {
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        sx={{mr: "24px"}}
                                                        label={item?.payment_method?.name}
                                                        control={
                                                            <Checkbox
                                                                checked={isChecked(item, selectedMethods)}
                                                                onChange={(e) => handleCheckbox(item, e)}
                                                            />
                                                        }
                                                    />
                                                )
                                            }
                                        }
                                    })}
                                </Grid>
                               
                                {selectedMethods?.length > 0 &&
    <Grid item xs={12}>
        {console.log('selectedMethods:', selectedMethods)}
        {console.log('Gateway methods count:', selectedMethods.filter(item => item?.payment_method?.is_gateway === 1).length)}
        {selectedMethods.some((item) => item?.payment_method?.is_gateway === 1) ?
        <>
            <PayByCard
                refundPolicy={refundPolicy}
                selectedMethods={selectedMethods}
                provider={provider}
                user={user}
                setLoading={setLoading}
                registerData={registerData}
                handleBookAppointment={handleBookAppointment}
            /> 
            {console.log('Rendering PayByCard')}
        </>
        



         :
            <Box>
                {refundPolicy()}
                <Button
                    fullWidth
                    onClick={() => handlePaidBooking()}
                    variant={"contained"}
                    type={"submit"}
                    sx={{backgroundColor: '#E6DAD1',color:'#000', height: '48px',
                    "&:hover": {
                        backgroundColor: "#E6DAD1",
                        color:'#000'
                      },}}
                >
                    Book Appointment
                </Button>
            </Box>
        }
    </Grid>
}
                            </Box> : ""
                    }
                </Box>
            }
        </Box>
    );
}

export default PlanBookingContact;