import React, {useEffect} from "react";
import {Box, Drawer, Typography, useMediaQuery, useTheme} from "@mui/material";
import logo from "../../assets/logo/3d-logo.png";
import { Link } from "react-router-dom";
import {ReactComponent as User } from "../../assets/logo/user.svg";
import {ReactComponent as Lock } from "../../assets/logo/Lock.svg";
import {ReactComponent as Calender } from "../../assets/logo/calendar.svg";
import {ReactComponent as MemberGroup } from "../../assets/logo/member-group.svg";
import {ReactComponent as Headphone } from "../../assets/logo/headphones.svg";
import {ReactComponent as Notifications } from "../../assets/logo/notifications-outline.svg";
import {ReactComponent as Users } from "../../assets/logo/users.svg";
import {ReactComponent as Wallet } from "../../assets/logo/wallet.svg";
import {ReactComponent as Location } from "../../assets/logo/location-outline.svg";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useLocation, useNavigate } from "react-router";
import { removeUser } from "../../store/auth";
import {useDispatch, useSelector} from "react-redux";
import { MdPowerSettingsNew,} from "react-icons/md";
import { confirmation } from "../../services/utills";
import {scrollCSS} from "../../App";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {CustomDrawer, drawerWidth} from "./adminSidebar";
import Pusher from "pusher-js";
import {getAllUserNotification} from "../../store/user/notification";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { getAllMessages, ReadMessageuser } from "../../store/user/Chat-modue";

const sideData = [
    {
        name: "My Account",
        logo: <User  style={{ fill: 'currentColor' }} />,
        url: "/user/my-account",
    },
    {
        name: "Inbox",
        count:"inbox",
        logo: <ForwardToInboxIcon  style={{ fill: 'currentColor' }} />,
        url: "/user/inbox",
    },
    { name: "Password",
        logo: <Lock style={{ stroke: 'currentColor' }} />,
        url: "/user/password" },
    {
        name: "Default Spa Location",
        logo: <Location style={{ stroke: 'currentColor' }} />,
        url: "/user/default-spa-location",
    },
    {
        name: "Notification / Alerts",
        count:"notification",
        logo: <Notifications  style={{ stroke: 'currentColor' }} />,
        url: "/user/notification",
    },
    { name: "My Wallet", logo:  <Wallet   style={{ stroke: 'currentColor' }}/>,  url: "/user/wallet" },
    { name: "My Membership", logo: <MemberGroup   style={{ stroke: 'currentColor' }} />,  url: "/user/membership" },
    { name: "My Booking", logo: <Calender  style={{ stroke: 'currentColor' }}  />, url: "/user/bookings" },
    { name: "MileStones", logo: <AssignmentIndIcon  style={{ stroke: 'currentColor' }}  />,  url: "/user/milestone" },
    { name: "My Treatment Plan", logo: <Calender  style={{ stroke: 'currentColor' }}  />,  url: "/user/plans/listing" },
    { name: "Refer/Share", logo: <Users  style={{ stroke: 'currentColor' }}/>, url: "/user/refer/share" },
    { name: "Support", logo: <Headphone  style={{ stroke: 'currentColor' }}/>,  url: "/user/support" },

];

const UserSidebar = ({ sidebarOpen, mobileOpen, setMobileOpen }) => {
    const unreadCounter = useSelector(state => state?.userNotificationStore?.unreadCounter);
    const unreadCounterr = useSelector(state => state?.UserChatStore?.unreadCounter);

    const authStore = useSelector(state => state?.authStore);
    const { user } = authStore;

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { pathname } = location;

    const handleLogout = async () => {
        if (await confirmation("Are you want to logout", "Yes, Logout!")) {
            localStorage.removeItem("token");
            dispatch(removeUser());
            document.cookie.split(";").forEach(cookie => {
                const name = cookie.split("=")[0];
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            });
            navigate("/login");
            window.location.reload();
        }
    };

    const handleNavigate = (url) => {
        setMobileOpen(false);
        navigate(url);
        dispatch(ReadMessageuser(user?.id));
    };

    const handleButtonClick = (val) => {
        navigate(val);
    };

    useEffect(() => {
        const pusher = new Pusher('5e9dcc6e9cd79dc83685', {
            cluster: 'mt1'
        });

        const channel = pusher.subscribe(`notifications.user.${user?.id}`);
        channel.bind('pusher:subscription_succeeded', () => { });
        channel.bind("App\\Events\\NotificationUserUnreadCountEvent", () => {
            dispatch(getAllUserNotification());
        });

        return () => {
            pusher.unsubscribe(`notifications.user.${user?.id}`);
        };
    }, [dispatch, user?.id]);

    useEffect(() => {
        const pusher = new Pusher('5e9dcc6e9cd79dc83685', {
            cluster: 'mt1'
        });

        const chatChannel = pusher.subscribe(`customer.chat.unread.${user?.id}`);
        chatChannel.bind('pusher:subscription_succeeded', () => {
            console.log('Successfully subscribed to chat channel');
        });
        chatChannel.bind("App\\Events\\CustomerMessageUnreadCountEvent", (data) => {
            console.log('Chat message event received:', data);
            if(user){
                dispatch(getAllMessages(user?.provider_id))
            }
           
        });

        return () => {
            pusher.unsubscribe(`customer.chat.unread.${user?.id}`);
        };
    }, [dispatch, user?.id, user?.provider_id]);

    useEffect(() => {
        dispatch(getAllUserNotification());
        dispatch(getAllMessages(user?.provider_id));
    }, [dispatch, user?.provider_id]);

    useEffect(() => {
        if (pathname === '/user/my-account') {
            setMobileOpen(true);
        }
    }, [pathname, setMobileOpen]);

    const drawer = () => (
        <Box
            sx={{
                borderRight: "1px solid #dee2e6",
                height: "100vh",
                padding: { sm: '16px 20px', xs: '10px' },
                overflowY: "auto",
                // Add your scrollCSS here if needed
            }}
        >
            <Box
                sx={{
                    mb: sidebarOpen || isMdScreen ? "25px" : "90px",
                }}
            >
                {sidebarOpen || isMdScreen ? (
                    <Link to={"/"}>
                        <img src={logo} alt={"logo"} height={"fit-content"} width={"100%"} />
                    </Link>
                ) : <></>}
            </Box>
            <Box>
                <List
                    sx={{
                        width: "100%",
                        ".MuiListItemIcon-root ": {
                            minWidth: "37px",
                        },
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {sideData?.map((item, index) => (
                        <ListItemButton
                            key={index}
                            onClick={() => handleNavigate(item?.url)}
                            sx={{
                                borderRadius: "7px",
                                mb: "7px",
                                color: item?.url === pathname ? "#9d7658" : "#000",
                                "&:hover": {
                                    backgroundColor: 'transparent',
                                    fontWeight: 'bold',
                                    color: "#9d7658",
                                },
                            }}
                        >
                            <ListItemIcon>{item?.logo}</ListItemIcon>
                            {(sidebarOpen || isMdScreen) && <ListItemText primary={item?.name} />}
                            {
                                item?.count === "notification" && unreadCounter > 0 && (
                                    <Typography
                                        sx={{
                                            flex: 'unset',
                                            color: '#fff',
                                            background: "#FF2D32",
                                            minWidth: '18px',
                                            height: '18px',
                                            borderRadius: '50%',
                                            fontSize: '10px',
                                            textAlign: 'center',
                                            p: '1.8px 0'
                                        }}
                                    >
                                        {unreadCounter}
                                    </Typography>
                                )
                            }

                            {
                                item?.count === "inbox" && unreadCounterr > 0 && (
                                    <Typography
                                        sx={{
                                            flex: 'unset',
                                            color: '#fff',
                                            background: "#FF2D32",
                                            minWidth: '18px',
                                            height: '18px',
                                            borderRadius: '50%',
                                            fontSize: '10px',
                                            textAlign: 'center',
                                            p: '1.8px 0'
                                        }}
                                    >
                                        {unreadCounterr}
                                    </Typography>
                                )
                            }

                        </ListItemButton>
                    ))}
                    <ListItemButton
                        onClick={handleLogout}
                        sx={{
                            borderRadius: "7px",
                            "&:hover": {
                                backgroundColor: '#fff',
                                color: "#2F80ED",
                            },
                        }}
                    >
                        <ListItemIcon>
                            <MdPowerSettingsNew style={{ fontSize: "1.5rem" }} />
                        </ListItemIcon>
                        {(sidebarOpen || isMdScreen) && <ListItemText primary="Logout" />}
                    </ListItemButton>
                </List>
            </Box>
        </Box>
    );

    return (
        <Box>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(!mobileOpen)}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: '70%', sm: drawerWidth } },
                }}
            >
                <Box>
                    {drawer()}
                </Box>
            </Drawer>
            <CustomDrawer
                variant="permanent"
                open={sidebarOpen}
                sx={{
                    display: { xs: 'none', md: 'block' },
                }}
            >
                {drawer()}
            </CustomDrawer>
        </Box>
    );
};

export default UserSidebar;
