import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders} from "../../../../lib/apiHeader";


export const getAllClinicsPlan = createAsyncThunk('getAllClinicsPlan', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicPlansStore; 
    console.log("page value",currentPage)
    try {
        const response = await axios.get(apis.get_plan+`${provider_id}&page=${currentPage}&limit=${pageLimit}`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){   
            return response?.data
        }
        if (response?.data?.success === false) {

            return {
                data:  response.data,           
                errors: true  
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {   
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

// get_plan
// export const getclinicplan = createAsyncThunk('getclinicplan', async (provider_id,{dispatch,getState}) => {
//     const { search, pageLimit, currentPage } = getState().clinicRoomsStore; 
//     try {

//         const response = await axios.get(apis.get_clinic_staff+`${provider_id}&page=${currentPage}&limit=${pageLimit}`, {
//             headers: apiHeaders()
//         })

//         if (response?.data?.success ==='true'){
//             return response?.data?.data
//         }
//         if (response?.data?.success === false) {
//             return {
//                 data:  response.data,
//                 errors: true
//             }
//         }

//     } catch (err) {
//         if (err?.response?.status !== 200) {
//             return {
//                 data: [],
//                 errors: err?.response?.data?.errors
//             }
//         }
//     }
// })

export const storeClinicsPlan = createAsyncThunk('storeClinicsPlan', async (data, {dispatch}) => {
    try {
        const response = await axios.post(apis.store_clinics_plan, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.success ==='true'){
          await  dispatch( getAllClinicsPlan(data.provider_id))  
            toast.success("Plan Added Successfully",{duration:3000})
        }
        return  response?.data
  
    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})   

            return err?.response?.data
        }
        if (err?.response?.status!==200){

            return {   
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getClinicsPlanByID = createAsyncThunk('getClinicsPlanByID', async (id,dispatch) => {
    try {
        const response = await axios.get(apis.get_clinics_plan_by_id+id,{
            headers: apiHeaders()
        })

        return  response?.data
    }catch (err){    
        if (err?.response?.status!==200){   
            return {
                data : [],   
                errors:err?.response?.data?.errors
            }  
        }
    }
})

export const updateClinicsPlan = createAsyncThunk('updateClinicsPlan', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_plan , {...data},{
            headers: apiHeaders()
        })    

        if (response?.data?.success==='true'){      
            await dispatch(getAllClinicsPlan(data?.provider_id))      
            toast.success("Plan Updated Successfully",{duration:3000})  

            return  response.data
        }else {
            return response?.data
        }   
    }catch (err){   
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }   
        if (err?.response?.status!==200){  
            return {
                data :err?.response,      
                errors:true     
            }      
        }
    }
})
export const deleteClinicsPlan = createAsyncThunk('deleteClinicsPlan', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinics_plan+data?.id,{
            headers: apiHeaders()   
        })
   
        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsPlan(data?.provider_id))
            toast.success("Clinic Plan Deleted Successfully",{duration:3000})   

        }else {
            console.log(response?.data)
        }   

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const updateplan = createAsyncThunk('updateplan', async (data) => {
    return data     
  })
export const clinicsPlanSlice = createSlice({
    name: 'clinicsPlanSlice',
    initialState: {
        plans: [],
        meta: {},
        links: {},
        pageLimit:10,  
        currentPage:1,   
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllClinicsPlan.fulfilled, (state, action) => {
            state.plans = action.payload.data
            state.meta = action.payload.meta
            state.links = action.payload.links
            state.currentPage = action.payload?.meta?.current_page;
        })
        builder.addCase(updateplan.fulfilled, (state, action) => {
            const { search, pageLimit, currentPage } = action.payload; 
            if (pageLimit){  
                state.pageLimit = pageLimit ;
            } 
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
           
        });
    }
})


export default clinicsPlanSlice.reducer