import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateRangePicker from "../date-range/DatePicker";
import DateRange from "../date-range/date-range";
import { Navigate, useNavigate } from "react-router";
import moment from "moment";

const Test = (props, onDatesChange) => {
  const [localStartDate, setLocalStartDate] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(new Date());

 
  const setCurrentMonthDates = () => {
    const now = new Date();
    const startOfWeek = moment().startOf('isoWeek').toDate();
    const endOfWeek = moment().endOf('isoWeek').toDate();

    setLocalStartDate(startOfWeek);
    setLocalEndDate(endOfWeek );
  };

 
  useEffect(() => {
    setCurrentMonthDates();
  }, []);

 
  const handleDateChange =async (start, end) => {
    setLocalStartDate(start);
    setLocalEndDate(end);
  
    const response = await props.fetchData(start, end);
  };

  // const handleButtonClick = async () => {
  
  // };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/crm/sale-report", {
      state: {
        value: "No Show",
        startDate: localStartDate,
        endDate: localEndDate,
      },
    });
  };
  const handleClickcrm = () => {
    navigate("/crm/sale-report", {
      state: { value: "CRM", startDate: localStartDate, endDate: localEndDate },
    });
  };
  const handleClickwebsite = () => {
    navigate("/crm/sale-report", {
      state: {
        value: "Self",
        startDate: localStartDate,
        endDate: localEndDate,
      },
    });
  };


  const handleClickRepeated= () => {
    navigate("/crm/sale-report", {
      state: {
        value: "RepeatedBooking",
        startDate: localStartDate,
        endDate: localEndDate,
      },
    });
  };
  const handleClicknew= () => {
    navigate("/crm/sale-report", {
      state: {
        value: "NewBooking",
        startDate: localStartDate,
        endDate: localEndDate,
      },
    });
  };
  //  
  const {
    totalNewBooking,
    newBookingtitle,
    noShowTotal,
    noShowTitle,
    cancelationsTitle,
    cancelationsTotal,
    completeTitle,
    completeTotal,
    newMembersTotal,
    bookingRate,
    bookingRateTitle,
    newMemberTitle,
    completedTotal,
    completeTotalTitle
  } = props;
  return (
    <div>
      <Box sx={{ padding: { lg: "60px 20px", sm: "20px 5px" } }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "helvetica-lt-std-bold",
            ml: { xl: 2, lg: 2, sm: 2, xs: 2 },
            mt:{xs:3},
          }}
        >
          Booking overview

        </Typography>
        <Box sx={{ marginLeft: "9px" }}>
          <Box
            sx={{
              marginLeft: "9px",
              mt:{xs:3},
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: { lg: "center", xs: "start" },
              "@media (min-width:1600px)": { flexDirection: "row" },
            }}
          >
            <DateRange
              color="#EDF2F7"
              widthDash="100%"
              smWidth="70%"
              setStartDate={setLocalStartDate}
              setEndDate={setLocalEndDate}
              startDate={localStartDate}
              endDate={localEndDate}
              onChange={handleDateChange} 
            />
            {/* <Button
              sx={{
                height: "fit-content",
                "@media (min-width:1600px)": { marginTop: "0" },
                mt: 2,
                width: "fit-content",
              }}
              variant="contained"
              onClick={handleButtonClick}
            >
              Fetch Report
            </Button> */}
          </Box>

        </Box>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            px: 2,
          }}
        >
          <Grid
            item
            lg={3.8}
            sm={5.9}
            xs={12}
            sx={{
              background: "#E8EAF6",
              p: 6,
              cursor: "pointer",
              borderRadius: "16px",
              mt: { xl: 0, lg: 2, sm: 2, xs: 2 },
            }}
            onClick={handleClickcrm}
          >
            <Box align="center">
              <Typography
                variant="h3"
                sx={{ color: "#283593", fontFamily: "helvetica-lt-std-bold" }}
              >
                {totalNewBooking}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#283593", fontFamily: "helvetica-lt-std-bold" }}
              >
                {newBookingtitle}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={3.8}
            sm={5.9}
            xs={12}
            sx={{
              background: "#E8F5E9",
              p: 6,
              cursor: "pointer",
              borderRadius: "16px",
              mt: { xl: 0, lg: 2, sm: 2, xs: 2 },
            }}
            onClick={handleClickwebsite}
          >
            <Box align="center">
              <Typography
                variant="h3"
                sx={{ color: "#2E7D32", fontFamily: "helvetica-lt-std-bold" }}
              >
                {completeTotal}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#2E7D32", fontFamily: "helvetica-lt-std-bold" }}
              >
                {completeTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={3.8}
            sm={5.9}
            xs={12}
            sx={{  background: "#F6F9FB", p: 6,  cursor:"pointer" ,borderRadius: "16px",  border:'1px solid #e0494973',
            position:'relative', mt:{xl:0,lg:2, sm:2, xs:2} }}
            onClick={handleClicknew}
          >
            <Box align="center">
              <Typography
                variant="h3"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {completedTotal}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {completeTotalTitle}
              
              </Typography>
              <Typography variant="body2" 
              sx={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                background:'#ff1361',
                backgroundImage: 'linear-gradient(-225deg, #ff1361 0%, #44107a 29%, #ff1361 67%, #fff800 100%)',
                backgroundSize: '200% auto',
                color: 'transparent',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                animation: 'textclip 2s linear infinite',
                fontSize: '19px',
                "@keyframes textclip": {
                  to: {
                    backgroundPosition: '200% center',
                  },
                },
              }}
              >New</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            px: 2,
          }}
        >
          <Grid item xl={2.9} lg={5.9} sm={5.9} xs={12}>
            <Box
              //  onClick={()=>Navigate('/crm/appointments',{state:{value:'NoShow'}})}
              onClick={handleClick}
              sx={{
                background: "rgba(154,151,151,0.1)",
                height:"190px",
                borderRadius: "16px",
                cursor: "pointer",
                mt: { xl: 0, lg: 2, sm: 2, xs: 2 },
              }}
              align="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                sx={{ color: "#FFC300", fontFamily: "helvetica-lt-std-bold" }}
              >
                {noShowTotal}
              </Typography>
              <Typography variant="body1" sx={{ color: "#FFC300" }}>
                {noShowTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.9} lg={5.9} sm={5.9} xs={12}>
            <Box
              sx={{
                background: "#F6F9FB",
                 height:"190px",
                borderRadius: "16px",
                mt: { xl: 0, lg: 2, sm: 2, xs: 2 },
              }}
              align="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {cancelationsTotal}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {cancelationsTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.9} lg={5.9} sm={5.9} xs={12}>
            <Box
              sx={{
              
                background: "#F6F9FB",
                height:"190px",
                borderRadius: "16px",
                mt: { xl: 0, lg: 2, sm: 2, xs: 2 },
              }}
              align="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"

              onClick={handleClickRepeated}
            >
              <Typography
                variant="h4"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {newMembersTotal}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {newMemberTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={2.9} lg={5.9} sm={5.9} xs={12}>
            <Box
              sx={{
                background: "#F6F9FB",
                height:"190px",
                borderRadius: "16px",
                mt: { xl: 0, lg: 2, sm: 2, xs: 2 },
              }}
              align="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {bookingRate}%
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#111827", fontFamily: "helvetica-lt-std-bold" }}
              >
                {bookingRateTitle}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Test;
