import React, { useEffect, useRef, useState } from "react";
import UserLayout from "../../user";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CloseIcon from '@mui/icons-material/Close';
import MasterCardIcon from '../../../assets/icon/243808_card_cash_checkout_mastercard_online shopping_icon.png'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Playon from '@mui/icons-material/PlayCircleOutline';
import successIcon from "../../../assets/icon/checkmark-circle.png";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Autocomplete,
  Box,
  Card,
  Drawer,
  ListItemText,
  Modal,
  Link as MuiLink,
  RadioGroup,
  TableFooter,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button, Typography, FormControlLabel, Radio, Container } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Waveloading from "../../loading/Waveloading";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";  
import visa from "../../../assets/icon/visa.png";
import FormControl from "@mui/material/FormControl";  
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

import { makeStyles } from '@mui/styles';
import { UpdatemembershipSubscription, cancelmembershipSubscription, createmembershipSubscription, getUserexsiting, getUserexsitingdata, getinvoice, getmemshiplist, getmemshiplistdeatil, pausemembershipSubscription, resumemembershipSubscription, updatemember } from "../../../store/crm/membership";
import { useDispatch, useSelector } from "react-redux";
import BackendPagination from "../../../components/pagination/backendPagination";
import BackendSearchField from "../../../components/pagination/backendSearch";
import BackendSearch from "../../../components/pagination/MemberSearch";
import { getAllCRMSubscription } from "../../../store/crm/crm";
import { crmCreateCard, defaultUserCard, deleteUserCard, getAllExistingUserCards } from "../../../store/crm/appointment/book-appointment";
import SquareCard from "../crmpage/crmMembership/squareCard";
import { MySwal, successConfirmation } from "../../../services/utills";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router";

import moment from "moment";
import Invoices from "../../../components/invoice/Invoicesss";

const useStyles = makeStyles({
  greenText: {
    color: '#40A50B',
  },
  redText: {
    color: '#CD0729',
  },
  defaultText: {
    color: 'black',
  },

});

export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  width: "95%",
  marginTop: '20px',
  // marginLeft: '10px',
  border: '1px solid lightgrey',
  "@media (max-width: 1600px) ": {
    width: "100%",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },

  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "21px",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100vh",
  bgcolor: "background.paper",
  p: "27px 12px",
  borderRadius: "6px",
  overflowX: 'auto',
  "@media (max-width: 550px)": {
    width: "95%",
  },
  gap: "5px",
};



const stylePayment = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  height: "auto",
  bgcolor: "background.paper",
  p: "27px 12px",
  borderRadius: "6px",
  overflowX: 'auto',
  "@media (max-width: 550px)": {
    width: "95%",

  },
  gap: "5px",
}

const invoicePayment = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  p: "27px 12px",
  borderRadius: "6px",
  overflowX: 'auto',
  "@media (max-width: 550px)": {
    width: "95%",

  },
  gap: "5px",
}
const MembershipList = () => {
  const [selectedDate, setSelectedDate] = useState(
      new Date().toISOString().split("T")[0]
  );
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const membershipstore = useSelector((state) => state?.membershipstore);
  const {
    membershiplist,
    pageLimit,
    search,
    appointmentInvoices,
    userData,
    detail
  } = membershipstore;
  console.log("user data", detail)
  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + ' ...';
    }
  }
  const [notes, setNotes] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTicket, setActiveTicket] = useState(null);
  const handleCloseDrawer = () => {
    setActiveTicket(null);
    setOpenDrawer(false);
  };


  // getinvoice
  const handleOpenDrawer =async (item) => {
    setLoading(true);
    setActiveTicket(item);
    setOpenDrawer(true);
    try {
      // Make API call to fetch customer details
      const response = await dispatch(getmemshiplistdeatil(item))
      const data = await response.json();
    } catch (error) {
      console.error("Error fetching customer details:", error);
    } finally {
      setLoading(false);
    }

  };
  const getInitials = (name) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    const initials = nameParts.length > 1
        ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`
        : nameParts[0][0];
    return initials.toUpperCase();
  };

  const [opencc, setOpenbb] = React.useState(false);
  const handleClickOpen = () => {
    setOpenbb(true);
  };

  const handleClosebb = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenbb(false);
    }
  }


  const crmStore = useSelector(state => state?.crmStore)
  const { crmSubscriptions = [] } = crmStore
  const list = () => (
      <Box sx={{ width: { xs: '300px', sm: '480px' } }} role="presentation">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
              variant="h5"
              color="initial"

              sx={{
                textAlign: "left", mr:1, cursor: "pointer", background: '#f4ede3', padding:{sm:'12px', xs:1}, width: '50px','@media (max-width: 768px)' :{ height:'30px'},height:'50px', marginTop: '30px', marginLeft: '20px', paddingLeft: '13px', paddingTop: '7px', borderRadius: '6px', fontSize: '27px'
              }}
              onClick={() => handleCloseDrawer()}
          >
            <CloseIcon sx={{'@media (max-width: 768px)' :{ marginTop:"-25px",fontSize:'20px', height:'30px',},marginTop:'-10px',}}/>
          </Typography>

          {detail?.status=== "Cancelled" ? null :
              <>

                <Box sx={{ display:'flex' }}>

                  <Button
                      variant="contained"
                      onClick={cancelhandel}
                      sx={{ background: '#EB5757 !important', '@media (max-width: 768px)' :{  height:'30px', fontSize:'12px'},height:'50px', marginTop: '30px', marginRight: '5px', color: '#fff', fontWeight: 'bold', textTransform:'' }}
                  >
                    Cancel
                  </Button>
                  <Button
                      variant="contained"
                      onClick={  detail?.status==="Pause" ? resumehandel :pausehandel}
                      sx={{ background: '#f4ede3 !important', '@media (max-width: 768px)' :{ height:'30px',fontSize:'12px'}, marginTop: '30px',height:'50px', marginRight: '5px', color: '#000', fontWeight: 'bold', textTransform:'' }}
                  >
                    {detail?.status==="Pause" ? "Resume":"pause"}
                  </Button>

                </Box>
              </> }
          <Button
              variant="contained"
              onClick={handleOpenUpdate}
              sx={{ background: '#1976D2 !important', '@media (max-width: 768px)' :{ height:'30px',fontSize:'12px'}, marginTop: '30px',height:'50px', marginRight: '15px', color: '#fff', fontWeight: 'bold', textTransform:'' }}
          >
            Updated
          </Button>


        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <Typography variant="body1"
                      sx={{ color: "#b7b4b4", marginLeft: '20px' }}
          >
            $ {detail?.plan?.plan_price} subscription plan
          </Typography>
          <Typography v
                      variant="h5"
                      color="initial"
                      sx={{ fontWeight: 'bold', marginLeft: '20px', marginTop: '10px' }}
          >
            {detail?.customer_name}
          </Typography>
        </Box>
        <Container maxWidth="xs">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
                variant="h5"
                color="rgba(0,0,0,.55)"
                sx={{
                  cursor: "pointer", background: 'rgba(0,0,0,.05)', height: '50px', width: '50px', marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                }}
            >
              {getInitials(detail?.customer_name)}
            </Typography>
            <Box sx={{ marginTop: '30px', ml: 2 }}>
              <Typography variant="h6">

                {detail?.customer_name}

              </Typography>
              <Typography variant="subtitle2" color="initial" sx={{ color: '#b7b4b4' }}>
                {detail?.customer_email}
              </Typography>
              <Typography variant="subtitle2" color="initial" sx={{ color: '#b7b4b4' }}>
                {detail?.customer_phone}
              </Typography>

            </Box>


          </Box>
          <Divider sx={{ height: 9, mt: 3, backgroundColor: 'lightgrey' }} orientation="horizontal" />

        </Container>
        {/* <Container maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>

            Notes

          </Typography>
          <Button variant="text" sx={{ color: '#3279DE', fontWeight: '600', textTransform: 'capitalize', fontSize: '16px' }} >
            Add
          </Button>
        </Box>
        <Divider sx={{ height: 9, mt: 3, backgroundColor: 'lightgrey' }} orientation="horizontal" /> */}

        {/* </Container> */}

        <Box>
          <Container maxWidth="xs">
            <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
              Subscription details

            </Typography>

          </Container>
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={6} xs={6}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "10px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}

                >
                  Status
                </Typography>

              </Grid>
              <Grid item lg={6} sx={6}>


                <Typography variant="h6"  style={{
                  color: "white",
                  backgroundColor: (detail?.status || "").includes("Cancelled") ? "#FF0000" :
                      (detail?.status || "").includes("Declined") ? "#dcd90a" :
                          (detail?.status || "").includes("Pause") ? "#FFA500" :
                              (detail?.status || "") !== "New User" ? "#219653" : "#09ABF1",
                }}  sx={{ fontSize: '12px', padding: '3px 8px', width: 'fit-content', marginBottom: '3px', fontWeight: 600, borderRadius: '100px', letterSpacing: '1px' }}>

                  {detail?.status}

                </Typography>
                <Typography variant="subtitle2" color="initial" sx={{ color: '#b7b4b4' }}>
                  On {moment(detail?.on_date).format("dddd MMMM D, YYYY")}
                </Typography>
              </Grid>
            </Grid>
            <hr />
          </Container>
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={6} xs={6}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}
                >
                  Plan
                </Typography>

              </Grid>
              <Grid item lg={6} xs={6}>

                <Typography variant="h6" sx={{ fontSize: '16px' }}>

                  {detail?.plan?.plan_price} Subscription plan

                </Typography>
              </Grid>
            </Grid>
            <hr />
          </Container>
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={6} xs={6}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}
                >
                  Price
                </Typography>

              </Grid>
              <Grid item lg={6} xs={6}>

                <Typography variant="h6" sx={{ fontSize: '16px' }}>

                  ${detail?.plan?.plan_price}

                </Typography>
              </Grid>
            </Grid>
            <hr />
          </Container>
          {/* <Container maxWidth="xs" sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={6} xs={6}>
              <Typography
                variant="h5"
                color="#676565"
                sx={{
                  cursor: "pointer", marginTop: "10px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                }}
              >
                Billing Cadence
              </Typography>

            </Grid>
            <Grid item lg={6} xs={6}>

              <Typography variant="h6" sx={{ fontSize: '16px' }}>

                Monthly

              </Typography>
              <Typography variant="subtitle2" color="initial" sx={{ color: '#b7b4b4' }}>
                Next billing cancelled
              </Typography>
            </Grid>
          </Grid>
          <hr />
        </Container> */}
          {/* <Container maxWidth="xs" sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={6} xs={6}>
              <Typography
                variant="h5"
                color="#676565"
                sx={{
                  cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                }}
              >
                Plan ends
              </Typography>

            </Grid>
            <Grid item lg={6} xs={6}>

              <Typography variant="h6" sx={{ fontSize: '16px' }}>



                Never

              </Typography>
            </Grid>
          </Grid>
          <hr />
        </Container> */}
          {/* <Container maxWidth="xs" sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={6} xs={6}>
              <Typography
                variant="h5"
                color="#676565"
                sx={{
                  cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                }}
              >
                Trial period
              </Typography>

            </Grid>
            <Grid item lg={6} xs={6}>

              <Typography variant="h6" sx={{ fontSize: '16px' }}>

                No trial

              </Typography>

            </Grid>
          </Grid>
          <hr />
        </Container> */}
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={6} xs={6}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}
                >
                  location
                </Typography>

              </Grid>
              <Grid item lg={6} xs={6}>

                <Typography variant="h6" sx={{ fontSize: '16px' }}>

                  {detail?.provider_name}

                </Typography>
              </Grid>
            </Grid>
            <hr />
          </Container>
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={6} xs={5.8}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}
                >
                  Subtotal
                </Typography>

              </Grid>
              <Grid item lg={6} xs={5.8}>

                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                  ${detail?.sub_total}

                </Typography>
              </Grid>
            </Grid>
            <hr />
          </Container>
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={6} xs={6}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}
                >
                  Sales tax (12%)
                </Typography>

              </Grid>
              <Grid item lg={6} xs={6}>

                <Typography variant="h6" sx={{ fontSize: '16px' }}>

                  ${detail?.tax}

                </Typography>
              </Grid>
            </Grid>
            <hr />
          </Container>
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={6} xs={6}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}
                >
                  Total
                </Typography>

              </Grid>
              <Grid item lg={6} xs={6}>

                <Typography variant="h6" sx={{ fontSize: '16px' }}>

                  {detail?.total}

                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ height: 9, mt: 3, backgroundColor: 'lightgrey' }} orientation="horizontal" />
          </Container>




          <Box>

            <Typography variant="h6" sx={{ p: 2, marginTop: '20px', marginLeft: '20px', fontWeight: 'bold' }} >
              Payment Method
            </Typography>
          </Box>
          <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={3} xs={6}>
                <Typography
                    variant="h5"
                    color="#676565"
                    sx={{
                      cursor: "pointer", marginTop: "5px", borderRadius: '6px', fontSize: '16px', fontWeight: 'bold',
                    }}
                >
                  <img style={{ width: "40px" }} src={MasterCardIcon} />
                </Typography>

              </Grid>
              {selectedUserCard?.data?.cards?.length > 0 ? (
                  selectedUserCard?.data?.cards
                      .filter(item => item?.status === 'Active')
                      .map((item, index) => (
                          <Grid item lg={6.5} xs={6}>

                            <Typography variant="h6" sx={{ fontSize: '16px' }}>

                              Card on file

                            </Typography>
                            <Typography variant="subtitle2" color="initial" sx={{ color: '#b7b4b4' }}>
                              {item?.card_brand} ending 1111
                            </Typography>
                          </Grid>
                      ))):null}
              <Grid item lg={2.5} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    variant="text"
                    onClick={handleOpenPayment}
                    sx={{ textTransform: 'capitalize', fontWeight: '600', color: '#3279DE' }}>
                  Update
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ height: 9, mt: 3, backgroundColor: 'lightgrey' }} orientation="horizontal" />
          </Container>
          <Container maxWidth="xs">
            <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }} >
              Activity
            </Typography>

            {detail && detail.activity && detail.activity.map((item, index) => (
                <>
                  <Grid container style={{ marginTop: '20px' }}
                        onClick={() => handleOpenInvoice(item.invoice_id)} >
                    <Grid item lg={3} xs={6}>
                      <Typography
                          variant="h5"
                          color="rgba(0,0,0,.55)"
                          sx={{
                            cursor: "pointer",
                            background: 'rgba(0,0,0,.05)',
                            height: '40px',
                            width: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '6px',
                            fontSize: '30px',
                            fontWeight: 'bold',
                          }}
                      >
                        {item?.invoice_id == null ? (
                            <Playon sx={{ fontSize: '30px' }} />
                        ) : (
                            <img style={{ width: '30px' }} src={MasterCardIcon} alt="MasterCard Icon" />
                        )}
                      </Typography>

                    </Grid>
                    <Grid item lg={9} xs={6}>

                      <Typography variant="h6" sx={{ fontSize: '16px' }}>

                        {item?.label}

                      </Typography>
                      {item?.invoice_id == null ? (
                          <Typography variant="subtitle2" sx={{ color: '#b7b4b4' }}>
                            {item.message}
                          </Typography>
                      ) : (
                          <Typography variant="subtitle2" sx={{ color: '#b7b4b4' }}>
                            {item?.message} sent {moment(item?.updated_at).format("dddd MMMM D, YYYY")}
                          </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <hr />

                </>
            ))}


          </Container>

        </Box>

      </Box>
  );

  const handleDateChange = async (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);

  };
  const classes = useStyles();
  // Determine the class based on the text content
  const getClass = (text) => {
    if (text === 'Paid') {
      return classes.greenText;
    } else if (text === 'Overdue') {
      return classes.redText;
    } else {
      return classes.defaultText;
    }
  };
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openinvoice, setOpenInvoice] = useState(false);
  const [openupdate, setOpenUpdate] = useState(false);
  const [userCards, setUserCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  // console.log("id get",customerId, bookingId )
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false)
  const dispatch = useDispatch();

  console.log("data comming", membershipstore?.membershiplist)
  const { selectedUserCard } = useSelector(state => state?.bookAppointmentStore)


  const currentPage = useSelector(state => state.membershipstore.currentPage);
  const [searchValue, setSearchValue] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [formFilled, setFormFilled] = useState(true);


  const navigate = useNavigate();
  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };
  const filteredList = (membershiplist ?? []).filter(row =>
      row?.customer?.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const [squareCard, setSquareCard] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const handleOpenPayment = () => setOpenPayment(true);
  const handleClosePayment = () => setOpenPayment(false);
  const handleCloseInvoice = () => setOpenInvoice(false);
  const [useExistingCard, setUseExistingCard] = useState(false);
  const handleCloseModal = () => {
    handleClose();
  };

  const handleCloseModalPayment = () => {
    handleClosePayment();
  }


  function createData(name, calories, fat, carbs, protein, Monthly, date, end) {
    return { name, calories, fat, carbs, protein, Monthly, date, end };
  }

  function ButtonWithIcon() {
    return (
        <Button
            variant="outlined"
            sx={{
              cursor: 'pointer',
              padding: '10px 20px',
              textTransform: 'capitalize',
              fontWeight: 'bold',
              letterSpacing: '1px',
              backgroundColor: '#efefef',
              border: 'none',
              borderRadius: '6px'
            }}

        >
          Export
        </Button>
    );
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const fetchMember = async () => {
    setLoading(true);
    try {
      await dispatch(getmemshiplist());
    } catch (error) {
      console.error("Error fetching membership list:", error);
      toast.error("Error occurred while fetching membership list");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMember();
  }, []);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // getUserexsiting
  useEffect(() => {
    setLoading(true);
    dispatch(getUserexsitingdata())
    dispatch(getAllCRMSubscription())
    setLoading(false)
    console.log("data coming",)
  }, []);
  //  existing cards
  const customer_id = 7169
  const handleCustomerChange = (event, newValue) => {
    setSelectedCustomer(newValue);
  };
  useEffect(() => {
    const fetchExistingUserCards = async () => {
      const customerId = selectedCustomer?.id || selectedCustomerId;
      console.log("id",customerId,selectedCustomerId,selectedCustomer?.id)
      if (customerId && selectedPaymentMethod === 'existing_card') {
        const { payload } = await dispatch(getAllExistingUserCards(customerId))
      }
    };

    fetchExistingUserCards();
  }, [selectedCustomer, selectedPaymentMethod, dispatch,selectedCustomerId]);

  const defaultusercard = async (cardId, event) => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: "Do you want to make this card default?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, make it default'
    });

    if (result.isConfirmed) {
      setLoading(true);

      console.log("card data", selectedUserCard);
      const payload = {
        card_id: cardId,
        provider_id: user?.id,
        user_id: selectedUserCard?.data?.user?.id,
      };
      console.log("data", payload);

      try {
        await dispatch(defaultUserCard(payload));


        setSelectedCard(event);
      } catch (error) {
        console.error("Error in defaultUserCard:", error);

      } finally {
        setLoading(false);

      }
    } else {

    }
  };

  const deleteusercard = async (cardId, event) => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this card?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it'
    });

    if (result.isConfirmed) {
      setLoading(true);

      const customerId = selectedCustomer?.id || selectedCustomerId;
      const providerId = user?.id;

      if (!customerId || !providerId) {
        toast.error("Error: Missing customer or provider information.");
        setLoading(false);
        return;
      }

      console.log("card data", selectedUserCard?.data?.application_token);

      const payload = {
        card_id: cardId,
        customer_id: customerId,
        provider_id: providerId,
      };

      console.log("data", payload);

      try {
        await dispatch(deleteUserCard(payload));
      } catch (error) {
        console.error("Error in deleteUserCard:", error);
      } finally {
        try {
          const { payload } = await dispatch(getAllExistingUserCards(customerId));
          setUserCards(payload?.data?.cards?.cards);
        } catch (error) {
          console.error("Error in getAllExistingUserCards:", error);
        }
        setLoading(false);
      }
    }
  };

  const authStore = useSelector(state => state?.authStore)
  const { user } = authStore




  const handleOpenInvoice = async (invoiceId) => {
    setLoading(true)
    const invoiceDetails = await dispatch(getinvoice(invoiceId))
    setSelectedInvoice(invoiceDetails);
    setOpenInvoice(true);
    setLoading(false)
    // console.log("details",invoicedetails)
  };

  const handleOpenUpdate= async () => {
    setLoading(true)
    setOpenUpdate(true);
    setLoading(false)
    // console.log("details",invoicedetails)
  };

  const handleCloseupdate = () => {
    setOpenUpdate(false);

  };
  const [visibleEntries, setVisibleEntries] = useState(15);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    console.log('Component mounted');
    const handleScroll = () => {
      console.log('Scroll event triggered');
      if (tableContainerRef.current && tableContainerRef.current.scrollTop + tableContainerRef.current.clientHeight + 1 >= tableContainerRef.current.scrollHeight) {
        console.log('Bottom reached, loading more entries');
        loadMoreEntries();
      }
    };

    const currentContainer = tableContainerRef.current;
    currentContainer.addEventListener('scroll', handleScroll);
    return () => {
      console.log('Component unmounted');
      currentContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const loadMoreEntries = () => {
    setVisibleEntries(prev => {
      console.log('Loading more entries, current visible entries:', prev);
      return prev + 15;
    });
  };



  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  // const handleSearch = async (val) => {
  //   await dispatch(updatemember({ search: val }));
  // };
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return options.slice(0, 10);
    }
    return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
  };
  const [selectedPlan, setSelectedPlan] = useState('');
  const [salesTax, setSalesTax] = useState(0);
  const [errors, setErrors] = useState({});
  const [planprice,setPlanprice] =useState(0)

  const handlePlanChange = (event) => {

    const selectedPlanId = event.target.value;
    const selectedPlan = crmSubscriptions.find(plan => plan.plan_id === selectedPlanId);

    setSelectedPlan(selectedPlanId);
    setSalesTax(selectedPlan ? selectedPlan.plan_tax : 0);
    setPlanprice(selectedPlanId)
    setPlanprice(selectedPlan ? selectedPlan.plan_price : 0);
  };
  const tokenize = async (method) => {
    const tokenResult = await method.tokenize()
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }

  }

//  console.log("payload data ",selectedPlan,selectedCustomer.id,)
  const handlesavedata = async () => {
    console.log("payload data ",selectedPlan,selectedCustomer.id,)
    setLoading(true);
    let squareToken = await tokenize(squareCard)
    let squarecardtokken =await tokenize(squareCard)
    let cardData = {
      user_id: customer_id,
      nonce: squarecardtokken,

    };
    await dispatch(crmCreateCard(cardData));

    let payload  = {
      customer_id:selectedCustomer.id ||selectedCustomerId,
      payment_method_token:squareToken,
      plan_id:selectedPlan,
      membership_start_date: selectedDate,
      // notes: notes
    }
    const result = await dispatch(createmembershipSubscription(payload))
    console.log("api calling",result)
    if (result?.payload?.success === 'true'){
      setLoading(false)
      fetchMember()
      await successConfirmation("Success",`Congratulations on Signing up a new member `)
      handleClose()
      // navigate("/crm/membership")

    }
    else {
      setLoading(false)
      toast.error("error occure")
      handleClose()
    }

  }
  const handleButtonClick =  async (status) => {
    console.log('Button clicked with status:', status);
    setLoading(true);// Add this line
    try {
      await dispatch(getmemshiplist(status));

    } catch (error) {
      toast.error("error occure")

    } finally {
      setLoading(false);

    }
  };
  const handelupdateButton = async () => {
    setLoading(true);

    try {
      let squarecardtoken = await tokenize(squareCard);
      let cardData = {
        user_id: customer_id,
        nonce: squarecardtoken,
      };

      await dispatch(crmCreateCard(cardData));

      successConfirmation("Card added successfully");

    } catch (error) {
      console.error("Error in crmCreateCard:", error);
      toast.error("Error occurred while adding the card");
    } finally {
      setLoading(false);
      handleCloseModalPayment()
    }
  };

  const cancelhandel =  async () => {
    const confirm = await MySwal.fire({
      icon: 'warning',
      title: 'Confirmation',
      showConfirmButton: false,
      html: (
          <Box sx={{ mb: 4 }}>
            <Typography>
              Are you sure you want to change your status?
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Button
                  sx={{
                    backgroundColor: "#fff",
                    minWidth: "max-content",
                    border: "1px solid #666666",
                    borderRadius: '10px',
                    fontSize: '16px',
                    px: '20px',
                    mx: '10px',
                    color: "#666666",
                    "&:hover": {
                      backgroundColor: "#fff",
                      border: "1px solid #666666",
                    },
                  }}
                  onClick={() => MySwal.clickConfirm()}
              >
                Yes
              </Button>
              <Button
                  sx={{
                    backgroundColor: "#fff",
                    minWidth: "max-content",
                    border: "1px solid #666666",
                    borderRadius: '10px',
                    px: '20px',
                    mx: '10px',
                    fontSize: '16px',
                    color: "#666666",
                    "&:hover": {
                      backgroundColor: "#fff",
                      border: "1px solid #666666",
                    },
                  }}
                  onClick={() => MySwal.close()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
      ),
    });

    if (confirm.isConfirmed) {
      console.log('Button clicked with status:', selectedCustomerId);
      setLoading(true);

      try {
        let payload = {
          user_id: selectedCustomerId
        };

        await dispatch(cancelmembershipSubscription(payload));
        const response = await dispatch(getmemshiplistdeatil(selectedCustomerId));
        await dispatch(getmemshiplist());
        toast.success("Successfully updated");
      } catch (error) {
        toast.error("Error occurred");
      } finally {
        setLoading(false);
      }
    }
  };

  const pausehandel = async () => {
    const confirm = await MySwal.fire({
      icon: 'warning',
      title: 'Confirmation',
      showConfirmButton: false,
      html: (
          <Box sx={{ mb: 4 }}>
            <Typography>
              Are you sure you want to change your status?
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Button
                  sx={{
                    backgroundColor: "#fff",
                    minWidth: "max-content",
                    border: "1px solid #666666",
                    borderRadius: '10px',
                    fontSize: '16px',
                    px: '20px',
                    mx: '10px',
                    color: "#666666",
                    "&:hover": {
                      backgroundColor: "#fff",
                      border: "1px solid #666666",
                    },
                  }}
                  onClick={() => MySwal.clickConfirm()}
              >
                Yes
              </Button>
              <Button
                  sx={{
                    backgroundColor: "#fff",
                    minWidth: "max-content",
                    border: "1px solid #666666",
                    borderRadius: '10px',
                    px: '20px',
                    mx: '10px',
                    fontSize: '16px',
                    color: "#666666",
                    "&:hover": {
                      backgroundColor: "#fff",
                      border: "1px solid #666666",
                    },
                  }}
                  onClick={() => MySwal.close()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
      ),
    });

    if (confirm.isConfirmed) {
      console.log('Button clicked with status:', selectedCustomerId);
      setLoading(true);

      try {
        let payload = {
          user_id: selectedCustomerId
        }

        await dispatch(pausemembershipSubscription(payload));
        const response = await dispatch(getmemshiplistdeatil(selectedCustomerId));
        await dispatch(getmemshiplist());
        toast.success("Successfully updated");
      } catch (error) {
        toast.error("Error occurred");
      } finally {
        setLoading(false);
      }
    }
  };

// resume api
  const resumehandel =  async () => {
    const confirm = await MySwal.fire({
      icon: 'warning',
      title: 'Confirmation',
      showConfirmButton: false,
      html: (
          <Box sx={{ mb: 4 }}>
            <Typography>
              Are you sure you want to change your status?
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Button
                  sx={{
                    backgroundColor: "#fff",
                    minWidth: "max-content",
                    border: "1px solid #666666",
                    borderRadius: '10px',
                    fontSize: '16px',
                    px: '20px',
                    mx: '10px',
                    color: "#666666",
                    "&:hover": {
                      backgroundColor: "#fff",
                      border: "1px solid #666666",
                    },
                  }}
                  onClick={() => MySwal.clickConfirm()}
              >
                Yes
              </Button>
              <Button
                  sx={{
                    backgroundColor: "#fff",
                    minWidth: "max-content",
                    border: "1px solid #666666",
                    borderRadius: '10px',
                    px: '20px',
                    mx: '10px',
                    fontSize: '16px',
                    color: "#666666",
                    "&:hover": {
                      backgroundColor: "#fff",
                      border: "1px solid #666666",
                    },
                  }}
                  onClick={() => MySwal.close()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
      ),
    });

    if (confirm.isConfirmed) {
      console.log('Button clicked with status:', selectedCustomerId);
      setLoading(true);

      try {
        let payload = {
          user_id: selectedCustomerId
        }
        await dispatch(resumemembershipSubscription(payload))
        const response = await dispatch(getmemshiplistdeatil(selectedCustomerId));
        await dispatch(getmemshiplist());
        toast.success("Successfully updated");
      } catch (error) {
        toast.error("Error occurred");
      } finally {
        setLoading(false);
      }
    }
  };

  const checkFormFilled = () => {
    const isFormFilled = selectedPlan && selectedDate && selectedPaymentMethod && (selectedPaymentMethod === 'credit_or_debit' || selectedUserCard?.data?.cards?.length > 0);
    setFormFilled(isFormFilled);
    return isFormFilled;
  }


  const validateFields = () => {
    const newErrors = {};
    if (!selectedCustomer) {
      newErrors.selectedCustomer = 'Customer is required';
    }
    if (!selectedPlan) {
      newErrors.selectedPlan = 'Plan is required';
    }
    if (!selectedDate) {
      newErrors.selectedDate = 'Date is required';
    }
    if (!selectedPaymentMethod) {
      newErrors.selectedPaymentMethod = 'Payment method is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleUpdatePlan = async () => {
    const payload = {
      customer_id: selectedCustomerId,
      new_plan_id: selectedPlan
    };

    try {
      setLoading(true)
      const response = dispatch(UpdatemembershipSubscription(payload))
      // Handle the response as needed
      console.log(response.data);
      // Close the modal if needed

      await dispatch(getmemshiplist());
      await dispatch(getmemshiplistdeatil(selectedCustomerId));

      handleCloseupdate();
      setLoading(false)
      toast.success("Successfully updated");

    } catch (error) {
      setLoading(false)
      console.error('Error updating plan:', error);
    }
  };


  const handleSaveClick = () => {
    if (validateFields()) {
      handlesavedata();
    } else {
      toast.error("Please fill all required fields.");
    }
  }

  return (
      <UserLayout>
        {loading ? <Waveloading fullSize /> : ""}
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                onSubmit={(e) => e.preventDefault()}
            >
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Subscription by customer name"
                  inputProps={{ 'aria-label': 'search google maps' }}
                  value={searchValue}
                  onChange={handleSearchChange}

              />

            </Paper>
            <div>
              <Button
                  variant="outlined"
                  size="large"
                  // color="success"
                  style={{ marginTop: "20px", marginRight: "10px"}}
                  onClick={() => handleButtonClick("All")}
              >
                All
              </Button>

              <Button
                  variant="outlined"
                  size="large"
                  color="success"
                  style={{ marginTop: "20px", marginRight: "10px"}}
                  onClick={() => handleButtonClick("Active")}
              >
                Active
              </Button>
              <Button
                  variant="outlined"
                  size="large"
                  // color="error"
                  style={{ marginTop: "20px", marginRight: "10px", color: "#dcd90a", borderColor: "#dcd90a" }}
                  onClick={() => handleButtonClick("Declined")}
              >
                Decline
              </Button>
              <Button
                  variant="outlined"
                  size="large"
                  color="error"
                  style={{ marginTop: "20px", marginRight: "10px" }}
                  onClick={() => handleButtonClick("Cancelled")}
              >
                Cancel
              </Button>
              <Button
                  variant="outlined"
                  size="large"
                  style={{ marginTop: "20px", marginRight: "10px", color: "orange", borderColor: "orange" }}
                  onClick={() => handleButtonClick("Pause")}
              >
                Paused
              </Button>
            </div>
          </Box>
          <Box sx={{ mt: { lg: 0, xs: 2 }, color: "#3279DE" }}>
            {/* <Button variant="outlined"
        sx={{
          cursor:'pointer',
          padding:'10px 20px',
          textTransform:'capitalize',
          fontWeight:'bold',
          letterSpacing:'1px',
          backgroundColor:'#efefef',
          border:'none',
          borderRadius:'6px'
          }}>Export</Button> */}


            <ReactHTMLTableToExcel
                table="saleReportPrint"
                filename="MemberReport"
                sheet="tablexlsx"
                buttonText={<ButtonWithIcon />}
                className="custom-excel-button"

            />
            <Button
                variant="contained"
                onClick={handleOpen}
                sx={{
                  marginLeft: "20px",
                  padding: "10px",
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  letterSpacing: '2px',
                  borderRadius: '6px',
                  fontFamily:'helvetica-lt-std-roman',
                }}
            >
              Create Subscription
            </Button>

          </Box>
        </Grid>

        <Paper sx={{ width: "100%", overflow: "hidden", mt: 3, }}>
          <TableContainer ref={tableContainerRef} sx={{ height: "710px" }}>
            <Table stickyHeader aria-label="sticky table" id="saleReportPrint" sx={{  width:{sm:'100%', xs:'600px', overflowX:'auto'} }}>
              <TableHead>
                <TableRow sx={{ fontSize: '15px' }}>
                  <TableCell sx={{ fontWeight: 'bold', padding: '0', paddingLeft: '10px', paddingBottom: '10px' }}>Customer</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', padding: '0', paddingBottom: '10px' }}>Plan</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', padding: '0', paddingBottom: '10px' }}>Status</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', padding: '0', paddingBottom: '10px' }}>Last invoice</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', padding: '0', paddingBottom: '10px' }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredList.length === 0 ? (
                    <TableCell
                        colSpan={6}
                        align={"center"}
                        sx={{
                          fontWeight: "bold",
                          borderBottom: "none",
                          fontSize: "20px",
                        }}
                    >
                      No Subscription Found
                    </TableCell>
                ) : (
                    filteredList.slice(0, visibleEntries).map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedCustomerId(row.customer.id);
                              handleOpenDrawer(row.customer.id);
                            }}
                        >
                          <TableCell component="th" scope="row" sx={{ color: "#005AD9", fontSize: '15px', fontWeight: '600',padding:'0',paddingLeft:'10px' }}>
                            {row?.customer?.name}
                          </TableCell>
                          <TableCell sx={{ padding:'3px', lineHeight:'20px',paddingTop:'2px',fontSize:'15px' }}>
                            <Typography variant="subtitle2" sx={{ mb:'5px' }}>${row?.plan?.plan_price} subscription plan</Typography>
                            {/* <p style={{ marginBottom: 0   }}>${row?.plan?.plan_price} subscription plan</p> */}
                            <p style={{  margin:'0'  }}>Monthly</p>
                          </TableCell>
                          <TableCell sx={{ padding:'3px', lineHeight:'12px',paddingTop:'2px',fontSize:'15px' }}>
                            <p style={{ fontWeight: 'bold', paddingTop:'5px' ,
                              color: row?.status.includes("Cancelled") ? "#FF0000" :
                                  row?.status.includes("Declined") ? "#dcd90a" :
                                      row?.status.includes("Pause") ? "#FFA500" :
                                          row?.status !== "New User" ? "#219653" : "#09ABF1"}}>{row.status}</p>
                            <p  style={{ marginBottom:'7px',lineHeight:'22px' }}> on  {moment(row?.start_date).format("dddd MMMM D, YYYY")}</p>

                            {/* <p>on {formatDate(row?.start_date)}</p> */}
                          </TableCell>
                          <TableCell sx={{ padding:'3px', lineHeight:'20px',paddingTop:'2px',fontSize:'15px' }}>
                            {row?.latest_invoice?.status ? (
                                <>
                                  {/* {row?.latest_invoice?.end_date}</p> */}
                                  <p>   {moment(row?.latest_invoice?.created_at).format("dddd MMMM D, YYYY")}</p>
                                </>
                            ) : (
                                <p>N/A</p>
                            )}
                          </TableCell>
                          <TableCell sx={{ padding:'0px' }}>${row.amount}</TableCell>
                        </TableRow>
                    ))
                )}

              </TableBody>
            </Table>


          </TableContainer>
        </Paper>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', position:'sticky', top:0, background:'#fff', padding:'32px 0', zIndex:99999 }}>
              <Box sx={{
                backgroundColor: '#F2F2F2',
                border: '1px solid #F2F2F2',
                fontSize: '25px',
                display: 'inline-block',
                borderRadius: '5px',
                padding: '5px 15px'
              }}>
                <CloseIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer", color: '#000', marginTop: '-5px' }}
                />
              </Box>
              <Button variant="contained" onClick={handleSaveClick} disabled={loading}>
                Save
              </Button>
            </Box>
            <Box sx={{ width: { lg: '650px', xs: '100%' }, margin: "0 auto" }}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="" color="#7f7e7e">
                  Create Subscription
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '19px', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                  $ {salesTax ? (Number(salesTax) + Number(planprice)).toFixed(2) : '0.00'}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Typography variant="h5"
                            sx={{
                              fontWeight: '700',
                              fontSize: '19px',
                              fontFamily: 'Helvetica, Arial, sans-serif'
                            }}
                >
                  Customer details
                </Typography>

                <FormControl fullWidth sx={{ marginTop: "20px" }}>

                  <Autocomplete
                      fullWidth
                      options={userData}
                      getOptionLabel={(user) => user.name}
                      onChange={handleCustomerChange}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Customer"
                              margin="normal"
                              variant="outlined"
                              error={!!errors.selectedCustomer}
                              helperText={errors.selectedCustomer}
                          />
                      )}
                      renderOption={(props, user) => (
                          <li {...props} key={user.id}>
                            {user.name}
                          </li>
                      )}
                  />

                </FormControl>
              </Box>

              <Box sx={{ marginTop: "20px" }}>
                <Typography variant="h5" sx={{
                  fontWeight: '700',
                  fontSize: '19px',
                  fontFamily: 'Helvetica, Arial, sans-serif'
                }}>Subscription details</Typography>
                <Grid container>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth sx={{ marginTop: "20px" }}>
                      <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                      <Select
                          style={{ height:'56px' }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedPlan}
                          label="plan"
                          onChange={handlePlanChange}
                          renderValue={(selected) => {
                            const selectedPlanObj = crmSubscriptions.find(plan => plan.plan_id === selected);
                            return selectedPlanObj ? truncateText(selectedPlanObj.plan_name, 5) : '';
                          }}
                      >
                        {crmSubscriptions?.length > 0 &&
                            crmSubscriptions.map((plan, index) => (
                                <MenuItem
                                    value={plan.plan_id}
                                    key={index}
                                >
                                  <ListItemText primary={plan.plan_name} />
                                </MenuItem>
                            ))
                        }
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <TextField
                        variant="outlined"
                        name="date"
                        sx={inputStyle}
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        error={!!errors.selectedDate}

                        helperText={errors.selectedDate}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6"
                            sx={{
                              marginTop: '20px',
                              fontSize: '16px',
                              fontWeight: '600'
                            }} >
                  <AutorenewIcon /> Billing Summary
                </Typography>
                <hr />
                <Grid container >
                  <Grid item xl={10} md={6} sm={12} xs={6} >
                    <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: '600', fontSize: '14px' }}>Subtotal</Typography>
                  </Grid>
                  <Grid item xl={2} md={6} sm={12} xs={6} sx={{ textAlign:'end' }}>
                    <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: '400', fontSize: '14px' }}>  ${planprice}</Typography>
                  </Grid>
                  <Grid item xl={10} md={6} sm={12} xs={6} >
                    <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: '600', fontSize: '14px' }}>Sales Tax</Typography>
                  </Grid>
                  <Grid item xl={2} md={6} sm={12} xs={6} sx={{ textAlign:'end' }}>
                    <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: '400', fontSize: '14px' }}>  ${salesTax.toFixed(2)}</Typography>
                  </Grid>
                </Grid>
                <Grid container >
                  <Grid item xl={10} md={6} sm={12} xs={6} >
                    <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: '600', fontSize: '14px' }}>Total</Typography>
                  </Grid>
                  <Grid item xl={2} md={6} sm={12} xs={6} sx={{ textAlign:'end' }}>
                    <Typography variant="body1" sx={{ marginTop: '20px', fontWeight: '400', fontSize: '14px' }}>
                      $ {salesTax ? (Number(salesTax) + Number(planprice)).toFixed(2) : '0.00'}
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '19px', fontFamily: 'Helvetica, Arial, sans-serif' }}>Payment details</Typography>

                <Box sx={{}}>
                  <FormControl fullWidth sx={{ marginTop: "20px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select Payment Method
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedPaymentMethod}
                        onChange={handlePaymentMethodChange}
                        label="Customer"
                        error={!!errors.selectedPaymentMethod}
                    >
                      <MenuItem  value="existing_card">Existing Card</MenuItem>
                      <MenuItem  value="credit_or_debit">Select Debit card / Credit card</MenuItem>

                    </Select>
                    {errors.selectedPaymentMethod && <Typography variant="body2" color="error">{errors.selectedPaymentMethod}</Typography>}
                  </FormControl>
                </Box>
                {selectedPaymentMethod === 'credit_or_debit' && user?.active_payment_method?.payment_method_id === 4 && !useExistingCard &&
                    <Box style={{ marginTop: "15px" }}>
                      <SquareCard
                          setSquareCard={setSquareCard}
                          paymentMethod={user?.active_payment_method}
                      />
                    </Box>
                }
                {selectedPaymentMethod === 'existing_card' && (
                    <Box style={{ display: "flex", flexDirection: "column", height: "auto", width: "100%", marginTop: "15px" }}>
                      {selectedUserCard?.data?.cards?.length > 0 ? (
                          selectedUserCard?.data?.cards?.map((item, index) => (
                              <React.Fragment key={index}>
                                <Box sx={{ position: "relative", mt: 2 }}>
                                  <Box sx={{ position: "absolute", top: -11, right: -3 }}>
                                    <CloseIcon
                                        style={{ color: "#EB5757" }}
                                        onClick={() => deleteusercard(item?.id)}
                                    />
                                  </Box>
                                  <Card
                                      sx={{
                                        mt: 2,
                                        border: `0.8px solid ${selectedCard === (item?.token || item?.id) || item?.status === "Active" ? "#128D64" : "#C4C4C4"}`,
                                        borderRadius: "4px",
                                        boxShadow: "none",
                                        position: "relative",
                                        overflow: "unset",
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => defaultusercard(item?.id)}
                                  >
                                    <Box sx={{ display: "flex", alignItems: "center", width: '100%', px: '70px' }}>
                                      <img
                                          src={visa}
                                          width="50px"
                                          style={{ marginLeft: 2 }}
                                          alt=""
                                      />
                                      <Typography
                                          variant="h5"
                                          color="initial"
                                          sx={{
                                            ml: 3,
                                            fontSize: "16px",
                                            "@media (max-width:500px)": {
                                              marginLeft: "5px",
                                              textAlign: "center",
                                              fontSize: "15px",
                                            },
                                          }}
                                      >
                                        {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                                      </Typography>
                                      {(selectedCard === (item?.token || item?.id) || item?.status === "Active") && (
                                          <Box sx={{ ml: "50px" }}>
                                            <img
                                                src={successIcon}
                                                alt="successIcon"
                                            />
                                          </Box>
                                      )}
                                    </Box>
                                  </Card>
                                </Box>
                              </React.Fragment>
                          ))
                      ) : (
                          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                            There are no existing cards.
                          </Typography>
                      )}
                    </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* Payment Modal */}

        <Modal
            style={{}}
            open={openPayment}
            onClose={handleClosePayment}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={stylePayment}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{
                backgroundColor: '#F2F2F2',
                border: '1px solid #F2F2F2',
                fontSize: '25px',
                display: 'inline-block',
                borderRadius: '5px',
                padding: '5px 15px'
              }}>
                <CloseIcon
                    onClick={handleCloseModalPayment}
                    style={{ cursor: "pointer", color: '#000', marginTop: '-5px' }}
                />
              </Box>
              <Typography variant="h5" sx={{ fontWeight: '600' }}  >
                Update payment method
              </Typography>
              <Button variant="contained" onClick={handelupdateButton}>
                Save
              </Button>
            </Box>
            <Box sx={{}}>
              <FormControl fullWidth sx={{ marginTop: "20px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Payment Method
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPaymentMethod}
                    onChange={handlePaymentMethodChange}
                    label="Customer"
                >
                  <MenuItem  value="existing_card">Card On File</MenuItem>
                  <MenuItem  value="credit_or_debit">Select Debit card / Credit card</MenuItem>

                </Select>
              </FormControl>
            </Box>
            {selectedPaymentMethod === 'credit_or_debit' && user?.active_payment_method?.payment_method_id === 4 && !useExistingCard &&
                <Box style={{ marginTop: "15px" }}>
                  <SquareCard
                      setSquareCard={setSquareCard}
                      paymentMethod={user?.active_payment_method}
                  />
                </Box>
            }
            {selectedPaymentMethod === 'existing_card' && (
                <Box style={{ display: "flex", flexDirection: "column", height: "auto", width: "100%", marginTop: "15px" }}>
                  {selectedUserCard?.data?.cards?.length > 0 ? (
                      selectedUserCard?.data?.cards?.map((item, index) => (
                          <React.Fragment key={index}>
                            <Box sx={{ position: "relative", mt: 2 }}>
                              <Box sx={{ position: "absolute", top: -11, right: -3 }}>
                                <CloseIcon
                                    style={{ color: "#EB5757" }}
                                    onClick={() => deleteusercard(item?.id)}
                                />
                              </Box>
                              <Card 
                                  sx={{
                                    mt: 2,
                                    border: `0.8px solid ${selectedCard === (item?.token || item?.id) || item?.status === "Active" ? "#128D64" : "#C4C4C4"}`,
                                    borderRadius: "4px",
                                    boxShadow: "none",
                                    position: "relative",
                                    overflow: "unset",
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => defaultusercard(item?.id)}
                              >
                                <Box sx={{ display: "flex", alignItems: "center", width: '100%', px: '70px' }}>
                                  <img
                                      src={visa}
                                      width="50px"
                                      style={{ marginLeft: 2 }}
                                      alt=""
                                  />
                                  <Typography
                                      variant="h5"
                                      color="initial"
                                      sx={{
                                        ml: 3,
                                        fontSize: "16px",
                                        "@media (max-width:500px)": {
                                          marginLeft: "5px",
                                          textAlign: "center",
                                          fontSize: "15px",
                                        },
                                      }}
                                  >
                                    {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                                  </Typography>
                                  {(selectedCard === (item?.token || item?.id) || item?.status === "Active") && (
                                      <Box sx={{ ml: "50px" }}>
                                        <img
                                            src={successIcon}
                                            alt="successIcon"
                                        />
                                      </Box>
                                  )}
                                </Box>
                              </Card>
                            </Box>
                          </React.Fragment>
                      ))
                  ) : (
                      <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        There are no existing cards.
                      </Typography>
                  )}
                </Box>
            )}
          </Box>
        </Modal>

        <Modal
            style={{}}
            open={openinvoice}
            onClose={handleCloseInvoice}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"h
        >

          {/* <Invoices appointmentInvoices={invoicedetails} /> */}
          <Box sx={invoicePayment}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>

              </Box>
              <Typography variant="h5" sx={{ fontWeight: '600' }}  >
                Invoice Details
              </Typography>

              <Box sx={{
                backgroundColor: '#F2F2F2',
                // border: '1px solid #F2F2F2',
                fontSize: '25px',
                display: 'inline-block',
                borderRadius: '5px',
                padding: '5px 15px'
              }}>
                <CloseIcon
                    onClick={handleCloseInvoice}
                    style={{ cursor: "pointer", color: '#000', marginTop: '-5px' }}
                />
              </Box>

            </Box>
            <Grid container sx={{ display: "flex" }}>
              <Grid item xs={12} xl={12}>
                <Box>

                  <Typography
                      variant="h4"
                      color="initial"
                      sx={{ mt: 1, fontFamily: "helvetica-lt-std-bold" }}
                  >
                    {moment(appointmentInvoices?.date).format("dddd MMMM D, YYYY")}

                  </Typography>
                  <Typography
                      variant="h5"
                      color="initial"
                      sx={{ fontFamily: "helvetica-lt-std-bold" }}
                  >
                    {appointmentInvoices?.start_time} {" "}
                    {appointmentInvoices?.end_time}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} xl={12} sx={{ borderBottom: "1px solid #C4C4C4" }}>
              </Grid>
            </Grid>
            <Grid container sx={{ display:'flex',justifyContent:{xs:'space-between',xl:'normal'},flexWrap:"nowrap",}}>
              <Grid lg={6} item >
                <Box
                    sx={{ display: "flex", mt: 2,cursor: 'pointer', }}
                    //  onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointmentInvoices?.booking_id}`)}

                >
                  <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                    Customer Name :
                  </Typography>
                  <Typography
                      variant="body2"
                      color="initial"
                      sx={{ ml: 1 }}
                  >
                    {appointmentInvoices?.user?.name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                    Date :
                  </Typography>
                  <Typography
                      variant="body2"
                      color="initial"
                      sx={{ ml: 1 }}
                  >
                    {/* {appointmentInvoices?.date} */}
                    <p>   {moment(appointmentInvoices?.date).format("dddd MMMM D, YYYY")}</p>

                  </Typography>
                </Box>
              </Grid>
              <Grid lg={6} item className={classes.invoicesBottom} style={{marginTop:"10px"}}>


                <Box sx={{ display: "flex", mt: 1 }}>
                  <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                    Status:
                  </Typography>
                  <Typography
                      variant="body2"
                      color="initial"
                      style={{
                        color: "white",
                        backgroundColor: (appointmentInvoices?.status || "").includes("Cancelled") ? "#FF0000" :
                            (appointmentInvoices?.status || "").includes("Declined") ? "#dcd90a" :
                                (appointmentInvoices?.status || "").includes("Pause") ? "#FFA500" :
                                    (appointmentInvoices?.status || "") !== "New User" ? "#219653" : "#09ABF1",
                      }}

                      sx={{ ml:2,fontSize: '12px', padding: '3px 8px', width: 'fit-content', marginBottom: '3px', fontWeight: 600, borderRadius: '100px', letterSpacing: '1px' }}

                  >
                    {appointmentInvoices?.status}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                    Amount:
                  </Typography>
                  <Typography
                      variant="body2"
                      color="initial"
                      sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}
                  >
                    ${appointmentInvoices?.amount}
                  </Typography>
                </Box>

              </Grid>

            </Grid>


          </Box>

        </Modal>
        <Modal
            style={{}}
            open={openupdate}
            onClose={handleCloseupdate}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

          {/* <Invoices appointmentInvoices={invoicedetails} /> */}
          <Box sx={invoicePayment}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>

              </Box>
              <Typography variant="h5" sx={{ fontWeight: '600' }}  >
                Plan Update
              </Typography>

              <Box sx={{
                backgroundColor: '#F2F2F2',
                // border: '1px solid #F2F2F2',
                fontSize: '25px',
                display: 'inline-block',
                borderRadius: '5px',
                padding: '5px 15px'
              }}>
                <CloseIcon
                    onClick={handleCloseupdate}
                    style={{ cursor: "pointer", color: '#000', marginTop: '-5px' }}
                />
              </Box>

            </Box>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth sx={{ marginTop: "20px" }}>
                <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                <Select
                    style={{ height:'56px' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPlan}
                    label="plan"
                    onChange={handlePlanChange}
                    renderValue={(selected) => {
                      const selectedPlanObj = crmSubscriptions.find(plan => plan.plan_id === selected);
                      return selectedPlanObj ? truncateText(selectedPlanObj.plan_name, 5) : '';
                    }}
                >
                  {crmSubscriptions?.length > 0 &&
                      crmSubscriptions.map((plan, index) => (
                          <MenuItem
                              value={plan.plan_id}
                              key={index}
                          >
                            <ListItemText primary={plan.plan_name} />
                          </MenuItem>
                      ))
                  }
                </Select>
              </FormControl>

            </Grid>
            <Box style={{display:"flex",justifyContent:"flex-end",marginTop:"10px"}}>
              <Button variant="contained" onClick={handleUpdatePlan}>Update Plan</Button>
            </Box>



          </Box>

        </Modal>
        {/* <Box sx={{}}>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Payment Method
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPaymentMethod} 
                onChange={handlePaymentMethodChange}
                label="Customer"
              >
                <MenuItem  value="existing_card">Existing Card</MenuItem>
                <MenuItem  value="credit_or_debit">Select Debit card / Credit card</MenuItem>

              </Select>
            </FormControl>
          </Box>
          {selectedPaymentMethod === 'credit_or_debit' && user?.active_payment_method?.payment_method_id === 4 && !useExistingCard &&
            <Box style={{ marginTop: "15px" }}>
              <SquareCard
                setSquareCard={setSquareCard}
                paymentMethod={user?.active_payment_method} 
              /> 
            </Box>
          } 
          {selectedPaymentMethod === 'existing_card' && (
            <Box style={{ display: "flex", flexDirection: "column", height: "auto", width: "100%", marginTop: "15px" }}>
              {selectedUserCard?.data?.cards?.length > 0 ? (
                selectedUserCard?.data?.cards?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box sx={{ position: "relative", mt: 2 }}>
                      <Box sx={{ position: "absolute", top: -11, right: -3 }}>
                        <CloseIcon
                          style={{ color: "#EB5757" }}
                          onClick={() => deleteusercard(item?.id)}
                        />
                      </Box>
                      <Card
                        sx={{
                          mt: 2,
                          border: `0.8px solid ${selectedCard === (item?.token || item?.id) || item?.status === "Active" ? "#128D64" : "#C4C4C4"}`,
                          borderRadius: "4px",
                          boxShadow: "none",
                          position: "relative",
                          overflow: "unset",
                          cursor: 'pointer'
                        }}
                          onClick={() => defaultusercard(item?.id)}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", width: '100%', px: '70px' }}>
                          <img
                            src={visa}
                            width="50px"
                            style={{ marginLeft: 2 }} 
                            alt=""
                          />
                          <Typography
                            variant="h5"
                            color="initial"          
                            sx={{ 
                              ml: 3,
                              fontSize: "16px",
                              "@media (max-width:500px)": {
                                marginLeft: "5px",
                                textAlign: "center",
                                fontSize: "15px",
                              },
                            }}
                          >
                            {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                          </Typography>
                          {(selectedCard === (item?.token || item?.id) || item?.status === "Active") && (
                            <Box sx={{ ml: "50px" }}>
                              <img
                                src={successIcon}
                                alt="successIcon"
                              />
                            </Box>
                          )}
                        </Box>
                      </Card>
                    </Box>
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                  There are no existing cards.
                </Typography>
              )}
            </Box>
          )}
*/}
        <Drawer
            anchor={"right"}
            open={openDrawer}
            onClose={() => handleCloseDrawer()}

        >
          {list()}
        </Drawer>


      </UserLayout>
  );
};

export default MembershipList;
