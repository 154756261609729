
import React, { useEffect, useRef, useState } from "react";
import ApexChartWrapper from "../../../lib/react-apexcharts";
import { Box, Grid, Typography } from "@mui/material";
import StatisticCard from "../../../components/crm-dashboard/statisticCard";
import Test from "../../../components/crm-dashboard/test";
import Map from "../../../components/crm-dashboard/map";
import RequestedAppointment from "../../../components/crm-dashboard/requestedAppointment";
import Notification from "../../../components/crm-dashboard/notification";
import MonthlyInsights from "../../../components/crm-dashboard/monthlyInsights";
import WeeklyInsights from "../../../components/crm-dashboard/weeklyInsights";
import MemberReport from "../../../components/crm-dashboard/MemberReport";
import CategorySale from "../../../components/crm-dashboard/categorySale";
import UpcomingEvents from "../../../components/crm-dashboard/upcomingEvents";
import Consulation from "../../../components/crm-dashboard/consultatin-stats";
import {useNavigate} from 'react-router-dom'
import momenttz from 'moment-timezone';
import {
  getAllDashboardDetails,
  getMemberReportsDashboard,
  salesHourReportDashboard,
  monthlyInsightReportDashboard,
  categoryReportDashboard,
  consultationReportDashboard,
  DailyMemberReportsDahboard,
  DailyMemberReportsDashboard,
  getMemberOverview,
  getWeeklyHours,
  AllDashboard,
  getMemberReportsMonthly,
  getSalesMonthly,
  categoryReport,
  monthlyconsultation,
  weeklyconsultation,
  consultatinDashboard,
  EventReportDashboard,
} from "../../../store/crm/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { InView } from "react-intersection-observer";
import Loader from "../../../components/loader/loader";
import Waveloading from "../../loading/Waveloading";
import DateRange from "../../../components/date-range/date-range";
import moment from "moment";
import Category from "../../../components/crm-dashboard/Category";
import MemberReportWeekly from "../../../components/crm-dashboard/MemberReportWeekly";
import MemberReportMonthly from "../../../components/crm-dashboard/MemberReportMonthly";
import WeeklyHours from "../../../components/crm-dashboard/WeeklyHours";
import MonthlyHours from "../../../components/crm-dashboard/MonthlyHours";
import Consultationweekly from "../../../components/crm-dashboard/consultationweekly";
import Consultationmonthly from "../../../components/crm-dashboard/consultationMonthly";
import { NotificationAlert } from "../../../store/crm/call-log";

const memberSignup = "Membership Signup";
const memberShipAmountTitle = "Membership Amount";
const memberShipRenewel = "Membership Renewel";
const totalRenewals = "Renewals Amount";
const totalDicline = "Total Decline";
const totalCancellations = "Total Cancellations";
let timeZone = localStorage.getItem("timeZone");

const Dashboard = () => {
  // const [loading, setloading] = useState(falsa);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const crmDashboardStore = useSelector((state) => state?.crmDashboardStore);
  const [start_date, setStartDate] = useState(moment().startOf('day').toDate());
  const [end_date, setEndDate] = useState(moment().endOf('day').toDate());
  const [hasIntersected, setHasIntersected] = useState(false)

  const { 
    dashboard,
    dashboardMember,
    members_report,
    loading,
    members_report_Daily,
    sale_hour_report,
    week_hour_report,
    monthly_insight,
    category_report,
    consultatio_report,
    consultatio_report_monthly,
    members_report_loading,
    sale_hour_report_loading,
    members_report_Daily_loading,
    members_report_Monthly,
    sale_hour_report_Monthly,
    monthly_insight_loading,
    category_report_loading,
    consultatio_report_loading,
    category_report_dashboard,
    consultatio_report_weekly,
    alldashboard,
    Event_report_loading,
    Event_report,
  } = crmDashboardStore;

  const [Notifications, setNotifications] = useState([]);
//  const callLogStore = useSelector(state => state?.callLogStore)
//   const {Notification=[]} = callLogStore
  const fetchNotifications = async () => {
    const response = await dispatch(NotificationAlert());
    setNotifications(response?.payload); 
   console.log(" response ",response?.payload)
  };
  
  console.log("category1 ",Notifications)
  useEffect(() => {
    (async () => {
      const now = momenttz.tz(timeZone).toDate();
      await dispatch(AllDashboard())
    await dispatch(EventReportDashboard());
   
    })();
  }, []);


  const targetRef = useRef(null);
  const MemberRef = useRef(null);
  const SalesRef = useRef(null);
// Booking Member over Section 
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Trigger API calls when the <Grid> container comes into view

          // Start and end of the current week in the specified timezone
          const startOfWeek = momenttz.tz(timeZone).startOf('isoWeek').startOf('day').toDate();
          const endOfWeek = momenttz.tz(timeZone).endOf('isoWeek').endOf('day').toDate();

          // Start and end of the current month in the specified timezone
          const startOfMonth = momenttz.tz(timeZone).startOf('month').toDate();
          const endOfMonth = momenttz.tz(timeZone).endOf('month').toDate();

          // Dispatch API call for dashboard details with the formatted dates
          dispatch(getAllDashboardDetails({
            start_date: moment(startOfWeek).format('YYYY-MM-DD'),
            end_date: moment(endOfWeek).format('YYYY-MM-DD')
          }));

          // Dispatch API call for member overview with the formatted dates
          dispatch(getMemberOverview({
            start_date: moment(startOfMonth).format('YYYY-MM-DD'),
            end_date: moment(endOfMonth).format('YYYY-MM-DD')
          }));
        }      });
    }, {
      root: null, // assuming the viewport; adjust as necessary
      rootMargin: '0px',
      threshold: 0.1 // Adjust this value based on when you want the callback to fire
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
    
  }, [dispatch]);



  // MmemberReports Section

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Get the current date and time in the specified timezone
          const currentDateTime = momenttz.tz(timeZone).toDate();
          const currentDate = momenttz.tz(timeZone).format('YYYY-MM-DD');
          const currentYear = currentDateTime.getFullYear();
          const currentMonth = currentDateTime.getMonth();

          // Dispatch the daily member reports with the current date
          dispatch(DailyMemberReportsDashboard(currentDate));

          // Get the start and end of the week in the specified timezone
          const startOfWeek = momenttz.tz(timeZone).startOf('isoWeek').format('YYYY-MM-DD');
          const endOfWeek = momenttz.tz(timeZone).endOf('isoWeek').format('YYYY-MM-DD');

          console.log("payload", startOfWeek, endOfWeek);

          // Dispatch the member reports dashboard for the week
          dispatch(getMemberReportsDashboard({
            start_date: startOfWeek,
            end_date: endOfWeek
          }));

          // Dispatch the monthly member reports
          dispatch(getMemberReportsMonthly({
            year: currentYear,
            month: currentMonth + 1,  // Adding 1 because JavaScript months start at 0
          }));
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });

    if (MemberRef.current) {
      observer.observe(MemberRef.current);
    }

    return () => {
      if (MemberRef.current) {
        observer.unobserve(MemberRef.current);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasIntersected) {
          setHasIntersected(true);

          // Get the current date and time in the specified timezone
          const currentDateTime = momenttz.tz(timeZone).toDate();
          const currentDate = momenttz.tz(timeZone).format('YYYY-MM-DD');
          const currentYear = currentDateTime.getFullYear();
          const currentMonth = currentDateTime.getMonth();

          // Dispatch the sales hour report for the current date
          dispatch(salesHourReportDashboard(currentDate));

          // Get the start and end of the week in the specified timezone
          const startOfWeek = momenttz.tz(timeZone).startOf('isoWeek').format('YYYY-MM-DD');
          const endOfWeek = momenttz.tz(timeZone).endOf('isoWeek').format('YYYY-MM-DD');

          // Dispatch the weekly hours
          dispatch(getWeeklyHours({
            start_date: startOfWeek,
            end_date: endOfWeek
          }));

          // Dispatch the monthly sales report
          dispatch(getSalesMonthly({
            year: currentYear,
            month: currentMonth + 1,  // Adding 1 because JavaScript months start at 0
          }));
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });

    if (SalesRef.current) {
      observer.observe(SalesRef.current);
    }

    return () => {
      if (SalesRef.current) {
        observer.unobserve(SalesRef.current);
      }
    };
  }, [dispatch, hasIntersected]);


 

  const fetchBookingOverview = async ( start_date, end_date) => { 
  console.log("values",start_date, end_date)
    await dispatch(getAllDashboardDetails({
      start_date: moment( start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD')
  }));
  };
  const fetchMemberoverview = async ( start_date, end_date) => {
    await dispatch(getMemberOverview({
      start_date: moment( start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD')
  }));
  };

const fetchWeeklyData = async ( start_date, end_date) => {
  await dispatch(getMemberReportsDashboard({
    start_date: moment( start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD')
}));
};
const fetchDailyData = async (date) => {
  await dispatch(DailyMemberReportsDashboard(date));  
};
// fetchmonthlyData

const fetchmonthlyData = async (year,month) => {
  console.log("year",year,month)
  await dispatch(getMemberReportsMonthly({    
    year: year,
    month: month, // Adding 1 because JavaScript months start at 0
 
   }));
};


// 
const fetchMonthlySales = async (year,month) => {
  console.log("year",year,month) 
  await dispatch(getSalesMonthly({    
    year: year,
    month: month, 
   }));
};

const fetchweeklySales = async ( start_date, end_date) => {
  await dispatch(getWeeklyHours({
    start_date: moment( start_date).format('YYYY-MM-DD'),  
    end_date: moment(end_date).format('YYYY-MM-DD')
  }));
};



const fetchMonthlyconsultation = async (year,month) => {
  console.log("year",year,month)
  await dispatch(monthlyconsultation({    
    year: year,
    month: month, 
   }));
};

const fetchweeklyconsultation = async ( start_date, end_date) => {
  await dispatch(weeklyconsultation({
    start_date: moment( start_date).format('YYYY-MM-DD'),  
    end_date: moment(end_date).format('YYYY-MM-DD')  
  }));
};

const fetchDailyconsultation= async (date) => {
  await dispatch(consultatinDashboard(date));
};


const CategorySales = async ( start_date, end_date) => {
  console.log("category report 1")
  await dispatch(categoryReport({
    start_date: moment( start_date).format('DD-MM-YYYY'),     
    end_date: moment(end_date).format('DD-MM-YYYY')
  }));
};   
 
const CategorySalesReport = async ( start_date, end_date) => {
  await dispatch(categoryReportDashboard({
    start_date: moment( start_date).format('DD-MM-YYYY'),  
    end_date: moment(end_date).format('DD-MM-YYYY')
  }));  
};     
const fetchSalesHours = async (date) => { 
  await dispatch(salesHourReportDashboard(date));
}

  
// useEffect(() => {
//   loadMemberReportsDashboard(true); 
// }, [ start_date, end_date, members_report_loading,members_report_Daily_loading,]);


    const loadmonthlyInsightReportDashboard = async () => {
     
      const currentDateTime = new Date(); 
      const currentYear = currentDateTime.getFullYear();
      const currentMonth = currentDateTime.getMonth();
    
      console.log("monthly insight");
      // Now this check only depends on your data loading state,
      // since the function itself is only triggered when inView is true
      if (!monthly_insight_loading && !monthly_insight) {
        console.log("monthly insight in function");
        await dispatch(monthlyInsightReportDashboard({    
          year: currentYear,
          month: currentMonth + 1, // Adding 1 because JavaScript months start at 0
        }));
      }
    };
    
  const fetchmonthly = async (year,month) => { 
     console.log("other functions") 
    const currentDateTime = new Date();     
    console.log("face",year,month)
    const currentYear = currentDateTime.getFullYear();
    const currentMonth = currentDateTime.getMonth();   
    // if (!monthly_insight_loading && !monthly_insight)  {
      await dispatch(monthlyInsightReportDashboard(year,month));                 
    // }       
  }; 
  const loadconsultationReportDashboard = async (inView) => {
    if (!consultatio_report_loading && !consultatio_report && inView) {
      const currentDateTime = new Date(); 
      const currentDate = new Date().toISOString().split('T')[0];
      const currentYear = currentDateTime.getFullYear();
      const currentMonth = currentDateTime.getMonth();
      await dispatch(consultatinDashboard(currentDate));
      const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');   
      const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');     
      await dispatch(weeklyconsultation({   
        start_date: startOfWeek,      
        end_date: endOfWeek 
      })); 

      await dispatch (monthlyconsultation({  year: currentYear,
        month: currentMonth + 1,}))    
 
    }     
    
  };

  const loadcategoryReportDashboard = async (inView) => {
    const currentDate = moment();  
    


    const endOfLastWeek = currentDate.subtract(1, 'weeks').endOf('isoWeek').toDate();
    const startOfLastWeek = moment(endOfLastWeek).subtract(6, 'days').toDate();
  
       
    // Calculate the start and end dates for the current week
    const startOfWeek = moment().startOf('isoWeek').toDate();
    const endOfWeek = moment().endOf('isoWeek').toDate();
    
    if (!category_report_loading && !category_report?.length && inView) {
      console.log("category report 2")
      console.log("Last week dates", startOfLastWeek, endOfLastWeek);
      await dispatch(categoryReportDashboard({
        start_date: moment(startOfLastWeek).format('DD-MM-YYYY'),
        end_date: moment(endOfLastWeek).format('DD-MM-YYYY')
      }));
      await dispatch(categoryReport({      
        start_date: moment(startOfWeek).format('DD-MM-YYYY'),
        end_date: moment(endOfWeek).format('DD-MM-YYYY')
      }));
    }
  
  
  };

  
  
  console.log("dashboard",category_report )
  const loadingData =
    loading||
    members_report_loading ||
    sale_hour_report_loading ||     
    members_report_Daily_loading||
    monthly_insight_loading ||
    category_report_loading ||
    consultatio_report_loading;
  return (   
    <ApexChartWrapper>
      <Grid container spacing={6}>  
        <Grid item xs={12} md={6} lg={3}>
          <StatisticCard
            title={"Today’s Total Sales"}
            color={"#2196F3"}
            isAmount
            value={alldashboard?.total_sales}
          />
        </Grid>    

        <Grid item xs={12} md={6} lg={3}>
          <StatisticCard
          // onClick={()=>navigate('/crm/appointments',{state:{value:"Today Completed"}})}
            title={"Completed Appointment"}
            color={"#4CAF50"}  
            value={alldashboard?.completed}   
          />   
        </Grid>
  
        <Grid item xs={12} md={6} lg={3}>  
          <StatisticCard
            title={"Today’s Forecast"}   
            color={"#FFC107"}
            isAmount   
            value={alldashboard?.today_forecast}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatisticCard
            // onClick={()=>navigate('/crm/appointments',{state:{value:"Today"}})}
            title={"Today’s Appointment"}
            color={"#F44336"}   
            value={alldashboard?.total_appointments_today}
            />    
        </Grid>     
      </Grid>    
      <InView onChange={(inView) => inView && loadmonthlyInsightReportDashboard()}>
  {({ inView, ref, entry }) => (      
    <Grid container sx={{ display: "unset" }} ref={ref}>            
      <Grid
        item
        lg={12}       
        sx={{ background: "#fff", borderRadius: "8px", mt: 2 }}
      >
        <MonthlyInsights insights={monthly_insight} fetchData={fetchmonthly} />
      </Grid> 
    </Grid>
  )}
</InView>
      <Grid container spacing={6} sx={{ mt: "-1px" }}></Grid>  
      <Grid     
       ref={targetRef}               
        container        
        sx={{    
          display: "flex",  
          justifyContent: "space-between",    
          background: "#fff",    
          borderRadius: "8px",       
          mt: 2,
        }}      
        >  
        <Grid item xs={12} md={5.8}>     
          <Test    
            sx={{ pl: { xl: 2, lg: 2, sm: 0, xs: 0 } }}       
            totalNewBooking={dashboard?.total_bookings_crm}   
            newBookingtitle={"Booked From CRM"}           
            completeTotalTitle={"New Intro Bookings"}      
            completedTotal={dashboard?.new_booking}
            noShowTotal={dashboard?.no_show}
            noShowTitle={"No Shows"}
            cancelationsTitle={"Man Hours "}  
            cancelationsTotal={dashboard?.man_hours}         
            completeTitle={"Self Booked"}    
            completeTotal={dashboard?.total_bookings_website}  
            newMemberTitle={" Repeated Customer Bookings"}  
            newMembersTotal={dashboard?.repeated_customers}
            bookingRate={dashboard?.booking_rate}      
            bookingRateTitle={"Booking Completion Rate"}
            fetchData={fetchBookingOverview} 
          />      
        </Grid>  
        <Grid item xs={12} md={5.8} sx={{ pr: 2 }}>        
          <WeeklyInsights
            newMemberTitle={"New Members"} 
            newMembersTotal={dashboardMember?.new_members}
            activeTitle={"Active "}
            activeTotal={dashboardMember?.active_members}
            declinedTitle={"Declined"}
            declinedTotal={dashboardMember?.declined_memberships}
            memberCancelationTotal={dashboardMember?.membership_Cancelled}
            memberCancelationTitle={"Membership Cancellations"}
            fetchData={fetchMemberoverview}
          />  
        </Grid>
      </Grid>
      
      <Typography
        variant="h5"
        sx={{
          background: "#fff",
          padding: "15px",
          mt: 2,
        }}
      >
        Members Report
      </Typography> 
          <Grid              
            ref={MemberRef}  
            container
            sx={{
              display: "flex",  
              justifyContent: "space-between", 
              background: "#fff",
              padding: "15px",
            }}
          >
            <Grid item xs={12} md={3.9}sx={{ mt: 2 }}>
              <MemberReport
              // onClick={()=>navigate('/crm/member-report',{state:{value:'Today'}})}
                membershipTitle={"Daily Membership"}
                memberSignup={ memberSignup}
                memberSignupTotal={ 
                  members_report_Daily?.daily_membership?.membership_signup
                }
                memberShipAmountTitle={memberShipAmountTitle}
                memberShipAmountTotal={   
                  members_report_Daily?.daily_membership?.membership_amount
                }
                memberShipRenewel={memberShipRenewel}
                memberShipRenewelTotal={
                  members_report_Daily?.daily_membership?.membership_renewal
                }
                totalRenewals={totalRenewals}
                renewalsTotal={
                  members_report_Daily?.daily_membership?.membership_renewal_amount
                }
                totalDicline={totalDicline}
                totalDiclineTotal={  
                  members_report_Daily?.daily_membership?.total_decline
                }
                totalCancellations={totalCancellations}
                totalCancellationsTotal={
                  members_report_Daily?.daily_membership?.total_cancellation
                }
                total69Member={
                  members_report_Daily?.daily_membership?.total_69_member_plan
                }
                total69MemberAmount={
                  members_report_Daily?.daily_membership?.total_69_member_amount
                }
                total99Member={
                  members_report_Daily?.daily_membership?.total_199_member_plan
                }
                total99MemberAmount={
                  members_report_Daily?.daily_membership?.total_199_member_amount
                }
                total149Member={
                  members_report_Daily?.daily_membership?.total_149_member_plan
                }
                total149MemberAmount={
                  members_report_Daily?.daily_membership?.total_149_member_amount
                }
                fetchData={fetchDailyData}
              />
            </Grid>

            <Grid
              item 
              xs={12}
              md={3.9}
              sx={{ background: "#fff", borderRadius: "8px", mt: 2 }}
            >
              <MemberReportWeekly
                // onClick={() => navigate('/crm/member-report', { state: { value: 'Weekly' } })}
                {...{
                  memberSignup,
                  memberShipAmountTitle,
                  memberShipRenewel,
                  totalRenewals,
                  totalDicline,
                  totalCancellations,
                }}
                membershipTitle={"Weekly Membership"}
                memberSignupTotal={
                  members_report?.weekly_membership?.membership_signup
                }
                memberShipAmountTotal={
                  members_report?.weekly_membership?.membership_amount
                }
                memberShipRenewelTotal={
                  members_report?.weekly_membership?.membership_renewal
                }
                renewalsTotal={
                  members_report?.weekly_membership?.membership_renewal_amount
                }
                totalDiclineTotal={
                  members_report?.weekly_membership?.total_decline
                }
                totalCancellations={totalCancellations}
                totalCancellationsTotal={
                  members_report?.weekly_membership?.total_cancellation
                }
                total69Member={
                  members_report?.weekly_membership?.total_69_member_plan
                }
                total69MemberAmount={
                  members_report?.weekly_membership?.total_69_member_amount
                }
                total99Member={
                  members_report?.weekly_membership?.total_199_member_plan
                }
                total99MemberAmount={
                  members_report?.weekly_membership?.total_199_member_amount
                }
                total149Member={
                  members_report?.weekly_membership?.total_149_member_plan
                }
                total149MemberAmount={
                  members_report?.weekly_membership?.total_149_member_amount
                }
                // onDatesChange={(start, end) => {
                //   setStartDate(start);
                //   setEndDate(end);
                // }}  
                fetchData={fetchWeeklyData}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3.9}
              sx={{ background: "#fff", borderRadius: "8px", mt: 2 }}
            >  
              <MemberReportMonthly
              //  onClick={()=>navigate('/crm/member-report',{state:{value:'Monthly'}})}
                membershipTitle={"Monthly Membership"}
                {...{
                  memberSignup,
                  memberShipAmountTitle,
                  memberShipRenewel,
                  totalRenewals,
                  totalDicline,    
                  totalCancellations,
                }}
                memberSignupTotal={
                  members_report_Monthly?.monthly_membership?.membership_signup
                }
                memberShipAmountTotal={
                  members_report_Monthly?.monthly_membership?.membership_amount
                }
                memberShipRenewelTotal={
                  members_report_Monthly?.monthly_membership?.membership_renewal
                }
                renewalsTotal={
                  members_report_Monthly?.monthly_membership?.membership_renewal_amount
                }
                totalDiclineTotal={
                  members_report_Monthly?.monthly_membership?.total_decline
                }
                totalCancellationsTotal={
                  members_report_Monthly?.monthly_membership?.total_cancellation
                }
                total69Member={
                  members_report_Monthly?.monthly_membership?.total_69_member_plan
                }
                total69MemberAmount={
                  members_report_Monthly?.monthly_membership?.total_69_member_amount
                }
                total99Member={
                  members_report_Monthly?.monthly_membership?.total_199_member_plan
                }
                total99MemberAmount={
                  members_report_Monthly?.monthly_membership?.total_199_member_amount
                }
                total149Member={
                  members_report_Monthly?.monthly_membership?.total_149_member_plan
                }
                total149MemberAmount={
                  members_report_Monthly?.monthly_membership?.total_149_member_amount
                }
                
              fetchData={fetchmonthlyData}
              />
            </Grid> 
          </Grid>
      
     
    
      <Typography  
        variant="h5"
        sx={{
          background: "#fff",
          padding: "25px 25px 0 25px",
          fontFamily: "helvetica-lt-std-bold",
          borderRadius: "8px",  
        }}
      >
        SALES-HOURS
      </Typography>
  
    
          <Grid
            ref={SalesRef}
            container  
            sx={{
              display: "flex",
              justifyContent: "space-between",      
              padding: "15px",
              background: "#fff",
              borderRadius: "8px", 
              
            }}   
          >
            <Grid item xs={12} lg={3.9} md={6} sm={12}>
              <Map
                saleshour={" Daily SALES-HOURS"}    
                textcolor={"#000"}
                salesData={sale_hour_report?.daily}
                fetchData={fetchSalesHours}
              />
            </Grid>  
            <Grid item xs={12} lg={3.9} md={6} sm={12}>  
              <WeeklyHours   
                saleshour={"Weekly SALES-HOURS"}   
                textcolor={"rgb(46, 125, 50)"}  
                salesData={week_hour_report?.weekly}  
                onDatesChange={(start, end) => {
                  setStartDate(start);
                  setEndDate(end);
                }}   
                fetchData={fetchweeklySales}     
              />
            </Grid>
            <Grid item xs={12} lg={3.9} md={6} sm={12}>     
              <MonthlyHours
                saleshour={"Monthly SALES-HOURS"}
                textcolor={"#000"}
                salesData={sale_hour_report_Monthly?.monthly}
                fetchData={fetchMonthlySales}  
              />
            </Grid>
          </Grid>
       
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid
          item
          xs={12}   
          md={5.8}
          sx={{ background: "#fff", borderRadius: "8px", mt: 2 }}
        >
          <RequestedAppointment />   
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ background: "#fff", borderRadius: "8px", mt: 2 }}
        >
        <InView as="div" onChange={(inView, entry) => inView && fetchNotifications()}>
        
          <Notification Notifications={Notifications} />
        
        </InView>
        </Grid>
      </Grid>    

      <Grid container sx={{ background: "#fff", borderRadius: "8px", mt: 2  }}>
        <Grid item lg={11.9} md={11.9} sm={11.9} xs={11.9}>
          <UpcomingEvents Events={Event_report} />
        </Grid>
      </Grid> 
  
      
      <InView onChange={loadcategoryReportDashboard}>
    
        {({ inView, ref, entry }) => (
          <Grid container sx={{ display: "flex",justifyContent:"space-between" }} ref={ref}>
            <Grid      
              item       
              lg={7.5}
              sm={12}
              xs={12} 
              sx={{ background: "#fff", borderRadius: "8px", mt: 2 }}
            >   
<CategorySale 
category={category_report_dashboard}   
onDatesChange={(start, end) => { 
    setStartDate(start); 
    setEndDate(end);
  }} 

  fetchData={CategorySales}  

  />
            </Grid>
            <Grid    
              item
              lg={4.5}
              sm={12}
              xs={12}
              sx={{ background: "#fff", borderRadius: "8px", mt: 2 }}
            >
              
 <Category category={category_report} 

       onDatesChange={(start, end) => {  
    setStartDate(start);
    setEndDate(end);  
  }}  
  fetchData={CategorySalesReport} 
  />
            </Grid>
          </Grid>
        )}
      </InView>

      <Typography    
        variant="h5"   
        sx={{
          background: "#fff",
          padding: "25px",
          mt: 2,
          fontFamily: "helvetica-lt-std-bold",
        }}   
      >
        Consultation Stats
      </Typography>
      <InView onChange={loadconsultationReportDashboard}>
        {({ inView, ref, entry }) => (
          <Grid
            ref={ref} 
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
              background: "#fff",
              borderRadius: "8px",
            }}
          >
            <Grid item xs={12} md={3.9}> 
              <Consulation   
                consultationStats={consultatio_report?.daily}
                consultationTitle={"Daily Consultation Stats"}
                textcolor={"#000"}
                bgRow={"#E8F5E9"}
                fetchData={fetchDailyconsultation} 
              />
            </Grid>   
      
            <Grid item xs={12} md={3.9}>
              <Consultationweekly
                consultationStats={consultatio_report_weekly?.weekly}
                consultationTitle={"Weekly Consultation Stats"}
                textcolor={"rgb(46, 125, 50)"}
                bgRow={"#E8F5E9"}
                fetchData={fetchweeklyconsultation}   
              />
            </Grid>
            <Grid item xs={12} md={3.9}>
              <Consultationmonthly
                consultationStats={consultatio_report_monthly?.monthly}
                consultationTitle={"Monthly Consultation Stats"}
                textcolor={"#000"}   
                bgRow={"#FFF7F8"}
                fetchData={fetchMonthlyconsultation} 
              />   
            </Grid>
          </Grid>
        )}
      </InView>
      <Grid container spacing={6} sx={{ my: 3 }}>
        <Grid item xs={12} md={6} lg={3}>
          <StatisticCard
          // onClick={()=>navigate('/crm/wallet-report')}
            title={"Active Member Wallet Balance"}
            color={"#4CAF50"}
            isAmount
            value={alldashboard?.active_member_wallet_balance}
          />
        </Grid>  
        <Grid item xs={12} md={6} lg={3}>
          <StatisticCard
          // onClick={()=>navigate('/crm/wallet-report')}
            title={"Declined  Member Wallet Balance"}  
            color={"#FFC107"}
            isAmount   
            value={alldashboard?.declined_members_wallet_balance}
          />
        </Grid>    
        <Grid item xs={12} md={6} lg={3}>
          <StatisticCard
          // onClick={()=>navigate('/crm/wallet-report')}
            title={"Canceled Member Wallet Balance"}
            color={"#F44336"}
            isAmount
            value={alldashboard?.cancelled_members_wallet_balance}
          />  
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatisticCard
          // onClick={()=>navigate('/crm/wallet-report')}
            title={"Total Wallet Balance"}
            color={"#2196F3"}
            isAmount
            value={alldashboard?.wallet_balance}
          />
        </Grid>
      </Grid>
      {loadingData && <Waveloading fullSize />}
  
    </ApexChartWrapper>
  );
};

export default Dashboard;
