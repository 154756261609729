import React, {useState} from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {Box, Menu, MenuItem, Typography} from "@mui/material";
import moment from "moment";
import {useSelector} from "react-redux";


const dateArray =[
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "Next Week",
    "This Month",
    "Last Month",
    "Maximum",
    "Custom Range",
]
const DateRange = ({setStartDate,setEndDate,startDate,endDate,color, style,wid, widthDash, smWidth,onChange,maxHeight}) => {

    const [calendarStartTime, setCalendarStartTime] = useState(null);
    const [calendarEndTime, setCalendarEndTime] = useState(null);
    const [active, setActive] = useState('');
    const [isCustomDateRange, setIsCustomDateRange] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const authStore = useSelector((state) => state?.authStore);
    const { user } = authStore;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); 
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const triggerOnChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        // Trigger the passed onChange function with the new start and end dates
        if (onChange) {
            onChange(start, end);
        }
    };

    const onChangeCustomRange = (dates) => {
        const [start, end] = dates;
        setCalendarStartTime(start);
        setCalendarEndTime(end);
        if (end) {
            setIsCustomDateRange(false);
            triggerOnChange(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'));
            handleClose();
        }
    };


    const handleSelectDate = async (key) => {
        setActive(key);
        setIsCustomDateRange(false);
        let start, end;
    
        switch (key) {
            case 'Today':
                start = end = moment().format('YYYY-MM-DD');
                break;
            case 'Maximum':
                start = moment(user?.created_at).format('YYYY-MM-DD');
                end = moment().format('YYYY-MM-DD');
                break;
            case 'Yesterday':
                start = end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'This Week':
                start = moment().startOf('isoWeek').format('YYYY-MM-DD');
                end = moment().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'Last Week':
                start = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                end = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'Next Week':  // Added case for Next Week
                start = moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                end = moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'This Month':
                start = moment().startOf('month').format('YYYY-MM-DD');
                end = moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'Last Month':
                start = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                end = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                break;
            case 'Custom Range':
                if (active !== key) {
                    setCalendarStartTime(null); 
                    setCalendarEndTime(null);
                } 
                setIsCustomDateRange(true);
                return; 
        }
        triggerOnChange(start, end);  
        if (key !== 'Custom Range') {
            handleClose();
        }
    };
    
    

    return (
        <Box
            sx={{width:{lg:widthDash || "100%", md:'100%', sm:'100%', xs:'100%'},'@media (min-width:1600px)': {width:smWidth}, mr:{lg:0, xs:2}}}
        >
           <Box
    sx={{
        width:wid || "100%",
        background: color || '#fff',
        borderRadius: '4px',
        minHeight: '44px', // Use min-height instead of height for responsive resizing
        maxHeight: maxHeight,
        cursor: 'pointer',
        fontSize: "1rem",
        p: '13px 16px', // Padding can be adjusted if necessary 
        fontFamily: 'helvetica-lt-std-roman',
        '@media (max-width: 400px)': {
            minHeight: "48px", // Adjust min-height if necessary for smaller screens
        },
    }}
    onClick={handleClick}
>
    {startDate && moment(startDate).format('MMMM DD, YYYY')} {startDate && " - "} {endDate && moment(endDate).format('MMMM DD, YYYY')}
</Box>   


            {!isCustomDateRange && <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "max-content",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                sx={{
                    display: "flex"
                }}
            >
                {dateArray?.map((item, index) => (
                    <MenuItem
                        key={index}
                        sx={{
                            py: '7px',
                            width: "230px",
                            my: '3px',
                            background: item === active ? "#09ABF1!important" : '#fff',
                            color: item === active ? "#fff" : '#000',
                            "&:hover": {
                                background: "#09ABF1",
                                color: "#fff",
                            }
                        }}
                        onClick={() => handleSelectDate(item)}
                    >
                        {item}
                    </MenuItem>

                ))}
            </Menu>}
            {isCustomDateRange && <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "max-content",
                        height:"max-content",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        "ul":{
                            p:'0',
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                sx={{
                    display: "flex"
                }}
            >
                <DatePicker
                        selected={calendarStartTime}
                        onChange={onChangeCustomRange}
                        startDate={calendarStartTime}
                        endDate={calendarEndTime}
                        shouldCloseOnSelect={false}
                        selectsRange
                        inline
                        monthsShown={2}
                    />
            </Menu>
            }
        </Box>
    );
};

export default DateRange;