import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {Box} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { removeUser } from "../../store/auth";
import { styled } from '@mui/material/styles'; 
import MuiDrawer from '@mui/material/Drawer';

import { AiOutlineMenu } from "react-icons/ai"; 
import {
  MdFileCopy,
  MdLanguage,
  MdLocalHospital,
  MdOutlineEvent,
  MdPowerSettingsNew,
    MdCardMembership,
  MdVpnKey,
} from "react-icons/md";

import { MdSupervisedUserCircle } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import { confirmation } from "../../services/utills";
import {scrollCSS} from "../../App";
const sideData = [
  {
    name: "Roles",
    logo: <MdSupervisedUserCircle style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/roles",
      active:['roles',"edit-role",'add-role']
  },
  {
    name: "Permissions",
    logo: <MdVpnKey style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/permissions",
      active:['permissions',"edit-permissions",'add-permissions']
  },
  {
    name: "Menus",
    logo: <AiOutlineMenu style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/menus",
      active:['menus',"edit-menus",'add-menus']
  },
  {
    name: "GHL WebHook",
    logo: <AiOutlineMenu style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/webhook",
      active:['GHLWebHook']
  },
  {
    name: "Membership Plans",
    logo: <MdCardMembership style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/all-membership-plans",
      active:['all-membership-plans',"edit-membership",'add-membership']             
  },
  {
    name: "Payment Methods",     
    logo: <MdOutlinePayment style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/payment-methods",   
      active:['payment-methods',"edit-payment-methods",'add-payment-methods']
  },
  {
    name: "Languages",
    logo: <MdLanguage style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/languages",
      active:['languages',"edit-language",'add-language']
  },
  {
    name: "Notification Template",
    logo: <MdFileCopy style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/notification-templates/customer-signup/1",
      active:[
          'customer-signup',
          "customer-created-from-crm",
          "membership-signup",
          "membership-upgraded",
          "appointment-booked",
          "appointment-rescheduled",
          "appointment-canceled",
          "reminder-for-appointment-before",
          "reminder-for-appointment-day",
          "check-in",
          "receipt-generated",
          "ask-for-review",
          "customer-no-show",
          "clinic-cancel-membership",
          "credit-card-decline",
      ]
  },
  // /admin/configuration/refralpoints
  {
    name: "Referral Points Settings",
    logo: <MdLocalHospital style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/refralpoints",
      active:['clinics',"edit-clinic",'add-clinic']
  },
  {
    name: "Clinics",
    logo: <MdLocalHospital style={{ fontSize: "1.5rem" }} />,
    url: "/admin/configuration/clinics",
      active:['clinics',"edit-clinic",'add-clinic']
  },
];

export const drawerWidth = 300;
export const openedMixin = (theme,customWidth) => ({
  width: customWidth || drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
        zIndex:'11',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
        ".css-12i7wg6-MuiPaper-root-MuiDrawer-paper":{
            zIndex:'11',
        },
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
);

const AdminSidebar = ({ sidebarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {pathname} = location;

  const handleLogout = async () => {
    if (await confirmation("Are you want to logout", "Yes, Logout!")) {
      localStorage.removeItem("token");
      dispatch(removeUser());
      const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            }
    
            // Navigate to login page
            navigate("/login");
    
            // Optionally, force a page reload
            window.location.reload();
    }
  };
  const handleButtonClick = (val) => {
    navigate(val);
  };
  const checkActive =(activeArray)=> {
      // console.log(pathname)
      // console.log(activeArray)
      let res = pathname.split('/')
      let lastValue
      if (pathname.includes('/admin/configuration/notification-templates/')){
          lastValue = res[res?.length - 2];
      }else if (pathname.includes('edit-')) {
          lastValue = res[res?.length - 2];

      } else {
          lastValue = res[res?.length - 1];
      }
      return !!activeArray.includes(lastValue)
  }
  return (
      <CustomDrawer sx={{    display: { md: "block", xs: "none", sm: "none", lg:"block" }, }} variant="permanent" open={sidebarOpen}>
        <Box
            sx={{
              borderRight: " 1px solid #dee2e6",
              height: "100vh",
              padding: sidebarOpen ? "16px 30px" : "6px",
              overflowY: "auto",
              ...scrollCSS,
           
            }}
        >
          <Box
              sx={{
                mb: sidebarOpen ? "25px" : "90px",
              }}
          >
            {sidebarOpen && (
                <Link to={""}>
                  <img src={logo} alt={"logo"} height={"fit-content"} width={"100%"}/>
                </Link>
            )}
          </Box>
          <Box>
            <List
                sx={{
                  width: "100%",
                  ".MuiListItemIcon-root ": {
                    minWidth: "37px",
                  },
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
              {sideData?.map((item, index) => (
                  <ListItemButton
                      key={index}
                      onClick={() => handleButtonClick(item?.url)}
                      sx={{
                        borderRadius: "7px",
                        mb: "7px",
                        backgroundColor: checkActive(item?.active) ? "#C9EDFC" : "#fff",
                        "&:hover": {
                          backgroundColor: "#C9EDFC",
                        },
                      }}
                  >
                    <ListItemIcon>{item?.logo}</ListItemIcon>
                    {sidebarOpen && <ListItemText primary={item?.name}/>}
                  </ListItemButton>
              ))}
              <ListItemButton
                  onClick={() => handleLogout()}
                  sx={{
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "#C9EDFC",
                    },
                  }}
              >
                <ListItemIcon>
                  <MdPowerSettingsNew style={{fontSize: "1.5rem"}}/>
                </ListItemIcon>
                {sidebarOpen && <ListItemText primary="Logout"/>}
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </CustomDrawer>
  );
};

export default AdminSidebar;
