import { useState } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send';
import {IconButton} from "@mui/material";
import { GetMessages, ReadMessages, SendMsg } from '../../store/crm/chat-module';

const Form = styled('form')(({ theme }) => ({
    padding: theme.spacing(1.1, 5, 5),
    display:'flex',
    alignItems:'center',
}))


const SendMsgForm = (props) => {
    const { store, dispatch, } = props; 
    const [msg, setMsg] = useState('');

    const handleSendMsg = async (e) => {
        e.preventDefault();

        if (store && store.selectedChat && msg.trim().length) {
            const user_id = store?.selectedChat?.user_id;

            const newMessage = {
                user_id: user_id,
                message: msg,
                created_at: new Date().toISOString(),
                from_user: true
            };

            // Optimistically update the message in the local store
            dispatch({
                type: 'ADD_MESSAGE_OPTIMISTICALLY',
                payload: newMessage
            });

            // Send message over WebSocket
            // sendMessageViaWebSocket(newMessage);

            // Optionally, you can still send the message via API
            const response = await dispatch(SendMsg({ user_id: user_id, message: msg }));
            console.log("send message", response);
            dispatch(GetMessages(user_id));
            dispatch(ReadMessages(user_id));

            setMsg('');
        }
    };

    return (
        <Box sx={{background: '#F6F9FB',borderTop:'1px solid #E2E8F0',pt:'7px'}}>
            <Form onSubmit={handleSendMsg}>
                <Box sx={{flexGrow: 1,borderRadius: '20px',px:'20px',background:'#fff', display: 'flex', alignItems: 'center'}}>
                    <TextField
                        fullWidth
                        value={msg}
                        size='small'
                        placeholder='Type your message here…'
                        onChange={e => setMsg(e.target.value)}
                        sx={{'& .MuiOutlinedInput-input': {pl: 0}, '& fieldset': {border: '0 !important'}}}
                    />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center',ml:'15px'}}>
                    <IconButton onClick={(e) => handleSendMsg(e)}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </Form>
        </Box>
    );
};

export default SendMsgForm 