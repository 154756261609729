import { useState } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send';
import {IconButton} from "@mui/material";

const Form = styled('form')(({ theme }) => ({
    padding: theme.spacing(1.1, 5, 5),
    display:'flex',
    alignItems:'center',
}))


const SendMsgForm = (props) => {
    const {store, dispatch, sendMsg} = props

// ** State
    const [msg, setMsg] = useState('')

    const handleSendMsg = e => {
        e.preventDefault()
        if (store && store.selectedChat && msg.trim().length) {
            dispatch(sendMsg({...store.selectedChat, message: msg}))
        }
        setMsg('')
    }

    return (
        <Box sx={{background: '#F6F9FB',borderTop:'1px solid #E2E8F0',pt:'7px'}}>
            <Form onSubmit={handleSendMsg}>
                    <Box sx={{flexGrow: 1,borderRadius: '20px',px:'20px',background:'#fff', display: 'flex', alignItems: 'center'}}>
                        <TextField
                            fullWidth
                            value={msg}
                            size='small'
                            placeholder='Type your message here…'
                            onChange={e => setMsg(e.target.value)}
                            sx={{'& .MuiOutlinedInput-input': {pl: 0}, '& fieldset': {border: '0 !important'}}}
                        />
                    </Box>
                <Box sx={{display: 'flex', alignItems: 'center',ml:'15px'}}>
                    <IconButton onClick={(e)=>handleSendMsg(e)}>
                        <SendIcon/>
                    </IconButton>
                </Box>
            </Form>
        </Box>
    )
}
export default SendMsgForm;