import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import {useFormik} from "formik";
import {
    getAllClinicsServices,
} from "../../../../store/clinics/clinicSettings/clinics-services";
import {
    getClinicsPlanByID,
    storeClinicsPlan,
    updateClinicsPlan
} from "../../../../store/clinics/clinicSettings/clinic-plan";
import Loader from "../../../../components/loader/loader";
import {
    Box,   
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    TextField
} from "@mui/material";
import FormInput, {inputStyle} from "../../../../components/form/formInput";
import FormStatusInput from "../../../../components/form/FormStatusInput";

import Image from "../../../../components/image/image";
import CustomButton from "../../../../components/custom-button/customButton";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {AiOutlinePlusCircle,AiOutlineMinusCircle} from 'react-icons/ai'
import Autocomplete from "@mui/material/Autocomplete";
import {appointmentTime} from "../../../../services/utills";
import {GiCancel} from "react-icons/gi";
import {storeImage} from "../../../../store/image";

const AddPlan = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate =useNavigate()
    const params = useParams();

    const clinicServicesStore = useSelector((state) => state?.clinicServicesStore);
    const user = useSelector(state => state?.authStore?.user)


    const validationSchema = Yup.object({
        TSKU: Yup.string().required("TSKU is Required"),
        title: Yup.string().required("name is Required"),
        description: Yup.string() ,
        // photograph_video_release: Yup.string().typeError("photograph video release is Required").required("photograph_video_release is Required"),
        // is_promo: Yup.string().typeError("Is Promo is Required").required("is_promo is Required"),
        // qty_enable: Yup.string().typeError("Is Quantity is Required").required("Quantity is Required"),
        status: Yup.string().required("slug is Required"),
        member_price: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Only Integer Allowed"
        ).required("member_price is Required"),
        no_of_bookings: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Only Integer Allowed"
        ).required("Session/Number of booking is Required"),
        regular_price: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Only Integer Allowed"
        ).required("regular_price is Required"),
        // session: Yup.string().matches(
        //     /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        //     "Only Integer Allowed"
        // ).required("session is Required"),
        slug: Yup.string().required("slug is Required"),

        image: Yup.mixed().required("Image is Required"),
        plan_services: Yup.array().of(
            Yup.object().shape({
                service: Yup.mixed().typeError("Service is required").required("Service is required"),
                time: Yup.string().typeError('Time is required').required('Time is required'),
                service_regular_price: Yup.string().matches(
                    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                    "Only Integer Allowed"
                ).typeError('Service Regular Price is required').required('Service Regular Price is required'),
                quantity:  Yup
                    .number()
                    .min(0, 'Number must be greater than or equal to 0')
                    .max(12, 'Number must be less than or equal to 12')
                    .required('Please enter a Quantity'),
            })
        )
    });

    const {values, handleSubmit, setFieldValue,setValues, handleChange, touched, errors} = useFormik({
        initialValues: {
            title: '',
            TSKU: '',
            slug: '',
            // session:'',
            regular_price: '',
            member_price: '',
            no_of_bookings: '',
            min_deposit_mount: '',
            image: null,
            description: '',
            status: 'In-Active',
            plan_services: [{ service: [], time:'00:00',service_regular_price:'',quantity:1 }]
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)
            if (typeof data?.image !=='string') {

                const formData = new FormData()

                formData.append("path", "plan/image")
                formData.append("file", data?.image)

                let uploadImage = await dispatch(storeImage(formData))

                if (uploadImage?.payload?.success === "true") {
                    data['image'] = uploadImage?.payload?.url

                }
            }
            let res

            if (params?.plan_id){
                res = await dispatch(updateClinicsPlan({...data,provider_id:params?.clinic_id}))

            }else {
                res = await dispatch(storeClinicsPlan({...data,provider_id:params?.clinic_id}))
            }
                if (res?.payload) {
                    if (res?.payload?.success === "true"){
                       navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/plan`)
                    }
                    if (res?.payload?.errors) {
                        const errors = res?.payload?.errors
                        try {
                            if (Object.keys(errors)?.length > 0) {
                                Object.keys(errors).forEach(function (key, index) {
                                    setFieldTouched(key, true)
                                    setFieldError(key, errors[key])
                                });
                            }
                        } catch (e) {

                        }
                    }
                }



            setLoading(false)

        },
    });

 console.log("id in addw    e4s",params?.clinic_id)

 useEffect(() => {
    (async () => {
      await dispatch(getAllClinicsServices({ provider_id: params?.clinic_id }));
    })();
  }, [dispatch, params?.clinic_id]);
  
    useEffect(()=>{
        (async () => {
            if (params?.plan_id) {
                setLoading(true)
                let res = await dispatch(getClinicsPlanByID(params?.plan_id))


                if (res?.payload?.data?.id) {
                    const data =res?.payload?.data
                    await setFieldValue("title",data?.title)
                    await setFieldValue("TSKU", data?.TSKU)
                    await setFieldValue("slug", data?.slug)
                    await setFieldValue('status', data?.status || 'In-Active')
                    await setFieldValue('description', data?.description || '')
                    await setFieldValue('regular_price', data?.regular_price || '')
                    await setFieldValue('member_price', data?.member_price || '')
                    await setFieldValue('no_of_bookings', data?.no_of_bookings || '')
                    await setFieldValue('min_deposit_mount', data?.min_deposit_mount || '')
                    await setFieldValue('image', data?.image || '')
                    // await setFieldValue('session', data?.session || '')
                    await setFieldValue('id', data?.id)


                    data?.plan_service?.length> 0 && data?.plan_service.forEach((item,index)=>{
                        setFieldValue(`plan_services[${index}].service`, item);
                        setFieldValue(`plan_services[${index}].time`, item?.pivot?.service_time);
                        setFieldValue(`plan_services[${index}].service_regular_price`, item?.pivot?.service_regular_price);
                        setFieldValue(`plan_services[${index}].quantity`, item?.pivot?.service_quantity);
                    })


                }
                setLoading(false)
            }

        })()
    },[params?.plan_id])

    const addServices =()=>{
        setValues({
            ...values,
            plan_services: [...values.plan_services, { service: [], time:'00:00',service_regular_price:'',quantity:1 }]
        });
    }
    const removeServices = (index) => {
        setValues({
            ...values,
            plan_services: values.plan_services.filter((field, i) => i !== index)
        });
    };

    const handleMinutes = (name,selectedValue,addMinutes) => {
        if (selectedValue !== '00:00' || addMinutes) {
            const [hours, minutes] = selectedValue.split(':');
            let newMinutes = parseInt(minutes) + (addMinutes ? 15 : -15);
            let newHours = parseInt(hours);

            if (newMinutes >= 60) {
                newMinutes -= 60;
                newHours += 1;
            } else if (newMinutes < 0) {
                newMinutes += 60;
                newHours -= 1;
            }

            if (newHours >= 12) {
                newHours -= 12;
            } else if (newHours < 0) {
                newHours += 12;
            }

            setFieldValue(name, `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`);

        }else {
            if (addMinutes){
                setFieldValue('time', "00:00");
            }
        }
    };

    //
    // useEffect(()=>{
    //     handleRegularPrice()
    // },[values?.plan_services,values?.plan_services?.length])

    const handleRegularPrice =(count)=>{
        const {plan_services} = values
        if (plan_services?.length){
            let finalRegularPrice = 0;
            let finalMemberPrice = 0;
            for (let i =0;i<plan_services?.length;i++){
                const {service,service_regular_price,quantity} = plan_services[i]
                let finalQuantity = count || quantity
                if (service?.regular_price){
                    finalRegularPrice = finalRegularPrice + (parseFloat(service_regular_price || 0) * finalQuantity)
                }
                if (service?.member_price){
                    const {member_price} = service
                    finalMemberPrice = finalMemberPrice + (parseFloat(member_price)* finalQuantity)
                }
            }
            setFieldValue("member_price",finalMemberPrice)
            setFieldValue("regular_price",finalRegularPrice)
        }

    }


    return (
            <form onSubmit={handleSubmit}>
                {loading ? <Loader fullSize/> :""}
                <Grid container p={'20px'} sx={{justifyContent: 'space-between'}}>
                    <Grid item xs={12} lg={5.5} xl={3.6}>
                        <FormInput
                            name={"title"} 
                            label={"Bundle Name"}
                            placeholder={'Type  your name'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={(e)=>{
                                const {value} = e?.target
                                setFieldValue("title", value)
                                let finalValue = value.replace(/[^a-zA-Z ]/g, '-').replace(/\s+/g, '-').toLowerCase().replace(/-+/g, '-')
                                setFieldValue("slug", finalValue)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5} xl={3.6}>
                        <FormInput
                            name={"slug"}
                            label={"Slug"}
                            placeholder={'Type  your Slug'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={(e)=>{
                                const {value} = e?.target
                                let finalValue = value.replace(/[^a-zA-Z0-9 ]/g, '-').replace(/\s+/g, '-').toLowerCase().replace(/-+/g, '-');
                                setFieldValue("slug", finalValue)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5} xl={3.6}>
                        <FormInput
                            name={"TSKU"}
                            label={"TSKU"}
                            placeholder={'Type  your TSKU'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5} xl={3.6}>
                        <FormInput
                            name={"no_of_bookings"}
                            label={"Session/Number of Booking"}
                            placeholder={'Type  your session/number of booking'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5} xl={3.6}>
                        <FormInput
                            name={"min_deposit_mount"}
                            label={"Minimum Depost Amount"}
                            placeholder={'Type  your Minimum Depost Amount'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5} xl={3.6}>

                    </Grid>
                    {values?.plan_services.map((item,index)=>(
                        <Grid key={index} item xs={12} sx={{display:"flex",alignItems:'center',mb:"16px",  '@media (max-width: 480px)' :{ display:"block"},}}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize:"16px",color:'#27272e',letterSpacing:'.3px'}}>Plan Services</FormLabel>

                                <Autocomplete
                                    options={clinicServicesStore?.services}
                                    value={item?.service}
                                    disableCloseOnSelect
                                    onBlur={()=>handleRegularPrice()}
                                    getOptionLabel={(option) => {
                                        if (Array.isArray(option) && option.length === 0) {
                                            return "";
                                        }
                                         return option?.name
                                    }}
                                    isOptionEqualToValue={(option, value) => {
                                         return option.id === value.id
                                    }}
                                    renderInput={(params) => {
                                        const { inputProps, helperText, error,...rest } = params;
                                        return(<TextField   {...rest}    inputProps={{ ...inputProps }} sx={inputStyle} placeholder={"Select"}/>
                                        )}}

                                    onChange={(event, newValue) => {
                                        if (newValue?.id) {
                                            setFieldValue(`plan_services[${index}].service`, newValue);
                                            setFieldValue(`plan_services[${index}].time`, newValue?.time_duration);
                                            setFieldValue(`plan_services[${index}].service_regular_price`, newValue?.regular_price);
                                        }
                                    }}
                                    renderOption={(props,option,{selected})=>(
                                        <MenuItem  {...props}>
                                            <Checkbox
                                                style={{marginRight: 8}}
                                                checked={selected}
                                            />
                                            {option?.name}
                                        </MenuItem>
                                    )}
                                />
                                {errors.plan_services?.[index]?.service && touched.plan_services?.[index]?.service && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors.plan_services?.[index]?.service }</FormHelperText>}

                            </FormControl>
                           <Box sx={{padding:'4px',mt:'20px'}}>
                               <IconButton disabled={!item?.service?.id} onClick={()=>handleMinutes(`plan_services.${index}.time`,item?.time,false)}>
                                   <AiOutlineMinusCircle fontSize={'20px'} color={"#09ABF1"}/>
                               </IconButton>
                           </Box>
                            <FormControl disabled={!item?.service?.id} fullWidth sx={{maxWidth:'100px', '@media (max-width: 480px)' :{ maxWidth:'100%'},}}>
                                <FormLabel sx={{fontSize:"16px",color:'#27272e',letterSpacing:'.3px'}}>Time</FormLabel>
                                <Autocomplete
                                    disabled={!item?.service?.id}
                                    name={`plan_services[${index}].time`}
                                    options={appointmentTime()}  
                                    disableClearable
                                    getOptionLabel={(option) => option}
                                    isOptionEqualToValue={(option, value) => {
                                        return option === value
                                    }}
                                    value={item?.time}
                                    onChange={(event, value) => {
                                        setFieldValue(`plan_services.${index}.time`, value || "")
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder={'Time'} sx={inputStyle}/>
                                    )}
                                />
                                {errors.plan_services?.[index]?.time && touched.plan_services?.[index]?.time && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors.plan_services?.[index]?.time }</FormHelperText>}

                            </FormControl>

                            <Box sx={{padding:'4px',mt:'20px'}}>
                                <IconButton disabled={!item?.service?.id}  onClick={()=>handleMinutes(`plan_services.${index}.time`,item?.time,true)}>
                                    <AiOutlinePlusCircle fontSize={'20px'} color={"#09ABF1"}/>
                                </IconButton>
                            </Box>
                            <FormControl fullWidth sx={{maxWidth:'90px' ,'@media (max-width: 480px)' :{ maxWidth:'100%'},}}>
                                <FormLabel sx={{fontSize:"16px",color:'#27272e',letterSpacing:'.3px'}}>Price</FormLabel>
                                <TextField
                                    fullWidth
                                    name={`plan_services[${index}].service_regular_price`}
                                    sx={inputStyle}
                                    onBlur={()=>{
                                        handleRegularPrice()
                                    }}
                                    disabled={!item?.service?.id}
                                    placeholder={"price"}
                                    value={item?.service_regular_price}
                                    onChange={(e)=>{
                                        const {value} = e.target
                                        const sanitizedValue = value.replace(/[^0-9]/g, '');
                                         setFieldValue(`plan_services.${index}.service_regular_price`, sanitizedValue)

                                        // handleRegularPrice()
                                    }}
                                />
                                {errors.plan_services?.[index]?.service_regular_price && touched.plan_services?.[index]?.service_regular_price && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors.plan_services?.[index]?.service_regular_price }</FormHelperText>}

                            </FormControl>
                           <Box sx={{padding:'4px',mt:'20px'}}>
                               <IconButton disabled={!item?.service?.id} onClick={()=> {
                                   if (item?.quantity > 1){
                                       setFieldValue(`plan_services.${index}.quantity`,item?.quantity - 1)
                                       handleRegularPrice(item?.quantity - 1)

                                   }
                               }}>
                                   <AiOutlineMinusCircle fontSize={'20px'} color={"#09ABF1"}/>
                               </IconButton>
                           </Box>
                            <FormControl fullWidth sx={{maxWidth:'70px' ,'@media (max-width: 480px)' :{ maxWidth:'100%'},}}>
                                <FormLabel sx={{fontSize:"16px",color:'#27272e',letterSpacing:'.3px'}}>Quantity</FormLabel>
                                <TextField
                                    fullWidth
                                    name={`plan_services[${index}].quantity`}
                                    sx={inputStyle}
                                    disabled={!item?.service?.id}
                                    type={'number'}
                                    placeholder={"quantity"}
                                    value={item?.quantity}
                                    onBlur={()=>handleRegularPrice()}
                                    onChange={(e)=>{
                                        const {value} = e?.target
                                        if (value > 0 && value<13) {
                                            handleChange({
                                                target: {
                                                    name: `plan_services[${index}].quantity`,
                                                    value: value
                                                }
                                            });
                                        }

                                    }}
                                />
                                {errors.plan_services?.[index]?.quantity && touched.plan_services?.[index]?.quantity && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors.plan_services?.[index]?.quantity }</FormHelperText>}

                            </FormControl>
                            <Box sx={{padding:'4px',mt:'20px'}}>
                                <IconButton disabled={!item?.service?.id} onClick={()=> {

                                    if (item?.quantity < 12){
                                        setFieldValue(`plan_services.${index}.quantity`,parseInt(item?.quantity) + 1)
                                        handleRegularPrice(parseInt(item?.quantity) + 1)

                                    }
                                }}>
                                    <AiOutlinePlusCircle fontSize={'20px'} color={"#09ABF1"}/>
                                </IconButton>
                            </Box>
                            <Box sx={{padding:'4px',mt:'20px'}}>
                                <IconButton
                                    size="small"
                                    sx={{}}
                                    onClick={() =>removeServices(index)}
                                >
                                    <GiCancel
                                        color={"#CB3838"}
                                        fontSize={"26px"}
                                    />
                                </IconButton>
                            </Box>
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                        <CustomButton handleClick={addServices} val={"Add Events"}/>
                    </Grid>
                    <Grid item xs={12} lg={5.5} xl={3.6}>
                        <FormInput
                            name={"regular_price"}
                            label={"Regular Price"}
                            placeholder={'Type  your regular price'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5} xl={3.6}>
                        <FormInput
                            name={"member_price"}
                            label={"Member Price"}
                            placeholder={'Type  your linkedIn'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>

                    {/*<Grid item xs={12} lg={5.5} xl={3.6}>*/}
                    {/*    <FormInput*/}
                    {/*        name={"session"}*/}
                    {/*        label={"Session"}*/}
                    {/*        placeholder={'Type Session Number'}*/}
                    {/*        errors={errors}*/}
                    {/*        touched={touched}*/}
                    {/*        values={values}*/}
                    {/*        handleChange={handleChange}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{mb: "16px"}}>
                            <FormLabel sx={{fontSize: "16px", color: '#27272e', letterSpacing: '.3px'}}>
                                Service Description
                            </FormLabel>
                            <TextField
                                minRows={6}
                                maxRows={6}
                                sx={inputStyle}
                                name={"description"}
                                multiline={true}
                                value={values?.description}
                                placeholder={'Type Plan Description'}
                                onChange={handleChange}
                            />
                            {errors?.description && touched?.description && <FormHelperText sx={{
                                color: '#CB3838',
                                width: 'max-content',
                                mx: 0
                            }}>{errors?.description}</FormHelperText>}

                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <Image
                            label={'Image'}
                            name={"image"}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                        />
                    </Grid>
                    <Grid item xs={4.5}>
                        <FormStatusInput
                            name={"status"}
                            label={"Status"}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end',alignItems:'center'}}>
                        <Box sx={{mr: '16px'}}>
                            <CustomButton
                                mainColor={'#ED1E45'}
                                val={'Cancel'}
                                handleClick={() => {
                                    navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/plan`)

                                }}
                            />
                        </Box>
                        <SubmitButton val={params?.plan_id ? 'Update Plan' : 'Add Plan'}/>
                    </Grid>
                </Grid>
            </form>
    );
};

export default AddPlan;