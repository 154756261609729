import { Box, Typography, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const MonthlyInsights = ({insights, fetchData}) => {
  const now = new Date(); 


  const [currentDate, setCurrentDate] = useState(new Date());

  // Calculate the first and last day of the current selected month
  const firstDayOfMonth = useMemo(() => new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), [currentDate]);
  const lastDayOfMonth = useMemo(() => new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), [currentDate]);
  
  // Function to format a date to YYYY-MM-DD
  function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2)  
        day = '0' + day;
     
    return [year, month, day].join('-');
  }
   
  console.log("First day of the month: " + formatDate(firstDayOfMonth)); 
  console.log("Last day of the month: " + formatDate(lastDayOfMonth));
 
  const handlePrevMonthClick = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    setCurrentDate(newDate);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1; 
    fetchData({year, month}); 
  };

  
  const handleNextMonthClick = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    setCurrentDate(newDate);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1; 
  fetchData({year, month});   
  };    
          
  const formatDateDisplay = () => {
    return currentDate.toLocaleString('default', { month: 'long' }) + ' ' + currentDate.getFullYear();
  };   
 

  // Function to format a date to YYYY-MM-DD
  function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2)  
        day = '0' + day;
     
    return [year, month, day].join('-');
  }

  const navigate = useNavigate();

  // Handlers that use the current date boundaries
  const handleClickUnpaid = () => {
    navigate("/crm/sale-report", {
      state: {
        value: "Pending",
        startDate: formatDate(firstDayOfMonth),
        endDate: formatDate(lastDayOfMonth),
      },
    });
  }
  // const navigate = useNavigate();
  const handleClick = () => {
    navigate("/crm/sale-report", {   
      state: {      
      value: "Paid",
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
      },  
    });
  }
  const startDate = firstDayOfMonth < now ? now : firstDayOfMonth;
  // const handleClickUnpaid = () => {
  //   navigate("/crm/sale-report", {
  //     state: {
  //     value: "Pending",
  //     startDate: startDate,
  //     endDate: lastDayOfMonth,
  //     },
  //   });
  // } 

  const handleClicknoshow = () => {
    navigate("/crm/sale-report", {
      state: {
      value: "No Show",
        startDate:firstDayOfMonth, 
      endDate:lastDayOfMonth,          
      },
    });
  }
  const handleClickPending = () => {
    navigate("/crm/sale-report", {
      state: {
      value: "Pending",
        startDate:startDate,
      endDate:lastDayOfMonth,
      },
    });
  }
  const handleClickNewServed = () => {
    navigate("/crm/sale-report", {
      state: {
      value: "New Booking",
      status: "CheckOut",
        startDate:startDate,
      endDate:lastDayOfMonth,
      },
    });
  }


  const handleClickcancle = () => { 
    navigate("/crm/sale-report", {   
      state: {
      value: "Cancel",
        startDate: firstDayOfMonth,
      endDate: lastDayOfMonth, 
      },
    });    
  }      

  const handleClickconfirmed = () => {  
    navigate("/crm/sale-report", {      
      state: { 
      value: "Confirmed",
        startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
      },
    });
  }

  const  handleClickbooked = () => {
    navigate("/crm/sale-report", {
      state: {    
      // value: "Confirmed",
      startDate: firstDayOfMonth,  
      endDate:lastDayOfMonth,
      }, 

    });
  }    

  const handleClickmember = () => {
    navigate("/crm/member-report", {
      state: {
      value: "AllMember",
        startDate: firstDayOfMonth,  
      endDate:lastDayOfMonth, 
      },  
    });   
  }    
     
  const handleClickmembercancle = () => { 
    console.log("issue",firstDayOfMonth,lastDayOfMonth)
    navigate("/crm/member-report", {
      state: {    
      value: "CancelledMembers",
      startDate:firstDayOfMonth,      
      endDate:lastDayOfMonth,
      },   
    
    });
  }
  const handleClickCompleted = () => {  
    navigate("/crm/sale-report", {  
      state: { 
      value: "CheckOut",    
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
      },
    });
  }

  return (
    <Box     
      sx={{
        padding: "40px 20px",
        position: "relative",   
        overflow: "hidden",
        background: "#fff", borderRadius: "8px", mt: 2,  
      }}
    >
      <Box style={{display:"flex",justifyContent:"space-between"}}>
      <Typography variant="h5" sx={{ fontFamily: "helvetica-lt-std-bold" }}>    
        Monthly Insight    
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 2 }}>
        <ArrowBackIosNewIcon onClick={handlePrevMonthClick} sx={{ cursor: 'pointer' }} />
        <Typography variant="h6" sx={{ mx: 2 }}>{formatDateDisplay()}</Typography>
        <ArrowForwardIosIcon onClick={handleNextMonthClick} sx={{ cursor: 'pointer' }} />
      </Box> 
      </Box>
     
      <Grid container>   
        <Grid item lg={3} md={4} sm={6} xs={6}> 
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography   onClick={handleClick} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }}>  ${insights?.earned_this_month}</Typography>
            <Typography variant="body1" sx={{ color: "rgb(107, 114, 128)" }}>
              Earned This Month
            </Typography>
          </Box>         
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography  onClick={handleClickPending} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }} color="initial">
              ${insights?.pending_this_month}
            </Typography>
            <Typography
                variant="body1"
                color="initial"
                sx={{ color: "rgb(107, 114, 128)" }}
            >
              Pending Sales
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography  onClick={handleClickmember} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }} color="initial">
              ${insights?.new_memberships_total}
            </Typography>
            <Typography
                variant="body1"
                color="initial"
                sx={{ color: "rgb(107, 114, 128)" }}
            >
              New Membership Total
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography  onClick={handleClickmember} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }} color="initial">
              ${insights?.renewals_total}
            </Typography>
            <Typography
                variant="body1"
                color="initial"
                sx={{ color: "rgb(107, 114, 128)" }}
            >
              Membership Renewals Total
            </Typography>
          </Box>
        </Grid>
      </Grid>        
     
      <Grid container>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography onClick={handleClickbooked}   sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }} color="initial">
          {insights?.total_booked_appointment}  
            </Typography>
            <Typography
              variant="body1"     
              color="initial"
              sx={{ color: "rgb(107, 114, 128)" }}    
            >
              Total Booked Appointments
            </Typography>  
          </Box>                
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography onClick={handleClickPending}   sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }} color="initial">
              {insights?.pending_appointment}
            </Typography>
            <Typography
                variant="body1"
                color="initial"
                sx={{ color: "rgb(107, 114, 128)" }}
            >
              Total Pending Appointments
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography  onClick={handleClickCompleted} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }} color="initial">
                   {insights?.completed_appointment}
            </Typography>
            <Typography 
              variant="body1"  
              color="initial"
              sx={{ color: "rgb(107, 114, 128)" }}    
            >
              Completed Appointments
            </Typography> 
          </Box>
        </Grid>  
        <Grid item lg={3} md={4} sm={6} xs={6}>  
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography onClick={handleClickcancle} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }} color="initial">
              {insights?.cancelled_appointment}
            </Typography>
            <Typography
              variant="body1"   
              color="initial"
              sx={{ color: "rgb(107, 114, 128)" }}
            >  
              Cancellations
            </Typography> 
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography onClick={handleClicknoshow} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }}>  {insights?.no_shows}</Typography>
            <Typography variant="body1" sx={{ color: "rgb(107, 114, 128)" }}>
              No Shows
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography  onClick={handleClickmember}sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }}>  {insights?.new_members}</Typography>
            <Typography variant="body1" sx={{ color: "rgb(107, 114, 128)" }}>
              New Members
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography  onClick={handleClickmembercancle} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }}>{insights?.membership_cancellation}</Typography>
            <Typography variant="body1" sx={{ color: "rgb(107, 114, 128)" }}>
              Membership Cancellations
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography sx={{ fontSize:{sm:'2.125rem', xs:'20px'} }} color="initial">
              {insights?.booking_rate} %
            </Typography>
            <Typography
                variant="body1"
                color="initial"
                sx={{ color: "rgb(107, 114, 128)", }}
            >
              Booking Completion Rate
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Typography onClick={handleClickNewServed} sx={{ fontSize:{sm:'2.125rem', xs:'20px',cursor:"pointer"} }}>  {insights?.clients_served}</Typography>
            <Typography variant="body1" sx={{ color: "rgb(107, 114, 128)" }}>
              New Clients Served
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ position: "absolute", bottom: "-26px", right: "-24px" }}>
        <svg
          id="check-circle"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"  
          style={{ color: "#D2EBD3", width: "96px" }}
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </Box>
    </Box>
  );
};    

export default MonthlyInsights;
