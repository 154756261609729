import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import arrowLeft from "../../assets/icon/arrow-back.svg";
import settingIcon from "../../assets/images/settingIcon.svg";
import userLogo from "../../assets/icon/profile.png";
import { Button, Box, IconButton, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router";
import { removeUser } from "../../store/auth";
import { MdPowerSettingsNew } from "react-icons/md";
import { confirmation } from "../../services/utills";
import MenuIcon from "@mui/icons-material/Menu";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {getClinicByID} from "../../store/clinics";
import setting from "../../assets/icon/settings.svg"    
import AdminDrawer from "../siderbar/adminDrawer";
const Header = ({
                    isCRM,
                    admin,   
                    sidebarOpen,  
                    mobileOpen,    
                    setSidebarOpen,
                    setMobileOpen,
                }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [clinicLoading, setClinicLoading] = useState(false)  

    const user = useSelector((state) => state?.authStore?.user);
   console.log("user",user)
    const handleLogout = async () => {
        if (await confirmation("Are you want to logout", "Yes, Logout!")) {
            // Remove token from localStorage
            localStorage.removeItem("token");
    
            // Dispatch action to remove user and reset any related state
            dispatch(removeUser());
    
            // Additional state reset actions can go here if you have any
    
            // Remove all cookies
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            }
    
            // Navigate to login page
            navigate("/login");
    
            // Optionally, force a page reload
            window.location.reload();
        }
    };
    
   const navigateAndUpdate= (userId) => {
        setClinicLoading(true)
        dispatch(getClinicByID(userId))
        setClinicLoading(false)
        // Assuming you're using a routing library that provides a navigate function    
        navigate(`/crm/configuration/clinic/${userId}/rooms`);
    }
    const handleExploreServicesClick = () => {
        // Replace 'https://example.com' with the URL of the other app or website you want to redirect to
        window.location.href = user?.explore_services_url + "/#services";
    };


    return (
        <>
        <Box
            sx={{
                height: "69px",
                borderBottom: "1px solid #dee2e6",
                display: "flex",
                justifyContent:
                    !setSidebarOpen || admin
                        ? isCRM
                            ? { xs: "space-between", lg: "flex-end" }
                            : { xs: "space-between", md: "flex-end" }
                        : "space-between",
                alignItems: "center",
            }}
        >  
                            {!user?.id || user?.role_id === 1 ?  (
                        <Box>
                            <Button
                                variant="text"
                                sx={{
                                    ml:{sm:3, xs:'5px'},
                                    mr:'5px',
                                    backgroundColor: "#f4ede3",
                                    display: {lg:'none',md:'none', xs:'block'},
                                    color: "#000",
                                    borderRadius: "7px",
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        border: "1px solid #09ABF1",
                                        color: "#09ABF1",
                                    },
                                }}
                                
                            >
                                <AdminDrawer/>
                            </Button>
                        </Box>
                    ) : (
                        ""
                    )}
                            {!user?.id || user?.role_id === 2 ?  (
                        <Box>
                            <Button
                                variant="text"
                      
                                
                            >
                                    <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setMobileOpen(!mobileOpen)}
                sx={{
                    ml:{sm:3, xs:'5px'},                           
                    mr:'5px',
                    backgroundColor: "#f4ede3", 
                    display: isCRM ? { lg: "none" } : { md: "none" },
                    color: "#000",
                    borderRadius: "7px",
                    "&:hover": {
                        backgroundColor: "#fff",
                        border: "1px solid #09ABF1",
                        color: "#09ABF1",
                    },
                }}
            >
                <MenuIcon />
            </IconButton>
                            </Button>
                        </Box>
                    ) : (
                        ""
                    )}
                                    {!user?.id || user?.role_id === 3 ?  (
                        <Box>
                            <Button
                                variant="text"
                      
                                
                            >
                                    <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setMobileOpen(!mobileOpen)}
                sx={{
                    ml:{sm:3, xs:'5px'},
                    mr:'5px',
                    backgroundColor: "#f4ede3",
                    display: isCRM ? { lg: "none" } : { md: "none" },
                    color: "#000",
                    borderRadius: "7px",
                    "&:hover": {
                        backgroundColor: "#fff",
                        border: "1px solid #09ABF1",   
                        color: "#09ABF1",
                    },
                }}

            >
                <MenuIcon />
            </IconButton>
                            </Button>  
                        </Box>
                    ) : (
                        ""
                    )}
       
            <Box
                sx={{
                    position: admin ? "absolute" : "relative",
                    left: admin
                        ? sidebarOpen
                            ? isCRM
                                ? "234px"
                                : "282px"
                            : "50px"
                        : "-17px",
                    zIndex: "111",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #c4c4c4",
                    borderRadius: "50%",
                    padding: "4px",
                    transition: ".5s",
                    cursor: "pointer",
                    display: setSidebarOpen
                        ? isCRM
                            ? { xs: "none", lg: "flex" }
                            : { xs: "none", md: "flex" }
                        : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: sidebarOpen ? "" : " rotate(540deg)",
                }}
                onClick={() => setSidebarOpen(!sidebarOpen)}
            >
                <img src={arrowLeft} alt={"arrow"} />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {user?.role_id === 2 && (
                    <Box
                        sx={{
                            borderRight: "1px solid #dee2e6",
                            borderLeft: "1px solid #dee2e6",
                            width: "60px",
                            height: "69px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => navigateAndUpdate(user?.id)}
                    >
                        <img src={settingIcon} alt={"user"} width={"40px"} height={"40px"} />
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",   
                        justifyContent: "center",  
                        padding: {sm:'0 35px', xs:'0 5px'},
                    }}
                >
                    <Avatar alt={user?.name} src={user?.profile_image || userLogo} />
                    <Typography
                        as={"p"}
                        sx={{
                            ml: 1.5,
                            textTransform: "capitalize",
                            overflowWrap:'anywhere',
                            "@media (max-width:319px)": {
                                fontSize:'12px'
                              },
                            
                        }}
                    >
                        {user?.name}
                    </Typography>
                    {!user?.id || user?.role_id == 3 ? (
                        <Box sx={{ display: { sm: "block", xs: "none" } }}>
                            <Button
                                variant="text"
                                sx={{
                                    background: "#E6DAD1",
                                    color: "#000",
                                    ml: 2,
                                    "&:hover": {
                                        background: "#E6DAD1 ",
                                    },
                                }}
                                onClick={handleExploreServicesClick}
                            >
                                Explore More Services
                            </Button>
                        </Box>
                    ) : (
                        ""
                    )}
                </Box>

                <Button
                    variant={"outlined"}
                    sx={{
                        height: "50px",
                        width: "50px",
                        borderRadius: '8px',
                        borderColor: "#f4ede3",
                        backgroundColor: "#f4ede3",
                        color: "#000",
                        marginRight:'10px',
                        "&:hover": {
                            color: "#E6DAD1",
                            borderColor: "#E6DAD1",
                        },
                    }}
                    onClick={handleLogout}
                >
                    <MdPowerSettingsNew style={{ fontSize: "1.5rem" }} />
                </Button>
            </Box>

        </Box>
            {user?.role_id === 3 && user?.email_verified_at === null && (
                <Box
                    sx={{
                        background: "rgb(253, 237, 237)",
                        p: '7px',
                        my: 2,
                        borderRadius: '6px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',

               //////////////////////////    fontFamily: "helvetica-lt-std-roman",
                            }}
                        >
                            <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px' }} />
                            Your email is not verified — please check your email!
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                sx={{
                                    background: '#E6DAD1',
                                    color: '#000',
                                    fontSize: { sm: '15px', xs: '10px' },
                                    mt: { sm: 0, xs: 2 },
                                    ml: { sm: 3, xs: 1 },
                                }}
                            >
                                Resend Verification Email
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}

        </>
    );
};

export default Header; 
