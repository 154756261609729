import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useStyles from "./style";
import moment from "moment/moment";


function createData(name, date, noofbooking) {
  return { name, date, noofbooking };
}

const rows = [
  createData("Model Call Fillers", "Monday, 15th July 2024", 10),
  createData("Model Call Fillers", "Monday, 15th July 2024", 10),
  createData("Model Call Fillers", "Monday, 15th July 2024", 10),
  createData("Model Call Fillers", "Monday, 15th July 2024", 10),
  createData("Model Call Fillers", "Monday, 15th July 2024", 10),
];

const upcomingEvents = ({Events}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="h5"
        sx={{ p: 4, fontFamily: "helvetica-lt-std-bold" }}
      >
        Upcoming Events Bookings
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: "650px" ,p:2}}>
        <Table
          sx={{  }}
          aria-label="simple table"
          className={classes.tableHead}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "700",
                  borderBottom: 0,
                }}
              >
                Name{" "}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "700",
                  borderBottom: 0,
                }}
              >
                Date
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "700",
                  borderBottom: 0,
                }}
              >
                No of Bookings
              </TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            {Events.map((row) => (
              <TableRow
                key={row.name}
                className={classes.odd}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left">{moment(row.service_schedule_date).format("dddd MMMM D, YYYY")}</TableCell>
                <TableCell align="left">{row.appointments_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default upcomingEvents;
