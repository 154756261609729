import React, {useEffect, useState} from 'react';
import {Box, FormControl, Grid} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllClinics, getClinicByID} from "../../../store/clinics";
import ConfigurationLayout from "../configurationLayout";
import {useLocation, useParams} from "react-router-dom";
import ClinicSittings from "../clinic-settings";
import Loader from "../../../components/loader/loader";
import SimpleInput from "../../../components/form/simpleInput";
import CustomBreadcrumbs from "../../../components/breadcrumbs/breadcrumbs";

const Clinics = () => {
    const [loading, setLoading] = useState(false)
    const [clinicLoading, setClinicLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [lastItem, setLastItem] = useState('')

    const clinicStore = useSelector(state => state.clinicStore)
    const user = useSelector((state) => state?.authStore?.user);


    const {pathname} = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const handleClick = () => {
        navigate('/admin/configuration/add-clinic')
    }

    useEffect(() => {
        let lastValue = pathname.split('/');
    
        let valueToSet = '';
    
        if (pathname.includes('edit-room')) {
            valueToSet = 'Edit-Room';
        } 
        if (pathname.includes('edit-promo')) {
            valueToSet = 'Edit-promo';
        } 
        // 
       else if (pathname.includes('edit-treatment-group')) {
            valueToSet = 'Edit-TreatmentGroup ';
        }
        else if (pathname.includes('edit-treatment-form')) {
            valueToSet = 'Edit-TreatmentForm';
        }
        else if (pathname.includes('edit-staff')) {
            valueToSet = 'Edit-Staff';
        } 
        else if (pathname.includes('edit-service')) {
            valueToSet = 'Edit-Service'
        }  
        else if (pathname.includes('edit-events')) {
            valueToSet = 'Edit-Events'
        }  
        else if (pathname.includes('edit-plan')) {
            valueToSet = 'Edit-Plan';    
        }  
        else if (pathname.includes('add-room')) {
            valueToSet = 'Add-Room';    
        }   
        else if (pathname.includes('add-treatment-form')) {
            valueToSet = 'Add-Treatment-Form';    
        }   
        else if (pathname.includes('add-treatment-group')) {
            valueToSet = 'Add-Treatment-Group';    
        } 
        else if (pathname.includes('add-service')) {
            valueToSet = 'Add-Service';    
        }  
        else if (pathname.includes('add-events')) {
            valueToSet = 'Add-Events';    
        } 
        else if (pathname.includes('add-staff')) {
            valueToSet = 'Add-Staff';    
        } 
        else if (pathname.includes('add-plan')) {
            valueToSet = 'Add-Bundle';    
        } 
        else if (pathname.includes('add-promo')) {
            valueToSet = 'Add-Promo';    
        } 
        else{
            // valueToSet =.replace('-', ' ');
        } 
        setLastItem(valueToSet);
    }, [pathname]);
        
    // useEffect(()=>{
    //     let lastValue = pathname.split('/')
    //     lastValue=lastValue[pathname.includes('edit-room')?lastValue?.length-2:lastValue.length-1]
    //     setLastItem(lastValue.replace('-',' '))
        
    // },[pathname]) 
     
    useEffect(() => {
        (async () => {  
            if (user?.role_id === 1) {  
                if (!clinicStore?.clinics?.length) {
                    setLoading(true) 
                }    
                await dispatch(getAllClinics())
                setLoading(false)
            }
        })()
    }, [dispatch,clinicStore?.clinics?.length])
 
    const roleHead = [  
        {value: "Name", key: 'name'},
        {value: "Email", key: 'email'},
        {value: "Created at ", key: 'created_at'},
        {value: "Action", key: 'action'}
    ]

    const handleEdit = async (row, isEdit) => {
        if (isEdit) {
           navigate(`/admin/configuration/edit-clinic/${row?.id}`)
        } else {
            setClinicLoading(true)
            await dispatch(getClinicByID(row?.id))
            setClinicLoading(false)
            navigate(`/admin/configuration/clinic/${row?.id}/business-details`)
        }
    }

    return (
        <ConfigurationLayout clinic={clinicStore?.clinicByID}>
            {params?.clinic_id &&
                <Box
                    sx={{
                        pb:"16px",
                        background:'#EDF2F7',
                    }}
                >
                    <CustomBreadcrumbs
                        back={user?.role_id === 1?  '/admin/configuration/clinics' :'/crm/dashboard'}
                        backLabel={clinicStore?.clinicByID?.name||'Clinic'}
                        name={lastItem || null }
                    />
                </Box>
            } 
            {clinicLoading ? <Loader fullSize/> : ""}
            {!params?.clinic_id && (
                <Grid container p={'20px'}>
                    <Grid item xs={12} sx={{
                        mb: '16px',
                          display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        flexWrap:'wrap',
                        '@media (max-width: 410px)' :{
                         display:'flex',
                         justifyContent:'flex-start'
                        },
                    }}>
                        <FormControl sx={{mr: "10px"}}>
                            <SimpleInput
                                search={search}
                                setSearch={setSearch}
                            />
                        </FormControl>
                        <CustomButton val={'Add Clinic'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
                    </Grid>
                    <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                        {loading ? <Loader/> :<CustomTable
                            headRow={roleHead}
                            tableData={clinicStore?.clinics}
                            search={search}
                            isClinic={true}
                            type={"clinic"}
                            handleEdit={handleEdit}
                        />}
                    </Grid>
                </Grid>
            )}
            {params?.clinic_id && user?.id && (<ClinicSittings clinicId={params?.clinic_id}/>)}
        </ConfigurationLayout>
    );
};

export default Clinics;