import { useState } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send';
import {IconButton} from "@mui/material";
import { getAllMessages, getUserDetails, SendMsgss } from '../../../store/user/Chat-modue';
// import { SendMsgs, SendMsgss } from '../../../store/crm/chat-module';


const Form = styled('form')(({ theme }) => ({
    padding: theme.spacing(1.1, 5, 5),
    display:'flex', 
    alignItems:'center',
}))


const SendMsgForm = (props) => {
    const {store, dispatch, SendMsg} = props
  

// ** State 
    const [msg, setMsg] = useState('')
    const [messages, setMessages] = useState([]);

    const handleSendMsg = async (e) => {
        e.preventDefault();
        if (store && msg.trim().length) {
            const provider_id = store?.userProfile?.provider_id;
            const newMessage = {
                provider_id: provider_id,
                message: msg,
                timestamp: new Date().toISOString() 
            };

          
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            try {
               
                await dispatch(SendMsgss(newMessage)).unwrap();

                // Introduce a delay of 2 seconds
                setTimeout(() => {
                    // Dispatch getAllMessages after the delay
                    dispatch(getAllMessages(provider_id));
                }, 2000);

             ;
            } catch (error) {
                console.error('Failed to send message:', error);
            }
        }
        setMsg('');
    };

  
    return (  
        <Box sx={{background: '#F6F9FB',borderTop:'1px solid #E2E8F0',pt:'7px'}}>
            <Form onSubmit={handleSendMsg}>
                    <Box sx={{flexGrow: 1,borderRadius: '20px',px:'20px',background:'#fff', display: 'flex', alignItems: 'center'}}>
                        <TextField
                            fullWidth
                            value={msg}
                            size='small'          
                            placeholder='Type your message here…'
                            onChange={e => setMsg(e.target.value)}
                            sx={{'& .MuiOutlinedInput-input': {pl: 0}, '& fieldset': {border: '0 !important'}}}
                        />
                    </Box>
                <Box sx={{display: 'flex', alignItems: 'center',ml:'15px'}}>
                    <IconButton onClick={(e)=>handleSendMsg(e)}>
                        <SendIcon/>
                    </IconButton>
                </Box>
            </Form>
        </Box>
    )
}
export default SendMsgForm;  