import {
    Button,
    Card,
    Grid,
    Typography,
    Container, Tooltip,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import wallet from "../../../../src/assets/images/wallet.png";
import cart from "../../../../src/assets/images/cart.png";
import TelegramIcon from "@mui/icons-material/Telegram";
import FormControl from "@mui/material/FormControl";
import copyIcon from "../../../assets/icon/copy-icon.png";
import instagram from "../../../assets/icon/Instagram.png";
import facebook from "../../../assets/icon/Facebook.png";
import whatsapp from "../../../assets/icon/WhatsApp.png";
import snapchat from "../../../assets/icon/Snapchat.png";
import tiktok from "../../../assets/icon/TikTok.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import UserLayout from "../index";
import refer from "../../../assets/icon/refer.png";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetails} from "../../../store/auth";
import toast from "react-hot-toast";
import Footer from "../../home/Footer";

const ReferShare = () => {
    const [copy, setCopy] = useState(false)
    const dispatch = useDispatch()
    const overlineContainer = {
        position: "relative",
        textAlign: "center",
    };

    const authStore = useSelector(state => state?.authStore)
    const {user} = authStore

    useEffect(() => {
        (async () => {
            if (!user?.id) {
                await dispatch(getUserDetails())
            }
        })()
    }, [user])

    return (
        <UserLayout>
            <Box sx={{ pb:{sm:0, xs:'120px'} }}>
                <Container maxWidth="lg" sx={{ml: "0px", mt: 3}}>
                    <Typography variant="h5" color="initial" sx={{fontFamily: 'helvetica-lt-std-bold',}}>
                    Refer and Share
                    </Typography>
                    <Grid container spacing={2} sx={{mt: 3}}>
                        <Grid item lg={8} xs={12}>
                            <Box
                                sx={{
                                    border: "1px solid #C4C4C4",
                                    borderRadius: "6px",
                                    p: 2,
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Grid container >
                                  <Grid item lg={9} xs={12} xl={10}>
                                  <Typography
                                    variant="h6"
                                    color="initial"
                                    sx={{
                                        color: "#2F80ED",
                                        fontSize: {md: "22px", xs: '16px'},
                                        wordBreak:'break-all',
                                        fontFamily: 'helvetica-lt-std-roman',
                                    }}
                                >
                                    {window?.location?.origin + `/register/${user?.referral_code}`}
                                </Typography>
                               
                                  </Grid>
                                  <Grid item lg={3} xs={12} xl={2}>
                                  <Tooltip title={copy ? 'Copied':"Copy"} arrow>
                                    <Typography
                                        variant="body1"
                                        color="initial"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            textDecoration: "underline",
                                            color: "#2F80ED",
                                            cursor: "pointer",
                                            justifyContent:{lg:'end',sm:'start', xs:'start'},
                                            fontSize: {md: "22px", xs: '16px'}, fontFamily: 'helvetica-lt-std-roman',
                                        }}
                                        onClick={() => {
                                            setCopy(true)
                                            navigator.clipboard.writeText(window?.location?.origin + `/register/${user?.referral_code}`)
                                            toast.success("Copied", {duration: 3000})
                                        }}
                                    >
                                        <img src={copyIcon} alt="" style={{
                                            marginRight: "7px",
                                            "@media (max-width:360px)": {marginTop: '5px'},
                                        }}/>
                                        Copy link
                                    </Typography>
                                </Tooltip>
                                  </Grid>
                                </Grid>

                              
                            </Box>
                            <Typography 
                                    variant="h5"
                                     color="initial" 
                                    sx={{
                                        marginTop:"20px",
                                        fontFamily: 'helvetica-lt-std-bold',
                                        fontSize: {md: "22px", xs: '16px'},
                                       fontWeight:'600'
                                    }}>Copy the link and share with your family and friend</Typography>
                        </Grid>
                       {/* <Grid
                            item       
                            lg={4}
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                "& img": {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            <img src={instagram} alt=""/>
                            <img src={facebook} alt=""/>
                            <img src={whatsapp} alt=""/>
                            <img src={snapchat} alt=""/>
                            <img src={tiktok} alt=""/>
                        </Grid>*/}
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        sx={{mt: 3}}
                    >
                        <Grid item xs={12} lg={4}> 
                            <Card
                                sx={{
                                    boxShadow: "1px 1px 9px #D8F3FF;",
                                    height: "246px",
                                    p: "25px",
                                    textAlign: "center",
                                    borderRadius: "14px",
                                    "& img": {fontSize: "36px", my: "10px", colors: "#04B4FF"},
                                }}
                            >
                                <img src={wallet} width="36px" alt=""/>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        " @media (max-width:600px)": {
                                            fontSize: {md: "21px", xs: '16px'},
                                            fontFamily: 'helvetica-lt-std-roman',
                                        },
                                    }}
                                >
                                    Refer your friends and family members to earn credits that get
                                    directly added to your account.
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={4} sx={{zIndex: "999"}}>
                            <Card
                                sx={{
                                    boxShadow: "1px 1px 9px #D8F3FF;",
                                    height: "246px",
                                    p: "25px",
                                    textAlign: "center",
                                    borderRadius: "14px",
                                    "& img": {fontSize: "36px", my: "10px", colors: "#04B4FF"},
                                }}
                            >
                                <img src={cart} width="36px" alt=""/>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: {md: "21px", xs: '16px'}, fontFamily: 'helvetica-lt-std-roman',
                                    }}
                                >
                                    Redeem your credit when you book any appointment or purchase
                                    any product.
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box
                                sx={{
                                    position: "relative",
                                    " @media (max-width:1199px)": {display: "none"},
                                }}
                            >
                                <img
                                    src={refer}
                                    style={{position: "absolute", left: "-100px", top: "-55px"}}
                                    alt=""
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                "& SVG": {color: "#464646"},
                                my: "15px",
                                marginTop: "100px",
                                " @media (max-width:600px)": {marginTop: "20px"},
                            }}
                        >
                            {/* <Typography
                                variant="h6"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    my: "10px",

                                    fontSize: {md: "24px", xs: '17px'}, fontFamily: 'helvetica-lt-std-roman',
                                }}
                            >
                                <TelegramIcon sx={{mr: "8px"}}/> Send Invites to your friends
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    ml: "30px",
                                    fontSize: {md: "22px", xs: '16px'}, fontFamily: 'helvetica-lt-std-roman',
                                }}
                            >
                                Add your referral phone number or email address by clicking the
                                above blue button. We will send them SMS and/or email to sign up
                                with us.
                            </Typography>
                            <Grid item xs={12} sx={{my: "20px"}}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={10}
                                        sx={{}}
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <FormControl
                                            sx={{
                                                border: "1px solid",
                                                borderColor: "whitesmoke",
                                                width: "100%",
                                            }}
                                        >
                                            <OutlinedInput placeholder="Please enter text"/>
                                            <FormHelperText/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                bgcolor: "#f4ede3",
                                                width: "100%",
                                                height: "60px",
                                                fontSize: "20px",
                                                p: "5px",
                                                color:'#000',
                                                textTransform: "capitalize",
                                                " @media (max-width:600px)": {fontSize: "15px"},
                                            }}
                                        >
                                            Send
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Box sx={overlineContainer}>
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                right: 0,
                                borderTop: "1px dashed #8D8D8D",
                            }}
                        ></div>
                        {/* <Typography
                            variant="span"
                            color="initial"
                            sx={{
                                position: "relative",
                                background: "#fff",
                                padding: "0 10px",
                                fontSize: {md: "20px", xs: '16px'}, fontFamily: 'helvetica-lt-std-light',
                            }}
                        >
                            OR
                        </Typography> */}
                    </Box>
                    
                </Container>
            </Box>
            <Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            <Footer />
</Box>
        </UserLayout>
    );
}

export default ReferShare;
