import { configureStore } from '@reduxjs/toolkit'
import authStore  from './auth'
import publicStore  from './public'
import rolesStore  from './roles'
import clinicStore  from './clinics'
import languageStore  from './languages'
import menuStore  from './menus'
import notificationEventStore  from './notification-event'
import notificationTemplateStore  from './notification-templates'
import paymentMethodStore  from './payments-methods'
import permissionStore  from './permissions'
import clinicServiceTypesStore  from './clinics/clinicSettings/service-types'
import clinicRoomsStore  from './clinics/clinicSettings/clinic-rooms'
import clinicTreatmentStore  from './clinics/clinicSettings/clinic-treatment'
import clinicTreatmentFormStore  from './clinics/clinicSettings/clinic-treatment-form'
import clinicTreatmentGroupStore  from './clinics/clinicSettings/clinic-treatment-group'
import clinicServicesStore  from './clinics/clinicSettings/clinics-services'
import clinicExtraServicesStore from './clinics/clinicSettings/clinic-extraServices'
import clinicPromoStore  from './clinics/clinicSettings/clinic-promo'
import clinicEventsStore  from './clinics/clinicSettings/clinics-events'
import clinicsStaffSliceStore  from './clinics/clinicSettings/clinic-staff'
import clinicsBusinessHoursStore  from './clinics/clinicSettings/business-hours'
import clinicPlansStore  from './clinics/clinicSettings/clinic-plan'
import userDefaultLocationStore  from './user/default-location'
import clinicBookingSettingsSlice from "./clinics/clinicSettings/booking-settings";
import userChartFormStore from "./user/chart-form";
import userCreateCardStore from "./user/user-cards";
import membershipStore from "./super-admin/membership";
import userMembershipStore from "./user/user-membership";
import bookingWidgetStore from "./booking-widget";
import userSupportStore from "./user/support";
import userNotificationStore from "./user/notification";
import crmAppointmentStore from "./crm/appointment";
import crmStaffStore from "./crm/staff";
import crmStaffsStore from "./crm/staffs";
import crmPOSStore from "./crm/pos";
import crmAppointmentNotesStore from "./crm/appointment/notes";
import crmAppointmentAttachmentStore from "./crm/appointment/attachment";
import bookAppointmentStore from "./crm/appointment/book-appointment";
import crmStaffAttendanceStore from "./crm/attendance";
import bookAppointmentReminderStore from "./crm/appointment/reminder";
import crmStore from "./crm/crm";
import crmDetailsStore from "./crm/crm/crm-details";
import crmDashboardStore from "./crm/dashboard";
import crmCalenderStore from "./crm/calender";
import reportsStore from "./crm/reports";
import chat from "./crm/chat";
import TreatmentPlanStore from "./user/treatment-plan";
import callLogStore from "./crm/call-log";
import ActivityLogStore from "./crm/activity-log"
import inventoryStore from "./crm/inventory";
import homeStore from "./home/index"
import membershipstore from './crm/membership'

export const store = configureStore({
    reducer: {
        authStore,
        publicStore,
        rolesStore,
        menuStore,
        languageStore,
        notificationEventStore,
        notificationTemplateStore,
        paymentMethodStore,
        permissionStore,
        clinicStore,
        clinicServiceTypesStore,
        clinicBookingSettingsSlice,
        clinicRoomsStore,
        clinicTreatmentStore,
        clinicTreatmentFormStore,
        clinicTreatmentGroupStore,
        clinicServicesStore,
        clinicExtraServicesStore,
        clinicPromoStore,
        clinicEventsStore,
        clinicsStaffSliceStore,
        clinicsBusinessHoursStore,
        clinicPlansStore,
        userDefaultLocationStore,
        userCreateCardStore,
        membershipStore,
        userMembershipStore,
        bookingWidgetStore,
        userSupportStore,
        userNotificationStore,
        crmAppointmentStore,
        crmStaffStore,
        crmStaffsStore,
        crmPOSStore,
        crmAppointmentNotesStore,
        crmAppointmentAttachmentStore,
        bookAppointmentReminderStore,
        bookAppointmentStore,
        crmStore,
        crmDetailsStore,
        crmCalenderStore,
        crmStaffAttendanceStore,
        crmDashboardStore,
        reportsStore,
        chat,
        TreatmentPlanStore,
        callLogStore,
        ActivityLogStore,
        userChartFormStore,
        inventoryStore,
        homeStore,
        membershipstore,
       
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false})
})