import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../../components/loader/loader";
import {Box, FormLabel, Grid, Typography} from "@mui/material";
import FormInput, {labelStyle} from "../../../../components/form/formInput";
import SubmitButton from "../../../../components/custom-button/submitButton";
import FormPasswordInput from "../../../../components/form/formPasswordInput";
import CustomButton from "../../../../components/custom-button/customButton";
import { useEffect } from "react";
import Image from "../../../../components/image/image";
import {getAllClinicsServices} from "../../../../store/clinics/clinicSettings/clinics-services";
import {
    getClinicsStaffByID,
    storeClinicsStaff,
    updateClinicsStaff
} from "../../../../store/clinics/clinicSettings/clinic-staff";
import FormAutoComplete from "../../../../components/form/FormAutoComplete";
import FormInputPhone from "../../../../components/form/formInputPhone";
import {Link} from "react-router-dom";
import { HexColorPicker } from 'react-colorful';
import CustomModal from "../../../../components/modal/customModal";
import menuStore, { getAllMenus as fetchAllMenus } from "../../../../store/menus";
import { getAllClinicsTreatmentGroup } from "../../../../store/clinics/clinicSettings/clinic-treatment-group";
import FormAutoParentChild from "../../../../components/form/FormAutoParentChild";

const AddStaff = () => {
    const [loading,setLoading] = useState(false)
    const [isOpen,setIsOpen] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const clinicServicesStore = useSelector((state) => state?.clinicServicesStore);
     const clinicTreatmentGroupStore = useSelector(state => state?.clinicTreatmentGroupStore)
    const user = useSelector(state => state?.authStore?.user)
    const menus = useSelector((state) => state.menuStore.menus);


    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        color: Yup.string().required("Color is required"),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        phone: Yup.string().when('countryCode', {
            is: '+92',
            then: Yup.string()
                .matches(/^[1-9][0-9]\d{8}$/, 'Invalid pakistani phone number'),
            otherwise: Yup.string()
                .matches(/^(\d{3}-?)?\d{3}-?\d{4}$/, 'Invalid canada phone number')
        }),
        countryCode: Yup.string().required("Country code is required"),
        /* job_role: Yup.string().required("Job Role is required"),*/
        // service_ids: Yup.array().min(1, "Select at least one service").typeError("Select at least one service"),
        /*      password: Yup.string().when('profile_image', {
                  is:(name) =>typeof name === 'string',
                  then: Yup.string(),
                  otherwise:Yup.string().min(8, "Password must be 8 characters long")
                     /!* .matches(/[0-9]/, "Password requires a number")
                      .matches(/[a-z]/, "Password requires a lowercase letter")
                      .matches(/[A-Z]/, "Password requires an uppercase letter")*!/
                      .required("Password is Required"),
              }),*/

        // profile_image: Yup.mixed().required("Image is Required"),

    });
    const { values, handleSubmit,setFieldTouched,setFieldError, setFieldValue, handleChange, touched, errors } =
        useFormik({
            initialValues: {
                name: "",
                email: "",
                color: "#463434",
                phone: "",
                countryCode: "+1",
                job_role: "",
                service_ids: [],
                category_ids:[],
                menu_ids: [],
                password: "",
                profile_image:null
            },
            validationSchema: validationSchema,
            onSubmit: async (data) => {
                setLoading(true)
                const formData = new FormData()
                Object.keys(data).forEach(function (key, index) {
                    if (Array.isArray(data[key])){
                        data[key].forEach(value => formData.append(key + '[]', value?.id))
                    }else{
                        if (key==="profile_image"){
                            if (typeof data[key] !== 'string'){
                                formData.append(key, data[key]);
                            }
                        }else if (key==='countryCode'|| key==='phone'){
                            formData.append(key, data["countryCode"]+data["phone"]);
                        }else {
                            formData.append(key, data[key]);
                        }
                    }

                });
                formData.append("provider_id", params?.clinic_id);
                let res
                if (params?.staff_id){

                    res = await dispatch(updateClinicsStaff({data:formData,provider_id:params?.clinic_id}))

                }else {

                    res = await dispatch(storeClinicsStaff({data:formData,provider_id:params?.clinic_id}))
                }
                if (res?.payload) {

                    if (res?.payload?.success ==='true'){
                        navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/staff`)
                    }
                    if (res?.payload?.errors) {
                        const errors = res?.payload?.errors
                        try {
                            if (Object.keys(errors)?.length > 0) {
                                Object.keys(errors).forEach(function (key, index) {
                                    setFieldTouched(key, true)
                                    setFieldError(key, errors[key])
                                });
                            }

                        } catch (e) {

                            if (Object.keys(errors)?.length > 0) {
                                Object.keys(errors).forEach(function (key, index) {
                                    setFieldTouched(key, true)
                                    setFieldError(key, errors[key])
                                });
                            }

                        }
                    }
                }
                setLoading(false)

            },
        });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(getAllClinicsServices({ provider_id: params.clinic_id }));
            await dispatch(getAllClinicsTreatmentGroup( params.clinic_id))
            await dispatch(fetchAllMenus());
        
            setLoading(false);
        };
        fetchData();
    }, [dispatch, params.clinic_id]);

    useEffect(()=>{
        (async () => {
            if (params?.staff_id) {
                setLoading(true)
                let res = await dispatch(getClinicsStaffByID({provider_id:params?.clinic_id,id:params?.staff_id}))

                if (res?.payload?.data?.id) {
                    const data =res?.payload?.data
                    await setFieldValue("name", data?.name || '')
                    await setFieldValue("profile_image", data?.profile_image || '')
                    await setFieldValue("color", data?.color || '#463434')
                    await setFieldValue("service_ids", data?.services || '')
                    await setFieldValue("category_ids",data?.categories || '')
                    await setFieldValue("menu_ids", data?.menus || '')
                    if (data?.phone?.includes("+1")){
                        await setFieldValue("phone", data?.phone.replace(/^\+1/, '')) 
                        await setFieldValue('countryCode', '+1')
                    }
                    if (data?.phone?.includes("+92")){  
                        await setFieldValue("phone", data?.phone.replace(/^\+92/, ''))
                        await setFieldValue('countryCode', '+92')
                    }  
                    await setFieldValue('email', data?.email || '')
                    await setFieldValue('job_role', data?.job_role || '')
                    await setFieldValue('id', data?.id)  
                }
                setLoading(false)
            }

        })()
    },[params?.staff_id])
    console.log("length",!clinicTreatmentGroupStore?.treatmentGroups)
    console.log("Menus",menus)

    const validateData =()=>(
        <Grid container p={'20px'} >
             {!clinicServicesStore?.services?.length && <Grid item xs={12} sx={{display:'flex',mt:'16px',justifyContent:'center',alignItems:'center'}}>
                <Typography sx={{textAlign:'center'}}>Please Add Services before</Typography>
                <Link to={`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-service`} style={{color:'#E01E5A',marginLeft:'7px'}}>Click here</Link>
            </Grid>}

        </Grid>
    )

    console.log("staff id",params?.staff_id )
    return (
        loading ? <Loader/> :
            !clinicServicesStore?.services?.length ?
                validateData()
                : <form onSubmit={handleSubmit}>
                    <Grid container p={"20px"} sx={{ }}>
                        <Grid item xs={3.3} sx={{maxWidth: {xs:"200px"},mr:'20px'}}>
                            <Image
                                label={'Image'}
                                name={"profile_image"}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomModal open={isOpen} setOpen={setIsOpen}>
                                <HexColorPicker
                                    color={values.color}
                                    onChange={(color) => setFieldValue('color', color)}
                                />
                            </CustomModal>
                            <FormLabel sx={labelStyle}>Legend Color</FormLabel>
                            <Box onClick={()=>setIsOpen(true)} style={{cursor:'pointer', marginTop: '10px' ,height:'35px',width:'35px',borderRadius:'5px',border:'1px solid #EDF2F7', backgroundColor:values?.color }}/>
                        </Grid>

                        <Grid
                            container
                            sx={{ marginTop: "7px", justifyContent: "space-between" }}
                        >
                            <Grid item xs={12} lg={3.7}>
                                <FormInput
                                    name={"name"}
                                    label={"Name"}
                                    placeholder={"Type your name"}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3.7}>
                                <FormInput
                                    name={"email"}
                                    label={"Email"}
                                    placeholder={"Type your  email"}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3.7}>
                                <FormInputPhone
                                    name={"phone"}
                                    label={"Phone"}
                                    placeholder={values?.countryCode ==='+1' ? "xxx-xxx-xxxx" : "3xxxxxxxxx"}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{ marginTop: "7px", justifyContent: "space-between" }}
                        >
                            <Grid item xs={12} lg={3.7}>
                                <FormInput
                                    name={"job_role"}
                                    label={"Job Role"}
                                    placeholder={"Type your  role"}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3.7}>
                                <FormPasswordInput
                                    name={"password"}
                                    label={"Set Password"} 
                                    placeholder={""}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3.7}>
                            </Grid>
                        </Grid>
                        {params?.staff_id ? ""
                        : <>
                        <Grid container>
                        <Grid item xs={12}>
                            <FormAutoParentChild
                                name={"category_ids"}
                                options={clinicTreatmentGroupStore?.treatmentGroups || []}
                                values={values}
                                multiple={true}
                                errors={errors}
                                touched={touched}
                                label={"Categories Associated"}
                                setFieldValue={setFieldValue}
                            />  
                        </Grid>

                    </Grid>
                    </>}
                       
                        <Grid container>
                            <Grid item xs={12}>
                                <FormAutoComplete
                                    name={"service_ids"}
                                    // options={clinicTreatmentGroupStore?.treatmentGroups}
                                    options={clinicServicesStore?.services}
                                    values={values}
                                    multiple={true}
                                    errors={errors}
                                    touched={touched}
                                    label={"Events Associated"}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>

                        </Grid>
                        {user?.role_id === 2 && (
                            <Grid container>
                            <Grid item xs={12}>
                            <FormAutoComplete
                            name={"menu_ids"}
                            options={menus}
                            values={values}
                            multiple={true}
                            errors={errors}
                            touched={touched}
                            label={"Menus Associated"}
                            setFieldValue={setFieldValue}
                            />
                            </Grid>

                            </Grid>
                        )}

                        <Grid item xs={12} sx={{display: 'flex', mt:'16px',justifyContent: 'flex-end',alignItems:'center'}}>
                            <Box sx={{mr: '16px'}}>
                                <CustomButton
                                    mainColor={'#ED1E45'} 
                                    val={'Cancel'}
                                    handleClick={() => {  
                                        navigate(`/${user?.role_id ===2 || user?.role_id ===4 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/staff`)
                                    }}
                                />
                            </Box>
                            <SubmitButton val={params?.staff_id ? 'Update Staff' : 'Add Staff'}/>
                        </Grid>
                    </Grid>
                </form>
    );
};

export default AddStaff;
