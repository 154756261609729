import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";

export const getAllPOSBooking = createAsyncThunk("getAllPOSBooking", async (data, { getState }) => {
    const {search,pageLimit,filterBy,currentPage} = getState()?.crmPOSStore
    try {
            const response = await axios.get(apis.get_all_crm_booking+`${filterBy}&limit=${pageLimit}&page=${currentPage}${search!=='' ? `&search=${search}`:'' }`, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {

                return {};
            }  
        } catch (err) {

            return  {}
        }
    }
)
export const getBookingInvoice = createAsyncThunk("getBookingInvoice", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_booking_invoice+id,{
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {

            return {};
        }  
    } catch (err) {

        return  {}
    }
}
)
// void_password

export const voidinvoice = createAsyncThunk('voidinvoice', async (payload) => {
    try {
      const response = await axios.post(apis.void_invoice, payload, {
        headers: apiHeaders(),
      });
  
      if (response.data.success === 'true') {
        return response.data.data;
      } else {
        return {};
       
      }
    } catch (error) {
        return {};
    }
  });
  export const voidpassword = createAsyncThunk('voidpassword', async (payload) => {
    try {
      const response = await axios.post(apis.void_password, payload, {
        headers: apiHeaders(),
      });
  
      if (response.data.success === 'true') {
        return response.data.data;
      } else {
        return {};
       
      }
    } catch (error) {
        return {};
    }
  });
  export const sendReminder = createAsyncThunk('sendReminder', async (payload) => {
    try {
      const response = await axios.post(apis.send_reminder, payload, {
        headers: apiHeaders(),
      });
  
      if (response.data.success === 'true') {
        return response.data.data;
      } else {
        return {};
       
      }
    } catch (error) {
        return {};
    }
  });
  export const resendInvoice = createAsyncThunk('resendInvoice', async (payload) => {
    try {
      const response = await axios.post(apis.resend_invoice, payload, {
        headers: apiHeaders(),
      });

      if (response.data.success === 'true') {
        return response.data;
      } else {
        return {};

      }
    } catch (error) {
        return {};
    }
  });


export const updatePOSFilters = createAsyncThunk('updatePOSFilters', async (data) => {
    return data
})



export const POSSlice = createSlice({
    name: "POSSlice",
    initialState: {
        allPOSBookings: {},
        bookingInvoice: {},
        search:'',
        pageLimit:10,
        filterBy:'All',
        currentPage:1,
        void:[],
        voidpass:[]
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllPOSBooking.fulfilled, (state, action) => {
            state.allPOSBookings = action.payload ;
        });
        builder.addCase(getBookingInvoice.fulfilled, (state, action) => {
            state.bookingInvoice = action.payload ;
        });
        
          builder.addCase(voidinvoice.fulfilled, (state, action) => {
            state.void = action.payload ;
        });
        builder.addCase( voidpassword.fulfilled, (state, action) => {
            state.voidpass = action.payload ;
        });
 
        builder.addCase(updatePOSFilters.fulfilled, (state, action) => {
            const {filterBy,pageLimit,search,currentPage} = action?.payload
            if (filterBy){
                state.filterBy = filterBy ;
            }
            if (pageLimit){
                state.pageLimit = pageLimit ;
            }
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
        });
    },
});

export default POSSlice.reducer;
