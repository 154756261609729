import React, {Fragment, useState} from 'react';
import {MenuItem, Box, Typography, IconButton, Avatar, Menu} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiAvatar from "@mui/material/Avatar";
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from "@mui/material/Badge";
import ChatLog from "./chatLog";
import SendMsgForm from "./sendMsgForm";
const ChatWrapperStartChat = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    borderRadius: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor:"#F1F5F9"
}))

const ChatContent = (props) => {
    const {
        store,
        hidden,
        sendMsg,
        mdAbove,
        dispatch,
        statusObj,
        getInitials,
        sidebarWidth,
        userProfileRightOpen,
        handleLeftSidebarToggle,
        handleUserProfileRightSidebarToggle
    } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)


    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleStartConversation = () => {
        if (!mdAbove) {
            handleLeftSidebarToggle()
        }
    }
    console.log("selected chat",store,store.selectedChat)

    const renderContent = () => {
        if (store) {
            const selectedChat = store.selectedChat
            if (!selectedChat) {
                return (
                    <ChatWrapperStartChat
                        sx={{
                            ...(mdAbove ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {})
                        }}
                    >
                        <MuiAvatar
                            sx={{
                                mb: 5,
                                pt: 8,
                                pb: 7,
                                px: 7.5,
                                width: 110,
                                height: 110,
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3]
                            }}
                        >
                            <ChatBubbleOutlineOutlinedIcon sx={{ width: 50, height: 50, color: 'action.active' }} />
                        </MuiAvatar>
                        <Box
                            onClick={handleStartConversation}
                            sx={{
                                px: 6,
                                py: 2.25,
                                borderRadius: 5,
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3],
                                cursor: mdAbove ? 'default' : 'pointer'
                            }}
                        >
                            <Typography sx={{ fontWeight: 600 }}>Start Conversation</Typography>
                        </Box>
                    </ChatWrapperStartChat>
                )
            } else {
                return (
                    <Box   
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            height: '100%',
                            backgroundColor: "#F1F5F9"  
                        }}
                    >
                     <Box
                            sx={{
                                py: 1,
                                px: 5,
                                display: 'flex',
                                alignItems: 'center',   
                                justifyContent: 'space-between',
                                borderBottom: theme => `1px solid ${theme.palette.divider}`
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {mdAbove ? null : (
                                    <IconButton onClick={handleLeftSidebarToggle} sx={{ mr: 2 }}>
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                <Box
                                    onClick={handleUserProfileRightSidebarToggle}
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                >
                                    <Badge
                                        overlap='circular'
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        sx={{ mr: 4.5 }}
                                        badgeContent={
                                            <Box
                                                component='span'
                                                sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    color: `${statusObj[selectedChat.contact.status]}.main`,
                                                    boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`,
                                                    backgroundColor: `${statusObj[selectedChat.contact.status]}.main`
                                                }}
                                            />
                                        }
                                    >
                                        {selectedChat.contact.avatar ? (
                                            <MuiAvatar
                                                src={selectedChat.contact.avatar}
                                                alt={selectedChat.contact.fullName}
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        ) : (
                                            <Avatar
                                                skin='light'
                                                color={selectedChat.contact.avatarColor}
                                                sx={{ width: 40, height: 40, fontSize: '1rem' }}
                                            >
                                                {getInitials(selectedChat.contact.fullName)}
                                            </Avatar>
                                        )}
                                    </Badge>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ color: 'text.secondary' }}>{selectedChat.contact.fullName}</Typography>
                                        <Typography variant='body2' sx={{ color: 'text.disabled' }}>
                                            {selectedChat.contact.role}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {/*{mdAbove ? (*/}
                                {/*    <Fragment>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <PhoneOutlinedIcon />*/}
                                {/*        </IconButton>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <VideocamOutlinedIcon sx={{ fontSize: '1.5rem' }} />*/}
                                {/*        </IconButton>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <SearchIcon />*/}
                                {/*        </IconButton>*/}
                                {/*    </Fragment>*/}
                                {/*) : null}*/}
                                <IconButton size='small' onClick={handleClick} sx={{ color: 'text.secondary' }}>
                                    <MoreVertOutlinedIcon />
                                </IconButton>
                                <Menu
                                    open={open}
                                    sx={{ mt: 2 }}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{   
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>View Contact</MenuItem>
                                    <MenuItem onClick={handleClose}>Mute Notifications</MenuItem>
                                    <MenuItem onClick={handleClose}>Block Contact</MenuItem>
                                    <MenuItem onClick={handleClose}>Clear Chat</MenuItem>
                                    <MenuItem onClick={handleClose}>Report</MenuItem>
                                </Menu>
                            </Box>
                        </Box>

                        {selectedChat && store.userProfile ? (
                            <ChatLog hidden={hidden} data={{ ...selectedChat, userContact: store.userProfile }} />
                        ) : null}

                        <SendMsgForm store={store} dispatch={dispatch} sendMsg={sendMsg} />

                        {/*<UserProfileRight*/}
                        {/*    store={store}*/}
                        {/*    hidden={hidden}*/}
                        {/*    statusObj={statusObj}*/}
                        {/*    getInitials={getInitials}*/}
                        {/*    sidebarWidth={sidebarWidth}*/}
                        {/*    userProfileRightOpen={userProfileRightOpen}*/}
                        {/*    handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}*/}
                        {/*/>*/}
                    </Box>
                )   
            }
        } else {
            return null
        }
    }

    return renderContent()
};

export default ChatContent;