import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import CardTable from '../../../../components/card-table/Cardtabel';
import SimpleInput from '../../../../components/form/simpleInput';
import {Box, FormControl, Grid, Typography, Container, Button, Tooltip, IconButton} from "@mui/material";
import CustomButton from '../../../../components/custom-button/customButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deletePromoCode, getPromoCodes, updatepromoFilter } from '../../../../store/clinics/clinicSettings/clinic-promo';
import Waveloading from '../../../loading/Waveloading';
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import moment from "moment";

import {MySwal, successConfirmation} from "../../../../services/utills";
import BackendSearchField from '../../../../components/pagination/backendSearch';
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D8F3FF",
    color: "#27272E",
    fontSize: "16px",
    fontWeight: 700,
  },  
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const Promo = () => {   
  const [loading, setLoading] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clinicPromoStore = useSelector(state => state?.clinicPromoStore)
    const {Promos,search,currentPage} = clinicPromoStore
  console.log("data comming",Promos)  
    useEffect(()=>{   
      (async ()=>{   
          setLoading(true)
          await dispatch(getPromoCodes())
          setLoading(false)
      })()
  },[dispatch])    
  
    const user = useSelector(state => state?.authStore?.user)
    const handleButtonClick =  async (status) => {  
      console.log('Button clicked with status:', status);  
      setLoading(true);// Add this line
      try {
        await dispatch(getPromoCodes(status));   
      
      } catch (error) {   
        toast.error("error occure") 
    
      } finally {
        setLoading(false);    
    
      }
    };


    const handleClick = () => {
      if (params?.clinic_id) {
        navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-promo`);
      }
    };
    const handleEdit = (row, isEdit) => {
      console.log("id", row)
      if (isEdit) {
          navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-promo/${row?.id}`)
      }
  }   
  const deleteusercard = async (item) => {

    const result = await MySwal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',  
        confirmButtonText: 'Yes, delete it'
    });

    if (result.isConfirmed) { 
      console.log("item",item)   
        setLoading(true)  
        const payload ={
          id:item?.id,
          provider_id:item?.provider_id
        }
             
        try {
            await dispatch(deletePromoCode(payload));             
            setLoading(false)     
        
        } catch (error) {  
            console.error("Error in deleteUserCard:", error);
            setLoading(false)   
        } 
    } else {
      setLoading(false)     
    }    
};  
const handleSearch = async (val) => {
  console.log("handel",val)  
  await dispatch(updatepromoFilter({search:val}))
}     
const refreshData = async () => {
  setLoading(true);
  await dispatch(getPromoCodes())    
  setLoading(false);   
};  
const handleCopyUrl = async (item) => {
  if (!item?.discount_code) {
    return;
  }

  // Copy the item URL to the clipboard
  navigator.clipboard.writeText(item.discount_code);

  // Show a success message
  let timerInterval;
  await MySwal.fire({
    icon: 'success',
    title: 'Promo Code Copied Successfully',
    showConfirmButton: false,
    html: (
      <Box sx={{ mb: 2 }}>
        <Typography>
          I will close in <b></b> milliseconds.
        </Typography>
        <Button
          sx={{ mt: "16px" }}
          onClick={() => MySwal.close()}
        >
          Ok. Close It
        </Button>
      </Box>
    ),
    timer: 4000,
    timerProgressBar: true,
    didOpen: () => {
      const b = MySwal.getHtmlContainer().querySelector('b');
      timerInterval = setInterval(() => {
        b.textContent = MySwal.getTimerLeft();
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  });
};


   
  return (  
    <>      
      <Grid container p={'20px'}>  
        <Grid item xs={12} sx={{ mb: '16px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', '@media (max-width: 420px)':{display:"block" }, }}>
        <FormControl sx={{mr: "10px",  mb: { xs: 2, sm: 0}}}>   
          <BackendSearchField      
                            search={search}
                            handleSearch={handleSearch}   
                            fetchData={async ()=>{
                                await dispatch(updatepromoFilter({currentPage:1}))
                                await refreshData()
                            }}
                        /> 
          </FormControl>
          <CustomButton val={'Add Promo Codes'} mrt={"10px"} size={'large'} handleClick={handleClick} />
        </Grid>
        <Grid item xs={12} style={{display:"flex",gap:"3",justifyContent: 'flex-end'}}>
        <div>
            <Button 
        variant="outlined" 
        size="large" 
        // color="success"
        style={{ marginTop: "20px", marginRight: "10px"}} 
        onClick={() => handleButtonClick("All")}
      >
        All    
      </Button>  
        
      <Button    
        variant="outlined"  
        size="large" 
        color="success"
        style={{ marginTop: "20px", marginRight: "10px"}} 
        onClick={() => handleButtonClick("Active")}
      >
        Active
      </Button>
      <Button 
        variant="outlined" 
        size="large" 
        // color="error"
        style={{ marginTop: "20px", marginRight: "10px", color: "#dcd90a", borderColor: "#dcd90a" }} 
        onClick={() => handleButtonClick("In-Active")}
      >
    InActive
      </Button>  
      <Button  
        variant="outlined"   
        size="large" 
        color="error"
        style={{ marginTop: "20px", marginRight: "10px" }} 
        onClick={() => handleButtonClick("Expired")}  
      >   
   EXPIRED
      </Button>
     
    </div>

        </Grid>
        </Grid>    



        {loading ? <Waveloading/> :
<Container maxWidth="xl">
  
<TableContainer component={Paper}>   
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
          <StyledTableCell>Discount Code</StyledTableCell>
          <StyledTableCell>Discount Type</StyledTableCell>
          <StyledTableCell>Discount Value</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell align="left">Expiration Date</StyledTableCell>
            <StyledTableCell align="left">Used</StyledTableCell>
            <StyledTableCell align="left">Action</StyledTableCell>

          </TableRow>
        </TableHead> 
        <TableBody>   
       { Promos && Promos.data ? (   
      Promos.data.length > 0 ? (
        Promos.data.map((row) => (  
        <StyledTableRow key={row.name}>
           <StyledTableCell align="left">{row?.discount_code}</StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row?.discount_type}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row?.discount_value}
            </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row?.status}
          </StyledTableCell>
         
          <StyledTableCell align="left">   {moment(row?.end_date).format("dddd MMMM D, YYYY")}</StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row?.count}  
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
           <IconButton
                                  onClick={() => handleCopyUrl(row)}
                              >
                                <ContentCopyIcon/>
                              </IconButton>
          <Tooltip title="Edit">
                                <IconButton
                                    size="small"
                                    onClick={() => handleEdit(row, true)}
                                >
                                  <MdModeEditOutline color={"#12B104"} fontSize={'20px'}/>
                                </IconButton>   
                              </Tooltip>
                              <Tooltip title="Delete">   
                                <IconButton
                                    size="small"
                                    onClick={() => deleteusercard(row)}   
                                >
                                   <MdDeleteOutline color={'#E53E31'} fontSize={'20px'}/>
                                </IconButton>
                              </Tooltip>
          </StyledTableCell>
         
        </StyledTableRow>
       ))
      ) : (
        <TableRow>
        <TableCell colSpan={6} style={{ textAlign: 'center' }}>
          <p style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
            No data available
          </p>
        </TableCell>
      </TableRow>
      )
    ) : (  
      <p>...</p> // Or some other fallback UI
    )}
  
        </TableBody>
      </Table>
    </TableContainer>
</Container>
}


    </>
  )    
}

export default Promo