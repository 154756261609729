import React, {useEffect, useState} from 'react';
import Loader from "../../../../components/loader/loader";
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    TextField, Typography,
} from "@mui/material";
import FormInput, {inputStyle} from "../../../../components/form/formInput";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {useFormik} from "formik";
import { useLocation } from 'react-router-dom';
import FormStatusInput from "../../../../components/form/FormStatusInput";
import {Link, useParams} from "react-router-dom";
import {getAllClinicsTreatmentForm} from "../../../../store/clinics/clinicSettings/clinic-treatment-form";
import {getAllClinicsRooms} from "../../../../store/clinics/clinicSettings/clinic-rooms";
import {getAllClinicsTreatmentGroup} from "../../../../store/clinics/clinicSettings/clinic-treatment-group";
import Image from "../../../../components/image/image";
import {
    getClinicsServicesByID,
    storeClinicsServices,
    updateClinicsServices
} from "../../../../store/clinics/clinicSettings/clinics-services";
import {useNavigate} from "react-router";
import CustomButton from "../../../../components/custom-button/customButton";
import {getAllClinicsStaff} from "../../../../store/clinics/clinicSettings/clinic-staff";
import FormAutoComplete from "../../../../components/form/FormAutoComplete";
import {appointmentTime} from "../../../../services/utills";
import {getAllProducts} from "../../../../store/crm/inventory";
import {getServicesCategory} from "../../../../store/crm/appointment/book-appointment";
import FormAutoParentChild from '../../../../components/form/FormAutoParentChild';
import FormDateInput from "../../../../components/form/FormDateInput1";

 
const AddServices = ({clinicId,onClose }) => {
    const [loading, setLoading] = useState(false)
    const [clinicTreatmentGroupData, setClinicTreatmentGroupData] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const params = useParams();

    const clinic_id = params?.clinic_id || clinicId
    const clinicRoomsStore = useSelector(state => state?.clinicRoomsStore)
    const clinicStaffStore = useSelector(state => state?.clinicsStaffSliceStore)
    const clinicTreatmentFormStore = useSelector(state => state?.clinicTreatmentFormStore)
    const clinicTreatmentGroupStore = useSelector(state => state?.clinicTreatmentGroupStore)
    const user = useSelector(state => state?.authStore?.user)
    const inventoryStore = useSelector(state => state?.inventoryStore)
    console.log("treatment forms", clinicTreatmentFormStore )
    const location = useLocation();
    const {
        products = [],
    } = inventoryStore
    useEffect(() => {
       
        const allTreatmentGroups = clinicTreatmentGroupStore?.treatmentGroups || [];
      
        const groupsById = {};
      
        
        allTreatmentGroups.forEach(group => {
          groupsById[group.id] = { ...group, children: [] };
        });
      
     
        allTreatmentGroups.forEach(group => {
          if (group.parent_id && groupsById[group.parent_id]) {
            groupsById[group.parent_id].children.push(group); 
          }
        });
      
        
        const flattenedNestedGroups = Object.values(groupsById);

        setClinicTreatmentGroupData(flattenedNestedGroups);
      }, [clinicTreatmentGroupStore, clinicTreatmentGroupStore?.treatmentGroups?.length]);
      
      
console.log("name ",clinicTreatmentGroupData)


    const validationSchema = Yup.object({   
        TSKU: Yup.string().required("TSKU is Required"),
        name: Yup.string().required("name is Required"),
        description: Yup.string(),
        photograph_video_release: Yup.string().typeError("photograph video release is Required").required("photograph video release is Required"),
        is_promo: Yup.string().typeError("Is Promo is Required").required("Is Promo is Required"),
        qty_enable: Yup.string().typeError("Is Quantity is Required").required("Quantity is Required"),
        status: Yup.string().required("slug is Required"),
        member_price: Yup.string().when('is_free', {
            is: (value) => parseInt(value) === 0,
            then: Yup.string().matches(
                /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                "Only Integer Allowed"
            ).required("member_price is Required"),  
            otherwise: Yup.string().nullable()
        }),
        upfront_amount: Yup.string().when('is_upfront_enable', {
            is: (value) => parseInt(value) === 1,
            then: Yup.string().matches(
                /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                "Only Integer Allowed"
            ).required("Upfront amount is Required"),
            otherwise: Yup.string().nullable()
        }),
        regular_price: Yup.string().when('is_free', {
            is: (value) => parseInt(value) === 0,
            then: Yup.string().matches(
                /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                "Only Integer Allowed"
            ).required("Regular price is Required"),
            otherwise: Yup.string().nullable()
        }),
        minimum_quantity: Yup.string().when('qty_enable', {
            is: (value) => parseInt(value) === 1,
            then: Yup.string().matches(
                /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                "Only Integer Allowed"
            ).required("Required Field"),
            otherwise: Yup.string().nullable()
        }),
        product_id: Yup.string().when('qty_enable', {
            is: (value) => parseInt(value) === 1,
            then:Yup.string().typeError("Required Field").required("Required Field"),
            otherwise: Yup.string().nullable()
        }),
        // per_service_price: Yup.string().when('qty_enable', {
        //     is: (value) => parseInt(value) === 1,
        //     then: Yup.string().matches(
        //         /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        //         "Only Integer Allowed"
        //     ).required("Required Field"),
        //     otherwise: Yup.string().nullable()
        // }),
        // quantity_measurements: Yup.string().when('qty_enable', {
        //     is: (value) => parseInt(value) === 1,
        //     then: Yup.string().typeError("Quantity type is Required").required("Quantity type is Required"),
        //     otherwise: Yup.string().nullable()
        // }),
        // room_id: Yup.array().typeError("Room is Required").required("Room is Required"),
        slug: Yup.string().required("slug is Required"),
        staff_id: Yup.array().typeError("Staff is Required"),
        time_duration: Yup.string().typeError("Time duration is Required").required("Time duration is Required"),
        // treatment_form_id: Yup.string().required("Treatment form is Required"),
        category_ids: Yup.string().required("Treatment / Category  is Required"),
        // image: Yup.mixed().required("Image is Required"),
    });
  
    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            TSKU: '',
            description: '',
            // total_quantity: '',
            // per_service_quantity: '',
            minimum_quantity: '',
            upfront_amount: '',
            product_id: '',
            qty_enable: 0,
            is_promo: 0,
            is_free: 0,
            service_schedule_date: null,
            promo_end_date: null,
            is_show_website: 0,
            photograph_video_release: 0,
            is_upfront_enable: 0,
            is_upfront_percentage: 0,  
            is_consultation_category: 0,
            virtual_paid: 0,
            status: 'In-Active',
            member_price: '',
            name: '',
            regular_price: '',
            room_id: [],
            slug: '',
            staff_id: [],
            time_duration: null,
            treatment_form_id: [],
            category_ids: [],
            image: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)
            const formData = new FormData()  

            Object.keys(data).forEach(function (key, index) {
                if (key ==='product_id'){
                    formData.append(key, data[key]?.id);
                } else if (Array.isArray(data[key])) {
                    data[key].forEach(value => formData.append(key + '[]', value?.id))
                } else {
                    if (key === "image") {
                        if (typeof data[key] !== 'string') {   
                            formData.append(key, data[key]);  
                        }
                    } else {
                        formData.append(key, data[key]);
                    }  
                }

            });
            formData.append("provider_id", clinic_id);
            let res
            if (params?.service_id) {
                res = await dispatch(updateClinicsServices({data: formData, provider_id: clinic_id}))
            } else {
                res = await dispatch(storeClinicsServices({data: formData, provider_id: clinic_id}))
            }
            if (res?.payload) {

                if (res?.payload?.success === 'true') {
                    navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${clinic_id}/services`)
                }
                if (res?.payload?.errors) {
                    const errors = res?.payload?.errors
                    try {
                        if (Object.keys(errors)?.length > 0) {
                            Object.keys(errors).forEach(function (key, index) {
                                setFieldTouched(key, true)
                                setFieldError(key, errors[key])
                            });
                        }
                    } catch (e) {

                    }
                }
            }
            setLoading(false)  
            const category = res.payload.data.category  
     
            onClose(category)

        },
    });

// console.log(" categoryy id ",categoryId)
    useEffect(() => {
        if (values?.is_free === 1) {  
            setFieldValue("member_price", 0)
            setFieldValue("regular_price", 0)
        }
    }, [values?.is_free, values?.member_price, values?.regular_price])

    useEffect(() => {
        (async () => {
            if (!clinicRoomsStore?.rooms?.length || !clinicTreatmentFormStore?.treatmentForms?.length || !clinicTreatmentGroupStore?.treatmentGroups?.length) {
                setLoading(true)
            } 
            await dispatch(getAllClinicsTreatmentForm(clinic_id))
            await dispatch(getAllClinicsRooms(clinic_id))
            await dispatch(getAllClinicsTreatmentGroup(clinic_id))
            await dispatch(getAllClinicsStaff(clinic_id))
            setLoading(false)
            await dispatch(getAllProducts())
        })()
    }, [clinic_id])
    useEffect(() => {
        (async () => {
            if (params?.service_id) {
                setLoading(true)
                let res = await dispatch(getClinicsServicesByID(params?.service_id))

                if (res?.payload?.data?.id) {
                    const data = res?.payload?.data  

                    await setFieldValue("name", data?.name || '')  
                    await setFieldValue("description", data?.description || '')
                    await setFieldValue("TSKU", data?.TSKU || '')
                    await setFieldValue("slug", data?.slug || '')
                    await setFieldValue("member_price", data?.member_price || "")
                    await setFieldValue("regular_price", data?.regular_price || "")  
                    await setFieldValue("time_duration", data?.time_duration || null)
                    await setFieldValue('status', data?.status || 'In-Active')
                    await setFieldValue('qty_enable', data?.qty_enable || 0)
                    await setFieldValue('product_id', data?.product || '')
                    await setFieldValue('minimum_quantity', data?.minimum_quantity || '')
                    await setFieldValue('upfront_amount', data?.upfront_amount || '')
                    // await setFieldValue('quantity_measurements', data?.quantity_measurements || '')
                    // await setFieldValue('per_service_price', data?.per_service_price || '')
                    await setFieldValue('photograph_video_release', data?.photograph_video_release || 0)
                    await setFieldValue('is_upfront_percentage', data?.is_upfront_percentage || 0)
                    await setFieldValue('is_consultation_category', data?.is_consultation_category || 0)
                    await setFieldValue('virtual_paid', data?.virtual_paid || 0)
                    await setFieldValue('is_upfront_enable', data?.is_upfront_enable || 0)
                    await setFieldValue('is_promo', data?.is_promo || 0)
                    await setFieldValue('is_show_website', data?.is_show_website || 0)
                    await setFieldValue('is_free', data?.is_free || 0)
                    await setFieldValue('service_schedule_date', data?.service_schedule_date || null)
                    await setFieldValue('promo_end_date', data?.promo_end_date || null)
                    await setFieldValue('is_price_show', data?.is_price_show || 0)
                    await setFieldValue('image', data?.image || null)
                    await setFieldValue('room_id', data?.room_service || [])
                    await setFieldValue('staff_id', data?.staff_service || [])
                    await setFieldValue('treatment_form_id', data?.treatment_form_service || [])
                    await setFieldValue('category_ids', data?.categories || [])
                    await setFieldValue('id', res?.payload?.data?.id)
                }
                setLoading(false)
            }

        })()
    }, [params?.service_id])     



    const validateData = () => (
        <Grid container sx={{ padding:{xs:'0px',sm:'20px'} }}>
            {!clinicRoomsStore?.rooms?.length &&
                <Grid item xs={12} sx={{display: 'flex', mt: '16px', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography sx={{textAlign: 'center'}}>Please Add rooms before</Typography>
                    <Link to={`/admin/configuration/clinic/${clinic_id}/add-room`}
                          style={{color: '#E01E5A', marginLeft: '7px'}}>Click here</Link>
                </Grid>}
            {!clinicTreatmentFormStore?.treatmentForms?.length &&
                <Grid item xs={12} sx={{display: 'flex', mt: '16px', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography sx={{textAlign: 'center'}}>Please Add Treatment Form before</Typography>
                    <Link to={`/admin/configuration/clinic/${clinic_id}/add-treatment-form`}
                          style={{color: '#E01E5A', marginLeft: '7px'}}>Click here</Link>
                </Grid>}
            {!clinicTreatmentGroupData?.length &&
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', mt: '16px', alignItems: 'center'}}>
                    <Typography sx={{textAlign: 'center'}}>Please Add Treatment / Group before</Typography>
                    <Link to={`/admin/configuration/clinic/${clinic_id}/add-treatment-group`}
                          style={{color: '#E01E5A', marginLeft: '7px'}}>Click here</Link>
                </Grid>}
        </Grid>
    )

    return (
        loading ? <Loader/> :
            (!clinicRoomsStore?.rooms?.length || !clinicTreatmentFormStore?.treatmentForms?.length || !clinicTreatmentGroupData?.length) ?
                validateData()
                : <form onSubmit={handleSubmit}>
                    <Grid container sx={{ padding:{xs:'0px',sm:'20px',justifyContent: 'space-between'} }} >
                        <Grid item xs={12} lg={5.5} xl={3.6}>
                            <FormInput
                                name={"name"}
                                label={"Service Name"}
                                placeholder={'Type  your name'}        
                                errors={errors}   
                                touched={touched}
                                values={values}
                                handleChange={(e)=>{
                                    const {value} = e?.target
                                    setFieldValue("name", value)
                                    let finalValue = value.replace(/[^a-zA-Z ]/g, '-').replace(/\s+/g, '-').toLowerCase().replace(/-+/g, '-')
                                    setFieldValue("slug", finalValue)  
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5.5} xl={3.6}>

                            <FormInput
                                name={"TSKU"}
                                label={"TSKU"}
                                placeholder={'Type  your TSKU'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5.5} xl={3.6}>  
                            <FormInput
                                name={"slug"}
                                label={"Slug"}
                                placeholder={'Type your Slug'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={(e)=>{
                                    const { value } = e?.target;
                                    let finalValue = value.replace(/[^a-zA-Z0-9 ]/g, '-').replace(/\s+/g, '-').toLowerCase().replace(/-+/g, '-');
                                    setFieldValue("slug", finalValue);  
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} lg={5.5} xl={3.6}>
                            <FormInput
                                disabled={values?.is_free === 1}
                                name={"regular_price"}
                                label={"Regular Price"}
                                placeholder={'Type  your regular price'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5.5} xl={3.6}>
                            <FormInput
                                disabled={values?.is_free === 1}
                                name={"member_price"}   
                                label={"Member Price"}
                                placeholder={'Type  your member price'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5.5} xl={3.6}>
                            <FormAutoComplete
                                name={"time_duration"}
                                options={appointmentTime() ?? []}
                                values={values}  
                                errors={errors}
                                touched={touched}
                                label={'Service Duration Time'}
                                setFieldValue={setFieldValue}
                            /> 
                            {/*<FormTimeInput*/}
                            {/*    name={"time_duration"}*/}
                            {/*    label={"Time Duration"}*/}
                            {/*    placeholder={'Type  your linkedIn'}*/}
                            {/*    errors={errors}*/}  
                            {/*    touched={touched}*/}  
                            {/*    values={values}*/}
                            {/*    handleChange={handleChange}*/}
                            {/*/>*/}
                        </Grid>
  
                        <Grid item xs={12} sm={5.8} lg={2.5} >    
                            <FormStatusInput
                                name={"is_price_show"}  
                                label={"Price Show?"}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />
                        </Grid>   
                        <Grid item xs={12} sm={5.8} lg={2.2}>
                            <FormStatusInput  
                                name={"qty_enable"}
                                label={"Quantity?"}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                            />   
                        </Grid>
                        <Grid item xs={12} sm={5.8} lg={2.4}>
                            <FormStatusInput
                                name={"is_promo"}
                                label={"Promo?"}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5.8} lg={4.6}>
                            <FormStatusInput
                                name={"photograph_video_release"}
                                label={"Photograph & Video Release Form?"}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>  
                        <Grid xs={12} sm={5.8} lg={3}>   
                            <FormStatusInput
                                name={"is_consultation_category"}
                                label={"Consultation Category?"}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}   
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={5.8} lg={3}>
                            <FormStatusInput
                                name={"virtual_paid"}
                                label={"Virtual Paid?"}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={5.8} lg={3}>
                            <FormStatusInput
                                name={"is_upfront_enable"}
                                label={"Deposit Enable?"}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5.8} lg={3}>
                            <FormStatusInput
                                name={"is_upfront_percentage"}
                                label={"Deposit Percentage?"}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>  
                        <Grid item xs={12} lg={3}>
                            <FormInput  
                                name={"upfront_amount"}
                                label={`Deposit Amount`}
                                placeholder={`Type Deposit amount`}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>

                        {values?.qty_enable === 1 &&
                        <Grid item xs={12}>
                            <Grid container  sx={{justifyContent: 'space-between'}}>
                                <Grid item xs={12} lg={5.5} xl={3.6}>
                                    <FormAutoComplete
                                        name={"product_id"}
                                        values={values}
                                        options={products ?? []}
                                        errors={errors}
                                        touched={touched}
                                        label={'Select Product'}
                                        setFieldValue={setFieldValue}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5.5} xl={3.6}>
                                    <FormInput
                                        name={"minimum_quantity"}
                                        label={`Minimum  Quantity`}
                                        placeholder={`Type minimum quantity`} 
                                        errors={errors}  
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5.5} xl={3.6}> </Grid>
                                {/*<Grid item xs={12} lg={5.5} xl={3.6}>*/}
                                {/*    <FormInput*/}
                                {/*        name={"per_service_quantity"}*/}
                                {/*        label={`${values?.quantity_measurements} Per Service`}*/}
                                {/*        placeholder={`Type ${values?.quantity_measurements} per service`}*/}
                                {/*        errors={errors}*/}
                                {/*        touched={touched}*/}
                                {/*        values={values}*/}
                                {/*        handleChange={handleChange}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} lg={5.5} xl={3.6}>*/}
                                {/*    <FormInput*/}
                                {/*        name={"per_service_price"}*/}
                                {/*        label={`Per ${values?.quantity_measurements} Price`}*/}
                                {/*        placeholder={`Type per ${values?.quantity_measurements} price`}*/}
                                {/*        errors={errors}*/}
                                {/*        touched={touched}*/}
                                {/*        values={values}*/}
                                {/*        handleChange={handleChange}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                        }    
                        <Grid item xs={12} lg={5.8}>
                            <FormAutoComplete
                                name={"room_id"}
                                multiple={true}
                                options={clinicRoomsStore?.rooms ?? []}
                                values={values}
                                errors={errors} 
                                touched={touched}
                                label={'Assign Room'}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        {/* <Grid item xs={12} lg={5.8}>
                            <FormAutoComplete
                                name={"treatment_form_id"}
                                multiple={true}
                                options={clinicTreatmentFormStore?.treatmentForms}
                                values={values}
                                errors={errors}
                                touched={touched}
                                label={'Select Treatment Form'} 
                                setFieldValue={setFieldValue}
                            />  

                        </Grid> */}
                           <Grid item xs={12} lg={5.8}>
                            <FormAutoComplete   
                                name={"treatment_form_id"} 
                                multiple={true}  
                                options={clinicTreatmentFormStore?.treatmentForms ?? []}
                                values={values}        
                                errors={errors}    
                                touched={touched}
                                label={'Treatment Form'}     
                                setFieldValue={setFieldValue}  
                            />   
                        </Grid>        
                        <Grid item xs={12} lg={5.8}>                                 
                            <FormAutoParentChild
                                name={"category_ids"}
                                options={clinicTreatmentGroupData ?? []}   
                                values={values}    
                                multiple={true}          
                                errors={errors}
                                touched={touched}      
                                label={'Select Group /  Treatment'}       
                                setFieldValue={setFieldValue}       
                            />   
                        </Grid> 
                        <Grid item xs={12} lg={5.8}>  
                            <FormAutoComplete  
                                name={"staff_id"}
                                options={clinicStaffStore?.staffs ?? []}
                                values={values}
                                errors={errors}
                                multiple={true}
                                touched={touched}
                                label={'Staff Associated'}
                                setFieldValue={setFieldValue} 
                            />
                        </Grid>
                        <Grid item xs={12} lg={5.8}>
                            <FormDateInput
                                name={"service_schedule_date"}
                                label={"Service Schedule Date"}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        {values?.is_promo === 1 &&
                            <>
                            <Grid item xs={12} lg={5.8}>
                                <FormDateInput
                                    name={"promo_end_date"}
                                    label={"Promo End Date"}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5.8}>

                            </Grid>
                            </>
                        }
                        <Grid item xs={12} lg={4} xl={3.6}>
                            <FormStatusInput  
                                name={"is_free"}
                                label={"free services?"}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2.4}>
                            <FormStatusInput
                                name={"is_show_website"}
                                label={"Show Website"}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} xl={3.6}>
                            <FormStatusInput
                                name={"status"}
                                label={"Status"}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                                handleChange={handleChange}
                            />     
                        </Grid>     
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mb: "16px"}}>   
                                <FormLabel sx={{fontSize: "16px", color: '#27272e', letterSpacing: '.3px'}}>
                                    Service Description
                                </FormLabel>
                                <TextField
                                    minRows={6}
                                    maxRows={6}
                                    sx={inputStyle}
                                    name={"description"}
                                    multiline={true}
                                    value={values?.description}
                                    placeholder={'Type Service Description'}
                                    onChange={handleChange}
                                />
                                {errors?.description && touched?.description && <FormHelperText sx={{
                                    color: '#CB3838',
                                    width: 'max-content',
                                    mx: 0
                                }}>{errors?.description}</FormHelperText>}    

                            </FormControl>
                        </Grid>

                        <Grid item xs={5}>
                            <Image
                                label={'Image'}
                                name={"image"}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <Box sx={{mr: '16px'}}>
                                <CustomButton
                                    mainColor={'#ED1E45'}
                                    val={'Back'}
                                    handleClick={() => {
                                        navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${clinic_id}/services`)

                                    }}
                                />  
                            </Box>
                            <SubmitButton val={params?.service_id ? 'Update Service' : 'Add Service'}/>
                        </Grid>
                    </Grid> 
                </form>
    )
}

export default AddServices;