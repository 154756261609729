import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio, RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import CustomButton from "../../../../../components/custom-button/customButton";
import {storeAppointmentNotes} from "../../../../../store/crm/appointment/notes";
import {useDispatch, useSelector} from "react-redux";
import {successConfirmation} from "../../../../../services/utills";
import Loader from "../../../../../components/loader/loader";
import {getAppointmentDetails} from "../../../../../store/crm/appointment";
import {useParams} from "react-router-dom";
import {getStaff} from "../../../../../store/crm/staffs";   

import {storeCrmNotes} from "../../../../../store/crm/crm/crm-details";
import Waveloading from '../../../../loading/Waveloading';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const labelStyles ={
    color:'#000',
    fontSize:'16px',
    mb:'10px',
    "&.Mui-focused": {
        color:'#000!important',
    }
}

const CrmAddNotes = () => {
    const [selectedStaff,setSelectedStaff] = useState({})
    const [loading,setLoading] = useState(false)
    const [staffLoading,setStaffLoading] = useState(false) 
    const [comments,setComments] = useState('')
    const [type,setType] = useState('Staff')

    const dispatch = useDispatch()
    const {user_id} = useParams()

    const crmStaffStore = useSelector(state => state?.crmStaffsStore)
    const {crmStaffs = []} = crmStaffStore

    console.log("Crm Staff")
    console.log(crmStaffs)

    useEffect(()=>{
        (async ()=>{
            setStaffLoading(true)
            await dispatch(getStaff())
            setStaffLoading(false)
        })()
    },[])

    useEffect(() => {
        // Assuming you fetch your staff data here and set it to crmStaffs
        // After fetching, set the selectedStaff to the first item in the array
        if (crmStaffs && crmStaffs.length > 0) {
            setSelectedStaff(crmStaffs[0]);
        }
    }, [crmStaffs]);

    const handleSubmit = async () => {
        if (comments?.length > 3 && selectedStaff?.id){

            let finalData ={
                customer_id:user_id,
                staff_id:selectedStaff?.id,
                type:type,
                description:comments
            }
            setLoading(true)

            let res =await dispatch(storeCrmNotes(finalData))
            setLoading(false)
            if (res?.payload?.success === 'true'){
                setType('Staff')
                setSelectedStaff({})
                setComments('')
                await successConfirmation(`${type} Note Added Successfully`)
            }
        }
    }
    return (
        <Box
            sx={{
                background:"#fff",
                minHeight:'100px',
                borderRadius:'8px',
                p:{sm:'24px', xs:'8px'},
            }}
        >
            {loading ? <Waveloading fullSize/>:''}
            <Typography
                sx={{
                    fontFamily:'helvetica-world-bold',
                    fontSize:'20px',
                    mb:"24px",  
                }}
            >
                Add New Notes
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                    <FormControl fullWidth>
                        <FormLabel
                            sx={{...labelStyles}}
                        >
                            Staff Name
                        </FormLabel>
                        <Select
                            size={'small'}
                            value={selectedStaff?.id || ''}
                            displayEmpty
                            MenuProps={MenuProps}
                        >
                            {staffLoading ?
                                <MenuItem
                                    value={""}
                                >
                                    Please Wait. Loading...
                                </MenuItem> :
                                crmStaffs?.length > 0 && crmStaffs?.map((staff,key) => (
                                <MenuItem
                                    key={key}
                                    value={staff?.id}
                                    onClick={()=>setSelectedStaff(staff)}
                                >
                                    {staff?.name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Notes</FormLabel>
                        <RadioGroup
                            row
                            value={type}
                            onChange={(e)=>setType(e.target.value)}
                            sx={{
                                gap: {x:'3rem',lg:'5px', md:'3rem', sm:'2rem', xs:'1rem'},
                            }}
                        >
                            <FormControlLabel
                                value="Medical"
                                label="Medical"
                                control={<Radio  sx={{mr:"10px"}}/>}
                            />
                            <FormControlLabel
                                value="Staff"
                                label="Staff"
                                control={<Radio sx={{mr:"10px"}} />}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Notes</FormLabel>
                        {/* <TextField
                            size={'small'}
                            variant="outlined"
                            multiline
                           maxRows={3}
                            placeholder={""}
                            value={comments}
                            onChange={(e)=>setComments(e.target.value)}
                        /> */}
                         <textarea
            className="MuiInputBase-input MuiOutlinedInput-input"
            rows={3}
            placeholder=""
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            style={{
                width: '100%',
                resize: 'vertical', // Allow vertical resizing
                padding: '8px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                fontFamily: 'inherit', // Use the same font as Material-UI inputs
                fontSize: '1rem', // Use the same font size as Material-UI inputs
            }}
        />
                    </FormControl>
                </Grid>
                <Grid xs={12} item>
                    <CustomButton val={'Save Note'} handleClick={handleSubmit}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CrmAddNotes;