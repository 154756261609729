import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {MySwal, successConfirmation} from "../../../../services/utills";
import {
    Autocomplete,
    Box,
    Button,
    Card, Checkbox,  
    Divider, FormControl,
    FormControlLabel, FormLabel, Grid, IconButton,
    Input,
    InputAdornment, Modal,
    Radio,
    RadioGroup, TextField, Tooltip,
    Typography
} from "@mui/material"; 
import CloseIcon from '@mui/icons-material/Close';
import {  
    crmCreateAppointment,
    crmCreateCard, crmCreatePlanAppointment,
    crmGuestUser, 
    defaultUserCard,
    deleteUserCard, 
    getAllExistingUserCards,  
} from "../../../../store/crm/appointment/book-appointment"; 
import ClearIcon from '@mui/icons-material/Clear';
import Playon from '@mui/icons-material/PlayCircleOutline';   
import successIcon from "../../../../assets/icon/checkmark-circle.png";
import Loader from "../../../../components/loader/loader";
import {inputStyle, labelStyle} from "../../../../components/form/formInput";
import RestartAltIcon from "@mui/icons-material/RestartAlt";   
import arrow from "../../../../assets/icon/chevron-back-outline.png";
import UserCardsList from "../../appointment/add-appointment/payment/userCardsList";
import CardPayment from "../../appointment/add-appointment/payment/CardPayment";
import CustomButton from "../../../../components/custom-button/customButton";
import visa from "../../../../assets/icon/visa.png";
import {ReactComponent as Close} from "../../../../assets/icon/close-outline.svg";
import {ReactComponent as Braintree} from '../../../../assets/icon/braintree.svg'
import MenuItem from "@mui/material/MenuItem";
import { removeCard } from './addNewInvoice';   
import Waveloading from '../../../loading/Waveloading';
import moment from "moment/moment";
 

export const walletcontent ={
    fontSize:"14px",color:'#2F80ED',
    fontFamily:'helvetica-lt-std-bold',
    fontWeight:'700'
}
export const bookingcontent={
    fontSize:"14px",
    fontFamily:'helvetica-lt-std-bold',
    fontWeight:'700'
}
export const bookingwrapper={
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    flexWrap:'wrap',
    flexDirection:"row", 
    // '@media (max-width: 400px)': {
    //     flexDirection: 'column',
    //     justifyContent: 'center', 
    //     alignItems: 'flex-start', 
    //   },
}
export const treatmentcontent={
    fontSize:"18px",
    fontWeight:'400'
}

const modalStyle ={
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius:'5px',
    transform: 'translate(-50%, -50%)',
    width: 450,
    background: '#fff',
    p: '10px 24px 24px 24px',
    '@media  (max-width: 450px) ': {
        width:'95%',
        p: '10px 10px 24px 10px',
    },
}

const InvoicePayment = (props) => {
    const {
        selectedServices,
        selectedUser,
        setSelectedServices,
        selectedStaff,
        selectedFinalDate, 
        newUser,
        selectedUserNotes,
        userCards,
        bookingType,
        selectedPlanTime,
    } = props

    const [tip, setTip] = useState(0.0)
    const [loading, setLoading] = useState(false)
    const [discount, setDiscount] = useState(0.0)
    const [walletBalance, setWalletBalance] = useState(0.0)
    const [futurePayment, setFuturePayment] = useState('now')
    const [isDiscount, setIsDiscount] = useState('fix')
    const [paymentModal, setPaymentModal] = useState(null)
    const [allInvoices, setAllInvoices] = useState({})
    const [guestSelectedUser, setGuestSelectedUser] = useState(null)
    const [brainTree, setBrainTree] = useState(null)
    const [useCard, setUseCard] = useState(false);
    const [invoiceDate, setInvoiceDate] = useState('')
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const { selectedUserCard } = useSelector(state => state?.bookAppointmentStore)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedCard, setSelectedCard] = useState(null); 

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appointment_id} = useParams()
  
     
    const authStore = useSelector(state => state?.authStore)
    const { user } = authStore
    const inventoryStore = useSelector(state => state?.inventoryStore)
    useEffect(() => {
        const fetchExistingUserCards = async () => {  
          const customerId = selectedUser?.id 
         
          if (customerId) {
            const { payload } = await dispatch(getAllExistingUserCards(customerId))
          }
        };
    
        fetchExistingUserCards();
      }, [ dispatch]);
      const defaultusercard = async (cardId, event) => {
        const result = await MySwal.fire({
          title: 'Are you sure?', 
          text: "Do you want to make this card default?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, make it default'
        });
    
        if (result.isConfirmed) {
          setLoading(true);
    
          console.log("card data", selectedUserCard);
          const payload = {
            card_id: cardId,
            provider_id: user?.id,
            user_id: selectedUserCard?.data?.user?.id,
          };
          console.log("data", payload);
    
          try {  
            await dispatch(defaultUserCard(payload));
    
      
            setSelectedCard(event);
          } catch (error) {
            console.error("Error in defaultUserCard:", error);
      
          } finally {
            setLoading(false);
    
          }
        } else {
    
        }
      };
       
      const deleteusercard = async (cardId, event) => {
        const result = await MySwal.fire({
          title: 'Are you sure?',
          text: "Do you want to delete this card?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it'
        });
    
        if (result.isConfirmed) {
          setLoading(true);
    
          const customerId = selectedUser?.id ;
          const providerId = user?.id;
    
          if (!customerId || !providerId) {
            toast.error("Error: Missing customer or provider information.");
            setLoading(false);
            return;
          }
    
          console.log("card data", selectedUserCard?.data?.application_token);  
    
          const payload = {
            card_id: cardId,
            customer_id: customerId,
            provider_id: providerId,
          };
    
          console.log("data", payload);   
    
          try {
            await dispatch(deleteUserCard(payload));
          } catch (error) {
            console.error("Error in deleteUserCard:", error);
          } finally {
            try {
              const { payload } = await dispatch(getAllExistingUserCards(customerId));
              setUserCards(payload?.data?.cards);
            } catch (error) {
              console.error("Error in getAllExistingUserCards:", error);
            }
            setLoading(false);
          }
        }
      };
    
   
    const {provider_payment = [], booking_setting = {}} = user
    const {
        products = [],
    } = inventoryStore
   
    const isTaxPercentage = booking_setting?.default_tax_in_percentage === 1

    const [tax, setTax] = useState(booking_setting?.tax_booking_amount || 0)

    const handleRemoveService = (serviceToRemove) => {
        const updatedServices =  removeCard(selectedServices, serviceToRemove);
        setSelectedServices(updatedServices);
      };

    const handleCloseModal = (method) => { 
        setPaymentModal(null)
        if (method?.payment_method_id || method?.id) { 
            let obj = {...allInvoices}
            delete obj[method?.payment_method_id || method?.id]    
            setAllInvoices(obj)
        }
    }
    const isValidDate = (date) => {
        const dateFormat = 'YYYY-MM-DD';
        const parsedDate = moment(date, dateFormat, true);

        return parsedDate.isValid() && parsedDate.year() > 2000;
    }; 
    const handleUpdateQuantity = (service, type) => { 
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            let currentQuantity = +arr[index].extra_quantity || 1; // The || 0 handles undefined or null values
            if (type === 'add') {
                arr[index].extra_quantity = currentQuantity + 1;  
            } else if (currentQuantity > 1) {
                arr[index].extra_quantity = currentQuantity - 1;
            } 
            setSelectedServices(arr)
        }
    }
    const handleServicesQuantity = (e, service) => {
        let arr = [...selectedServices];
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {      
            const { value } = e?.target;
            if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                arr[index].extra_quantity = value;   
                setSelectedServices(arr);
            }
        }
    }
    const handleServicesQuantityBlur = (e, service) => {
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            const {value} = e?.target
            if (value === '') {
                arr[index].extra_quantity = 0
                setSelectedServices(arr)
            }
        }
    }
    const handleProductPrice = (e, service) => {
        let arr = [...selectedServices];
        let index = arr.findIndex(item => item.id === service?.id);

        if (index !== -1) {
            const { value } = e?.target;
            if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                // Create a new object for the updated service, updating selling_price
                let updatedService = {
                    ...arr[index],
                    product: {
                        ...arr[index].product,
                        selling_price: value
                    }
                };

                // Replace the old service object with the updated one
                 arr[index] = updatedService;

                setSelectedServices(arr);
            }else{
                let updatedService = { 
                    ...arr[index],  
                    product: { 
                        ...arr[index].product, 
                        selling_price: service?.regular_price
                    }  
                };
     
                // Replace the old service object with the updated one
                arr[index] = updatedService;

                setSelectedServices(arr);
            }
        } 
    }


    const handleProductPriceBlur = (e, service) => {
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            const {value} = e?.target
            if (value === '') {
                arr[index].product.selling_price = service?.regular_price
                setSelectedServices(arr)
            }
        }
    }
    const getServicePrice = (service) => {
        // If booking_cost is explicitly an empty string, return it directly.
        if (typeof service?.booking_cost === 'string') {
            return service.booking_cost.trim();   
        }
        // If the service is free, return "Free".
        if (service?.is_free === 1) {
            return "Free";
        }
        if(service?.booking_cost === 0){
            return service?.booking_cost
        }
        // Determine the final price based on whether the customer is a member or not.
        if (selectedUser?.is_membered === 1) {
            if(service?.extra_quantity != null){
                let finalPrice = ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }else{
                let finalPrice = parseFloat(service?.member_price) + ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }
        } else {
            if(service?.extra_quantity != null){
                let finalPrice = ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }else{
                let finalPrice = parseFloat(service?.regular_price) + ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }
        }    
    }   

    // const handleUpdatePrice = (e, service) => {  
    //     const {value} = e.target  
    //     let arr = [...selectedServices]
    //     let index = arr.findIndex(item => item.id === service?.id);
    //     if (index !== -1) {
    //         if (!isNaN(value) && value.trim() !== '') {
    //             const intValue = parseInt(value, 10);
    //             arr[index].booking_cost = intValue
    //             setSelectedServices(arr)
    //         } else if (value === '') { 
    //             arr[index].booking_cost = value
    //             setSelectedServices(arr)

    //         }

    //     }
    // }
    const handleUpdatePrice = (e, service) => {
        const { value } = e.target;  
        let arr = [...selectedServices];
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                arr[index].booking_cost = value; // Directly use the string value to preserve decimal points
                setSelectedServices(arr);
            }
        }
    }
    const getSubTotal = () => {
        let total = 0
        selectedServices.forEach((item) => {
            if (item?.is_free === 0 || item?.booking_cost !== null) {
                if (item?.booking_cost !== null) {
                    if(item?.extra_quantity == null || item?.extra_quantity == undefined){
                        total = total + ((parseFloat(item?.booking_cost) || 0) + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0)))
                    }else{
                        total = total + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0))
                    }
                } else {  
                    if (item?.extra_quantity > 0){
                        total = total +  ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0))
                    }else{
                        if (selectedUser?.id && selectedUser?.is_membered === 1) {
                            total = total + (parseFloat(item?.member_price) + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0)))
                        } else {
                            total = total + (parseFloat(item?.regular_price) + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0)))
                        }
                    }  
                }  
            }
        })

        return parseFloat(total).toFixed(2)
    }
    const getTotalTex = () => {
        let total = getSubTotal()
        total = total - discount
        if (booking_setting?.id) {
            // let taxAmount = tax > 0 ? parseFloat(tax) : parseFloat(booking_setting?.tax_booking_amount)
            let taxAmount =   parseFloat(tax)
            if (isTaxPercentage) {
                return parseFloat((((total / 100) * taxAmount)|| 0).toFixed(2))
            } else {
                return parseFloat(taxAmount || 0).toFixed(2)
            }
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }

    const getTotalDiscount = () => {
        if (discount) {
            if (isDiscount === 'fix') {
                return parseFloat(discount).toFixed(2)
            } else {
                return parseFloat(((getSubTotal() / 100) * discount)).toFixed(2)
            }
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }


    const getTotalTip = () => {
        if (tip > 0) {
            return parseFloat(tip).toFixed(2)
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }
    const getWalletBalance = () => {
        if (walletBalance > 0) {
            return parseFloat(walletBalance).toFixed(2)
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }

    const handleTaxChange = (e) => {

        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {   
            const intValue = parseInt(inputValue, 10);
            if (parseFloat(intValue) <= (parseFloat(booking_setting?.tax_booking_amount)+1)) {
                setTax(intValue)
            }
        } else if (inputValue === '') {
            setTax(inputValue)
        }
    }
    const handleDiscountChange = (e) => {   

        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            const intValue = parseInt(inputValue, 10);
            // if (intValue <= parseFloat(getGrandTotal())){   
            setDiscount(intValue)
            // }
        } else if (inputValue === '') {
            setDiscount(inputValue)

        }
    }
    const handleTipChange = (e) => {
        
        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            const intValue = parseInt(inputValue, 10);  
            // if (intValue <= parseFloat(getGrandTotal())){   
            setTip(intValue)
            // }
        } else if (inputValue === '') {
            setTip(inputValue)

        }
    }
    const getGrandTotal = () => {
        return parseFloat(parseFloat(getSubTotal()) + parseFloat(getTotalTex()) + parseFloat(getTotalTip()) - parseFloat(getTotalDiscount())).toFixed(2)
    }
    const getTotalPaidAmount = () => {     
        let amount = 0

        return (parseFloat(amount)).toFixed(2)
    }

    const totalDue = () => {
        let grandFinal = parseFloat(getGrandTotal())

        if (Object.entries(allInvoices)?.length > 0) {
            Object.entries(allInvoices).forEach(([key, value], index) => {
                if (value?.amount) {
                    let amount = parseFloat(value?.amount)
                    grandFinal = grandFinal - amount
                }
            })

            return (parseFloat(grandFinal)).toFixed(2)

        } else {

            return (parseFloat(getGrandTotal())).toFixed(2)

        }
    }   


    const handleWalletChange = async (e) => {
   
        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {  
            const intValue = parseInt(inputValue, 10);
            if (intValue <= parseFloat(selectedUser?.wallet_point)) {
                setWalletBalance(intValue)      
                // await handleInputChange(intValue,method);  
            }
        } else if (inputValue === '') {
            setWalletBalance(inputValue)
            // await handleInputChange(inputValue,method);
        }
    }//
    const handleClickMethod = async (method, e) => {
        const {checked} = e?.target  
        if (checked) {
            if (parseFloat(totalDue()) >= 0) { 
                if (method?.id === 2) {
                    /********** Wallet *************/     
                    let value = 0
                    let walletBalance = parseFloat(selectedUser?.wallet_point)
                    let total = parseFloat(totalDue())
                    if (total >= walletBalance) {
                        value = walletBalance  
                    } else {  
                        value = total  
                    }
                    setWalletBalance(value)
                    await handleInputChange(value, method)
                    // selectedUser?.id && parseFloat(selectedUser?.wallet_point) > 0
                } else {
                    if (method?.labels?.length) {
                        const labels = method?.labels
                        let finalObj = {   
                            payment_method_id: method?.id,   
                            name: method?.name
                        }
                        labels.forEach((label) => { 
                            if (label.toLowerCase() === 'amount') { 
                                finalObj['amount'] = totalDue()
                            } else {
                                finalObj[label] = ""
                            }
                        })
                        setAllInvoices(prevState => {
        
                            if (prevState[method?.id]) {
                                return {
                                    ...prevState,           
                                    [method?.id]: finalObj
                                };
                            } else {
                                return {
                                    ...prevState,       
                                    [method?.id]: finalObj
                                };
                            }
                        });
                    }
                    setPaymentModal(method?.id)
                }
            }
        } else {
            let confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{mb: 4}}>
                    <Typography sx={{}}>
                        Are you sure? You don't want to use this Method.
                    </Typography>
                    <Box sx={{mt: 3,}}>
                        <Button  
                            sx={{
                                backgroundColor: "#fff",  
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',  
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        > 
                            Yes  
                        </Button>    
                        <Button  
                            sx={{    
                                backgroundColor: "#fff",
                                minWidth: "max-content", 
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff", 
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}  
                        >
                            Cancel
                        </Button>
                        {/*{method?.id !== 2 && <Button*/}
                        {/*    sx={{*/}
                        {/*        backgroundColor: "#fff",*/}   
                        {/*        minWidth: "max-content",*/}
                        {/*        border: "1px solid #666666",*/}    
                        {/*        borderRadius: '10px',*/} 
                        {/*        px: '10px',*/}  
                        {/*        mx: '10px',*/}
                        {/*        fontSize: '16px',*/}
                        {/*        color: "#666666",*/}
                        {/*        "&:hover": {*/}
                        {/*            backgroundColor: "#fff",*/}
                        {/*            border: "1px solid #666666",*/}
                        {/*        },*/}
                        {/*    }}*/}
                        {/*    onClick={() => MySwal.clickDeny()}*/}
                        {/*>*/}
                        {/*    Change Amount*/}  
                        {/*</Button>}*/}
                    </Box> 
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                let obj = {...allInvoices}
                delete obj[method?.id || method?.payment_method_id]
                setAllInvoices(obj)
            } else if (confirm?.isDenied) {
                // setPaymentModal(method?.id)
            }
        }

    }

    const handleInputChange = async (value, method, isClose) => {
        if (parseFloat(value) <= parseFloat(totalDue())) {  
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    return {
                        ...prevState,
                        [method?.id]: {amount: value, payment_method_id: method?.id, name: method?.name}
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: {amount: value, payment_method_id: method?.id, name: method?.name}
                    };       
                }            
            });      
            if (isClose) {      
                handleCloseModal()         
            }
        } else {  
            await successConfirmation("Warning", "Amount Increase", "warning")
        }
    }

    
    const handleSaveWallet = async (value, method) => {
        if (parseFloat(value) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.payment_method_id]) {
                    return {
                        ...prevState,
                        [method?.payment_method_id]: {
                            amount: value,
                            payment_method_id: method?.payment_method_id,
                            name: method?.name
                        }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.payment_method_id]: {
                            amount: value,
                            payment_method_id: method?.payment_method_id,
                            name: method?.name
                        }
                    };
                }
            });
        } else if (parseFloat(value) > parseFloat(totalDue())) {
            if (parseFloat(value) <= (parseFloat(allInvoices[method?.payment_method_id]?.amount) + parseFloat(totalDue()))) {
                setAllInvoices(prevState => {
                    if (prevState[method?.payment_method_id]) {
                        return {
                            ...prevState,
                            [method?.payment_method_id]: {
                                amount: value,
                                payment_method_id: method?.payment_method_id,
                                name: method?.name
                            }
                        };
                    } else {
                        return {
                            ...prevState, 
                            [method?.payment_method_id]: {
                                amount: value,
                                payment_method_id: method?.payment_method_id,
                                name: method?.name
                            }
                        };
                    }
                });
            } else {
                await successConfirmation("Warning", "Amounts Increased", "warning")
                setWalletBalance(allInvoices[method?.payment_method_id]?.amount)

            }

        } else if (value === '' || value === 0) {
            let confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{mb: 4}}>
                    <Typography sx={{}}>
                        Are you sure? you don't want to use Wallet Balance?
                    </Typography>
                    <Box sx={{mt: 3,}}>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        >
                            Yes
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                handleCloseModal(method)
            } else {
                setWalletBalance(allInvoices[method?.payment_method_id]?.amount)
            }
        }
    }
    const handleChangeOption = async (e) => {
        const value = e?.target?.value
        if (parseFloat(getGrandTotal()) >= 0) { 
            if (value === 'future') {
                if (Object.entries(allInvoices)?.length > 0) {
                    let confirm = await MySwal.fire({
                        icon: 'question',
                        title: 'Confirmation',
                        showConfirmButton: false,
                        html: <Box sx={{mb: 4}}>
                            <Typography sx={{}}>
                                Are you sure? You want to do Future Payment. Your selected method will Reset
                            </Typography>
                            <Box sx={{mt: 3,}}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        px: '20px',
                                        mx: '10px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.clickConfirm()}
                                >
                                    Yes
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        px: '10px',
                                        mx: '10px',
                                        fontSize: '16px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.close()}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    }) 
                    if (!!confirm?.isConfirmed) {
                        setFuturePayment(e.target.value)
                        setAllInvoices({
                            "1": {
                                amount: parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount()),
                                payment_method_id: 1,
                                name: "Future Payment"
                            }
                        })

                    }
                } else {
                    setFuturePayment(e.target.value)
                    setAllInvoices({"1": {amount: getGrandTotal(), payment_method_id: 1, name: "Future Payment"}})

                }
            } else {
                setFuturePayment(e.target.value)
                setAllInvoices({})
            }
        }
    }

    const handleBrainTree = async (data, method) => {
        setBrainTree(data)
        await handleInputChange(data?.amount, method, true)

    }
    const handleSquare = async (token, amount, method) => {
        // setSquareToken(token)
        if (parseFloat(amount) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    return {
                        ...prevState,
                        [method?.id]: {amount: amount, payment_method_id: method?.id, name: method?.name, token: token}
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: {amount: amount, payment_method_id: method?.id, name: method?.name, token: token}
                    };
                }
            });
            handleCloseModal()
  
        } else {
            await successConfirmation("Warning", "Amount Increase", "warning")
        }
        // await handleInputChange(amount,method,true)

    }
    const handleLabelsInputChange = async (value, target, method, bookingType) => {
        if (target === 'Amount' || target === 'amount') {
            if (!isNaN(value) && value.trim() !== '') {
                const floatValue = parseFloat(value);
                // if (parseFloat(totalDue()) === 0 || parseFloat(totalDue()) > floatValue){
                // if (floatValue <= parseFloat(floatValue)) {
                setAllInvoices(prevState => {
                    if (prevState[method?.id]) {
                        let prevMethod = prevState[method?.id] || {};
                        return {
                            ...prevState,
                            [method?.id]: { ...prevMethod, [target]: floatValue }
                        };
                    }
                })
                // }
                // }
            } else if (value === '') {
                setAllInvoices(prevState => {
                    if (prevState[method?.id]) {
                        let prevMethod = prevState[method?.id] || {};
                        return {
                            ...prevState,
                            [method?.id]: { ...prevMethod, [target]: value }
                        };
                    }
                })
            }
        } else {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    let prevMethod = prevState[method?.id] || {};
                    return {
                        ...prevState,
                        [method?.id]: { ...prevMethod, [target]: value }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: { amount: value, payment_method_id: method?.id, name: method?.name }
                    };
                }
            });
        }
    }

    const handleConfirm = async (invoice, method) => {
        let errorMessage = '';
        let isValuesMissing = false;
    
        // Check if all required fields are filled
        Object.entries(invoice).forEach(([key, value]) => {
            if (key !== 'name' && key !== "payment_method_id" && !value.toString().length) {
                isValuesMissing = true;
                errorMessage = 'All fields required.';
            }
        });
     
        // Specific check for E-Transfer/CC (External Device) method
        if (method?.name === "E-Transfer/CC (External Device)") {
            // Check if transaction ID is provided for E-Transfer/CC
            if (!invoice.transactionId || invoice.transactionId.trim() === '') {
                isValuesMissing = true;
                errorMessage = 'Transaction ID field is required.';
            }
        }
    
        // Perform the rest of the validation only if all required fields are filled
        if (!isValuesMissing) {
            if (parseFloat(invoice?.amount) >= 0) {
                // Your amount validation logic here
                if (parseFloat(totalDue()) === 0 || parseFloat(invoice?.amount) <= (parseFloat(totalDue()) + parseFloat(invoice?.amount))) {
                    handleCloseModal();
                } else {
                    errorMessage = 'Invalid Amount.';
                }
            } else {
                errorMessage = 'Amount field is required.';
            }
        }
    
        // Show error message if any validation failed
        if (errorMessage) {
            await successConfirmation("Error", errorMessage, 'error');
            // Prevent modal from closing if there's an error
            return;
        }
    };
    const handleClick = () => {
        if (invoiceDate) {
          setError('');
          handleBookAppointment();
        } else {
          setError('Invoice Date is required');
        }
      }; 
    
    const handleBookAppointment = async () => {

        if (parseFloat(totalDue()) === 0) { 

            setLoading(true)  
            let userId = null

            if (selectedUser?.id) {
                userId = selectedUser?.id
            } else if (guestSelectedUser?.id) {
                userId = guestSelectedUser?.id
            } else {

                let registerPayload = {
                    name: newUser?.name,
                    email: newUser?.email,
                    phone: newUser?.phone,
                    social_login: 0,
                    is_guest: 1,
                    postal_code: "none",
                    password: "3Dlifestyle!",
                }
                let registerUser = await dispatch(crmGuestUser(registerPayload))
                if (registerUser?.payload?.data?.id) {
                    setGuestSelectedUser(registerUser?.payload?.data)
                    userId = registerUser?.payload?.data?.id
                }
            }

            let notes = {}
            if (selectedUser?.id) {
                notes['note_by'] = selectedUserNotes?.note_by?.id
                if (selectedUserNotes?.staff_notes) {
                    notes['staff_notes'] = selectedUserNotes?.staff_notes
                }
                if (selectedUserNotes?.medical_notes) {
                    notes['medical_notes'] = selectedUserNotes?.medical_notes
                }
            } else {
                notes['note_by'] = newUser?.note_by?.id
                if (newUser?.staff_notes) {
                    notes['staff_notes'] = newUser?.staff_notes
                }
                if (newUser?.medical_notes) {
                    notes['medical_notes'] = newUser?.medical_notes
                }
            }

            let finalInvoices = []
            finalInvoices = Object.values(allInvoices);
            if (futurePayment === 'now') {

                // if ( allInvoices['4'] && userId){
                //     console.log(brainTree)
                //     let finalData ={
                //         user_id : userId,   
                //         nonce : squareToken,  
                //     }
                //
                //
                //     let res = await dispatch(crmCreateCard(finalData))
                // }else
                if (allInvoices['3'] && userId) {
 
                    let finalData = {
                        user_id: userId,
                        number: brainTree?.number,   
                        expirationDate: brainTree?.expirationDate,
                        cvv: brainTree?.cvv, 
                    }

                    if (brainTree?.token) {
                        let idToUpdate = 3;
                        let newProperty = {"token": brainTree?.token};

                        finalInvoices = finalInvoices.map(item => {
                            if (item.payment_method_id === idToUpdate) {
                                return {...item, ...newProperty};
                            }
                            return item;
                        });
                    } else {


                        let res = await dispatch(crmCreateCard(finalData))

                        if (res?.payload?.data?.token) {

                            let idToUpdate = 3;
                            let newProperty = {"token": res?.payload?.data?.token};

                            finalInvoices = finalInvoices.map(item => {
                                if (item.payment_method_id === idToUpdate) {
                                    return {...item, ...newProperty};
                                }
                                return item;
                            });
                        }
                    }
                }
            }

            if (bookingType === 'plan') {
                let planFinalPayload = {
                    user_id: userId,
                    plan_id: selectedServices[0],
                    isDiscountInPercentage: !!isDiscount === 'percentage',
                    notes: notes,
                    discount: getTotalDiscount(),
                    tax: getTotalTex(),
                    tip: getTotalTip(),
                    invoices: finalInvoices,
                    bookings:selectedPlanTime

                }

                if (selectedStaff?.id) {
                    planFinalPayload['staff_id'] = selectedStaff?.id
                }
                let planResult = await dispatch(crmCreatePlanAppointment(planFinalPayload))


                setLoading(false)
                if (planResult?.payload?.success === 'true') {
                    await MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        showConfirmButton: false,
                        html: <Box sx={{mb: 4}}>
                            <Typography>
                                    Invoice Created Successfully
                                </Typography>
                            <Box sx={{mt: 3,}}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        px: '30px',
                                        mx: '10px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.close()}
                                >
                                    OK
                                </Button>
                            </Box>
                        </Box> 
                    })
                    navigate(`/crm/point-of-sale`)
                } else {
                    console.log(planResult?.payload)
                    const errors = planResult?.payload?.errors
                    if (errors?.length > 0) {
                        await MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            showConfirmButton: false,
                            html: <Box sx={{mb: 4}}>
                                {errors.map((item, index) => (
                                    <Typography key={index}>
                                        {item}
                                    </Typography>
                                ))}
                                <Box sx={{mt: 3,}}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#fff",
                                            minWidth: "max-content",
                                            border: "1px solid #666666", 
                                            borderRadius: '10px',
                                            fontSize: '16px',
                                            px: '30px',
                                            mx: '10px',
                                            color: "#666666",
                                            "&:hover": {
                                                backgroundColor: "#fff",
                                                border: "1px solid #666666",
                                            },
                                        }}
                                        onClick={() => MySwal.close()}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Box>
                        })
                    }
                }
            } else {
                let finalPayload = {
                    service_ids: selectedServices,
                    user_id: userId,
                    isDiscountInPercentage: !!isDiscount === 'percentage',
                    notes: notes,
                    type:'Pos',
                    date: invoiceDate !== '' ? invoiceDate : selectedFinalDate,
                    discount: getTotalDiscount(),
                    tax: getTotalTex(),
                    tip: getTotalTip(),
                    invoices: finalInvoices,
                }

                if (selectedStaff?.id) {
                    finalPayload['staff_id'] = selectedStaff?.id
                }

                let appointmentResult = await dispatch(crmCreateAppointment(finalPayload))


                setLoading(false)

                if (appointmentResult?.payload?.success === 'true') {
                    await MySwal.fire({
                        icon: 'success',
                        title: 'Success',
                        showConfirmButton: false,
                        html: <Box sx={{mb: 4}}>
                            <Typography >
                                Invoice Created Successfully
                            </Typography>
                            <Box sx={{mt: 3,}}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        px: '30px',
                                        mx: '10px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.close()}
                                >
                                    OK
                                </Button>
                            </Box>
                        </Box>
                    })
                    navigate(`/crm/invoices/${appointmentResult?.payload?.data?.id}`)
                } 
                else {
                    const errors = appointmentResult?.payload?.errors
                    if (errors?.length > 0) {
                        await MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            showConfirmButton: false,
                            html: <Box sx={{mb: 4}}>
                                {errors.map((item, index) => (
                                    <Typography key={index}>
                                        {item}
                                    </Typography>
                                ))} 
                                <Box sx={{mt: 3,}}>
                                    <Button
                                        sx={{
                                            backgroundColor: "#fff",
                                            minWidth: "max-content",
                                            border: "1px solid #666666",
                                            borderRadius: '10px',
                                            fontSize: '16px',
                                            px: '30px',
                                            mx: '10px',
                                            color: "#666666",
                                            "&:hover": {
                                                backgroundColor: "#fff",
                                                border: "1px solid #666666",
                                            },
                                        }}
                                        onClick={() => MySwal.close()}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Box>
                        })
                    } 
                }
            }
        } else {
            await successConfirmation('Invalid', 'Invalid amount or something went wrong.Please Review all prices again or reset payment ways', 'error')
            // setAllInvoices({})
            // setFuturePayment('reset')
        }  
    }


    return (
        <Box>  
            {loading ? <Waveloading fullSize/> : ""}
            <Card sx={{mt: 4, p: 2}}>
                <Box sx={bookingwrapper}>
                    <Typography sx={{boxShadow: 'lg', ...bookingcontent,textTransform:'capitalize'}}>
                        {bookingType} Booking Details
                    </Typography>
                    {selectedUser?.id &&
                        <Typography sx={walletcontent}>
                            Wallet Balance: ${selectedUser?.wallet_point || "0.00"}
                        </Typography>
                    }
                </Box>
                {selectedServices?.length > 0 ?
                    <Box sx={{background: "#EDF2F7", mt: 2, p: '12px', borderRadius: '4px'}}>
                        {
                            selectedServices.map((service, index) => (
                                <Card sx={{mb: 2, p: 2}} key={index}>
                                    <Box sx={{...bookingwrapper, alignItems: 'center', flexWrap:'nowrap'}}>
                                        <Box sx={{display: 'flex', gap: '5px'}}>
                                            {/*{service?.qty_enable === 1 &&*/}
                                            {/*    <Box sx={{display: 'flex', gap: '5px'}}>*/}
                                            {/*        <Box*/}
                                            {/*            sx={{*/}
                                            {/*                backgroundColor: '#EFEDED',*/}
                                            {/*                padding: '4px 12px',*/}
                                            {/*                height: '34px',*/}
                                            {/*                borderRadius: '4px',*/}
                                            {/*                cursor: 'pointer',*/}
                                            {/*            }}*/}
                                            {/*            onClick={() => handleUpdateQuantity(service, 'add')}*/}
                                            {/*        >*/}
                                            {/*            <Typography sx={{alignItems: 'center'}}>+</Typography>*/}
                                            {/*        </Box>*/}
                                            {/*        <Box>*/}
                                            {/*            <Input*/}
                                            {/*                sx={{*/}
                                            {/*                    ...inputStyle,*/}
                                            {/*                    width: '50px',*/}
                                            {/*                    p: '5px',*/}
                                            {/*                    ".MuiInputBase-input": {*/}
                                            {/*                        height: "14px",*/}
                                            {/*                    },*/}
                                            {/*                    '&::before': {*/}
                                            {/*                        content: 'none',*/}
                                            {/*                    },*/}
                                            {/*                }}*/}
                                            {/*                value={service?.service_quantity}*/}
                                            {/*                onChange={(e) => handleServicesQuantity(e, service)}*/}
                                            {/*                onBlur={(e) => handleServicesQuantityBlur(e, service)}*/}
                                            {/*            />*/}
                                            {/*        </Box>*/}
                                            {/*        <Box*/}
                                            {/*            sx={{*/}
                                            {/*                backgroundColor: '#EFEDED',*/}
                                            {/*                padding: '4px 14px',*/}
                                            {/*                height: '34px',*/}
                                            {/*                borderRadius: '4px',*/}
                                            {/*                cursor: service?.service_quantity === 1 ? 'not-allowed' : 'pointer',*/}
                                            {/*            }}*/}
                                            {/*            onClick={() => service?.service_quantity > 1 && handleUpdateQuantity(service, 'remove')}*/}
                                            {/*        >*/}
                                            {/*            <Typography sx={{alignItems: 'center'}}>-</Typography>*/}
                                            {/*        </Box>*/}
                                            {/*    </Box>*/}
                                            {/*}*/}
                                            <Typography
                                                sx={{
                                                    ...treatmentcontent,
                                                    // ml:service?.qty_enable === 1 ? '16px' : ''
                                                }}
                                            >
                                                {service?.name || service?.plan_name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Input
                                                startAdornment={
                                                    <InputAdornment
                                                        position="start"
                                                    >
                                                        {service?.is_free === 1 ? "" : "$"}
                                                    </InputAdornment>
                                                }
                                                sx={{
                                                    ...inputStyle,
                                                    width: '80px',
                                                    p: '5px',
                                                    '&::before': {   
                                                        content: 'none',
                                                    },
                                                }}
                                                value={getServicePrice(service)}
                                                onChange={(e) => handleUpdatePrice(e, service)}
                                            />
                                        </Box>
                                        <ClearIcon style={{color:"red"}} onClick={() => handleRemoveService(service)} />
                                    </Box>
                                    {bookingType === 'service' && service?.qty_enable === 1 &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '20px',
                                                flexWrap:'wrap'
                                            }}
                                        >
                                            <Autocomplete
                                                sx={{  
                                                    mt: 2,
                                                    width: '150px',    
                                                    minWidth: '150px',
                                                }}
                                                options={products}
                                                value={service?.product}
                                                disableCloseOnSelect={false}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option?.id === value?.id
                                                }}
                                                getOptionLabel={(option) => option?.name || ''}
                                                renderInput={(params) => {
                                                    const {inputProps, helperText, error, ...rest} = params;
                                                    return (
                                                        <TextField
                                                            {...rest}
                                                            inputProps={{...inputProps}}
                                                            placeholder={"Select Product"}
                                                            sx={{...inputStyle}}
                                                        />
                                                    )
                                                }}
                                                onChange={(event, newValue) => {
                                                    let arr = [...selectedServices]
                                                    let index = arr.findIndex(item => item.id === service?.id);
                                                    if (index !== -1) {
                                                        arr[index].product = newValue
                                                        arr[index].extra_quantity = 0
                                                        setSelectedServices(arr)
                                                    }
                                                }}
                                                renderOption={(props, option, {selected}) => (
                                                    <MenuItem  {...props} key={option?.id}>
                                                        <Checkbox
                                                            style={{marginRight: 8}}
                                                            checked={selected}
                                                        />
                                                        {option?.name}
                                                    </MenuItem>
                                                )}
                                            />
                                            {service?.product?.id && <Box
                                                sx={{
                                                    display:'flex',
                                                    justifyContent:'space-between',
                                                    alignItems:'flex-end',
                                                    width:"100%",
                                                }}
                                            >
                                                <Box>
                                                    <Typography>Quantity</Typography>
                                                    <Box sx={{display: 'flex', gap: '5px'}}>
                                                        <Box
                                                            sx={{
                                                                backgroundColor: '#EFEDED',
                                                                padding: '4px 12px',
                                                              height: '34px',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleUpdateQuantity(service, 'sub')}
                                                        >
                                                            <Typography sx={{alignItems: 'center'}}>-</Typography>
                                                        </Box> 
                                                        <Box>
                                                            <Input
                                                                type='text'
                                                                sx={{
                                                                    ...inputStyle,
                                                                    width: '50px',
                                                                    p: '5px',
                                                                    ".MuiInputBase-input": {
                                                                        height: "14px",
                                                                    },
                                                                    '&::before': {
                                                                        content: 'none',
                                                                    },
                                                                }}
                                                                value={service?.extra_quantity || 1}
                                                                onChange={(e) => handleServicesQuantity(e, service)}
                                                                onBlur={(e) => handleServicesQuantityBlur(e, service)}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                backgroundColor: '#EFEDED',
                                                                padding: '4px 12px',
                                                                height: '34px',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleUpdateQuantity(service, 'add')}
                                                        >
                                                            <Typography sx={{alignItems: 'center'}}>+</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Typography>Unit Price</Typography>
                                                    <Box sx={{ display: 'flex', gap: '5px' }}>
                                                        <Input
                                                            type='text'
                                                            sx={{
                                                                ...inputStyle,
                                                                width: '50px',
                                                                p: '5px',
                                                                ".MuiInputBase-input": {
                                                                    height: "14px",
                                                                },
                                                                '&::before': {
                                                                    content: 'none',
                                                                },
                                                            }}
                                                            value={service?.product?.selling_price}
                                                            onChange={(e) => handleProductPrice(e, service)}
                                                            onBlur={(e) => handleProductPriceBlur(e, service)}
                                                        />
                                                        {/*
                                                           // <Typography>× {service?.product?.selling_price}</Typography>
*/}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Input
                                                        type='text'
                                                        startAdornment={
                                                            <InputAdornment
                                                                position="start"
                                                            >
                                                                {"$"}
                                                            </InputAdornment>
                                                        }
                                                        sx={{
                                                            ...inputStyle,
                                                            width: '80px',
                                                            p: '5px',
                                                            '&::before': {
                                                                content: 'none',
                                                            },
                                                        }}
                                                        disabled
                                                        value={((parseFloat(service?.extra_quantity) || 1) * (parseFloat(service?.product?.selling_price) || 0))}
                                                        // onChange={(e) => handleUpdatePrice(e, service)}F
                                                    />
                                                </Box>
                                            </Box>}
                                        </Box>
                                    }
                                </Card>
                            ))}
                        <Card sx={{p: 2}}> 
                            <div style={{display: 'flex', flexDirection: 'column', gap: '14px'}}>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={{fontFamily: 'helvetica-lt-std-bold'}}>Sub Total</Typography>
                                    <Typography sx={{fontFamily: 'helvetica-lt-std-bold'}}>${getSubTotal()}</Typography>
                                </Box>
                                <Box sx={bookingwrapper}>
                                    <div style={{display: "flex", alignItems: 'center', gap: '40px', }}>
                                        <Typography>Discount</Typography>
                                        <RadioGroup
                                            row
                                            value={isDiscount}
                                            onChange={(e) => setIsDiscount(e?.target?.value)}
                                        >
                                            <FormControlLabel
                                                value="fix"
                                                size="small"
                                                control={<Radio/>}
                                                label="Fix"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}   
                                            />
                                            <FormControlLabel
                                                value="percentage"
                                                size="small"
                                                control={<Radio/>}
                                                label="%"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />
                                        </RadioGroup>
                                    </div>
                                    <Box sx={{display: 'flex', alignItems: "center", gap: '20px',
                                        '@media (max-width: 468px)' :{
                                            justifyContent:'end',
                                            width:'100%'
                                        }}}>
                                        <Input
                                            startAdornment={<InputAdornment position="start">
                                                {isDiscount === 'fix' ? "$" : ""}
                                            </InputAdornment>}
                                            endAdornment={<InputAdornment position="end">
                                                {isDiscount !== 'fix' ? "%" : ""}
                                            </InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                maxWidth: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={discount}
                                            onChange={(e) => handleDiscountChange(e)}
                                        />
                                        <Typography
                                            sx={bookingwrapper}
                                        >
                                            {isDiscount === 'fix' ? "$" : ""}{getTotalDiscount()}{isDiscount !== 'fix' ? "%" : ""}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Tax {isTaxPercentage ? "in %" : ""}</Typography>
                                    <Box sx={{display: 'flex', alignItems: "center", gap: '20px'}}>
                                        <Input  
                                            startAdornment={<InputAdornment position="start">
                                                {isTaxPercentage ? "" : "$"}
                                            </InputAdornment>}
                                            endAdornment={<InputAdornment position="end">
                                                {isTaxPercentage ? "%" : ""}
                                            </InputAdornment>}                                          
                                            sx={{
                                                ...inputStyle,
                                                width: '80px', 
                                                p: '5px',
                                                '&::before': {
                                                    cont0ent: 'none',
                                                },
                                            }}
                                            value={tax}
                                            onChange={handleTaxChange}
                                        />
                                        <Typography sx={bookingwrapper}>${getTotalTex()}</Typography>
                                    </Box>
                                </Box>

                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Tip</Typography>
                                    <Box sx={{display: 'flex', alignItems: "center", gap: '20px'}}>
                                        <Input
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                width: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={tip}
                                            onChange={(e) => handleTipChange(e)}
                                        />
                                        <Typography sx={bookingwrapper}>${getTotalTip()}</Typography>
                                    </Box>
                                </Box>

                                <Divider/>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Grand Total</Typography>
                                    <Typography sx={{
                                        fontSize: "22px",
                                        fontFamily: 'helvetica-lt-std-bold',
                                        fontWeight: '700'
                                    }}>
                                        ${getGrandTotal()}
                                    </Typography>
                                </Box>

                                {Object.entries(allInvoices)?.length > 0 &&
                                    Object.entries(allInvoices).map(([key, value], index) => (
                                        <Box sx={bookingwrapper} key={index}>
                                            <Typography sx={bookingwrapper}>{value?.name}</Typography>
                                            <Box sx={{display: 'flex', alignItems: "center", gap: '20px'}}>
                                                {value?.payment_method_id === 2 && <Input
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    sx={{
                                                        ...inputStyle,
                                                        width: '80px',
                                                        p: '5px',
                                                        '&::before': {
                                                            content: 'none',
                                                        },   
                                                    }}
                                                    onBlur={() => handleSaveWallet(walletBalance, value)}
                                                    value={walletBalance}
                                                    onChange={(e) => handleWalletChange(e)}
                                                />}
                                                <Typography sx={bookingwrapper}>-${value?.amount}</Typography>
                                            </Box>
                                        </Box>
                                    ))
                                }
                                <Divider/>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={{...bookingwrapper, fontFamily: 'helvetica-lt-std-bold'}}>
                                        Due Amount
                                    </Typography>
                                    <Typography sx={{
                                        ...bookingwrapper,
                                        fontFamily: 'helvetica-lt-std-bold'
                                    }}>${totalDue()}</Typography>
                                </Box>
                            </div>
                        </Card>
                        {(bookingType ==='service')|| (bookingType ==='plan' && selectedPlanTime?.length > 0 ) ? (
                            <Card sx={{mt: 2, minHeight: '100px'}}>
                                <Box
                                    sx={{
                                        px: 2,
                                        pt: 2,
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <FormControl>
                                        <FormLabel sx={{...labelStyle, mb: '5px'}}>Choose One Option:</FormLabel>
                                        <RadioGroup
                                            defaultValue="now"
                                            row
                                            value={futurePayment}
                                            onChange={handleChangeOption}
                                            sx={{gap: '40px'}}
                                        >
                                            <FormControlLabel
                                                value="now"
                                                size="small"
                                                control={<Radio/>}
                                                label="Pay Now"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 20,
                                                    },
                                                }}
                                            />
                                            {/*<FormControlLabel*/}
                                            {/*    value="future"*/}
                                            {/*    size="small"*/}
                                            {/*    control={<Radio/>}*/}
                                            {/*    label="Future Payment"*/}
                                            {/*    sx={{*/}
                                            {/*        '& .MuiSvgIcon-root': {*/}
                                            {/*            fontSize: 20,*/}
                                            {/*        },*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </RadioGroup>
                                    </FormControl>

                                    {Object.entries(allInvoices)?.length > 0 && futurePayment !== 'future' && <Box
                                        sx={{
                                            mt: '-5px',
                                            mr: '-5px',  
                                        }}
                                    >
                                        <Tooltip title={"Reset Payment Ways"}>
                                            <IconButton
                                                color="error"
                                                onClick={() => {
                                                    setAllInvoices({})
                                                }}
                                            >
                                                <RestartAltIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>}
                                </Box>
                                {
                                    futurePayment === "now" &&
                                    <Box sx={{pb: 2}}>
                                        <Box sx={{p: "5px", background: '#EDF2F7'}}> </Box>
                                        <Box sx={{
                                            px: 2,
                                            pt: 1,
                                            display: 'flex',
                                            columnGap: '40px',
                                            flexWrap: 'wrap',
                                            alignItems: 'center'
                                        }}>
                                            {provider_payment?.length && provider_payment.map((method, index) => {
                                                if (method?.id !== 1) {
                                                    if (selectedUser?.id && parseFloat(selectedUser?.wallet_point) > 0 && method?.id === 2) {
                                                        return <FormControlLabel
                                                            key={index}
                                                            value={method?.name}
                                                            size="small"
                                                            control={
                                                                <Checkbox
                                                                    checked={!!allInvoices[method?.id]?.amount}
                                                                    onChange={(e) => handleClickMethod(method, e)}
                                                                />
                                                            }
                                                            label={"My Wallet"}
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }}
                                                        />
                                                    } else if (method?.id !== 2) {
                                                        return <FormControlLabel
                                                            key={index}
                                                            value={method?.name}
                                                            size="small"
                                                            control={
                                                                <Checkbox
                                                                    checked={!!allInvoices[method?.id]?.amount}
                                                                    onChange={(e) => handleClickMethod(method, e)}
                                                                />
                                                            }
                                                            label={method?.name}
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                }
                                            })}
                                            {provider_payment?.length && provider_payment.map((method, index) => {
                                                console.log("provider name",method)
                                                if (paymentModal === method?.id) {
                                                    return <Modal
                                                        key={index}
                                                        open={paymentModal === method?.id}
                                                    >
                                                        <Box sx={modalStyle}>
                                                            {method?.is_gateway === 1 ?
                                                                <Grid container> 
                                                                    <Grid item xs={12} sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between'
                                                                    }}>

                                                                        {useCard && <Box sx={{
                                                                            width: 'max-content',                   
                                                                            cursor: 'pointer'
                                                                        }} onClick={() => setUseCard(false)}>
                                                                            <img src={arrow} alt={'back-arrow'}/>
                                                                        </Box>}
                                                                        {method?.payment_gateway === 'BrainTree' && !useCard &&
                                                                            <Braintree style={{marginTop: '-8px'}}/>
                                                                        }
                                                                        {!useCard &&
                                                                            <Typography sx={{fontSize: '18px'}}>
                                                                                Setup Payment
                                                                            </Typography>}

                                                                        <IconButton
                                                                            size="small"
                                                                            sx={{p: '0'}}
                                                                            onClick={() => handleCloseModal(method)}
                                                                        >
                                                                            <Close/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {useCard ?
                                                                            <UserCardsList
                                                                                userCards={userCards}
                                                                                setUseCard={setUseCard}
                                                                                handleBrainTree={handleBrainTree}
                                                                                handleSquare={handleSquare}
                                                                                paymentMethod={method}
                                                                                totalDue={totalDue()}
                                                                            />
                                                                            :
                                                                            <CardPayment
                                                                                userCards={userCards}
                                                                                setUseCard={setUseCard}
                                                                                useCard={useCard}
                                                                                setLoading={setLoading}
                                                                                handleBrainTree={handleBrainTree}
                                                                                handleSquare={handleSquare}
                                                                                paymentMethod={method}
                                                                                totalDue={totalDue()}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                method?.labels?.length > 0 ?
                                                                <Grid container sx={{alignItems: "center",}}>
                                                                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                        <IconButton size="small" onClick={() => handleCloseModal(method)}>
                                                                            <Close/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{mt: '-49px'}}>
                                                                        <Typography sx={{fontSize: "18px", textAlign: "center"}}>
                                                                            {"Enter " + method?.name}
                                                                        </Typography>  
                                                                    </Grid>
                                                            
                                                                    {
                                                                        Object.entries(allInvoices[method?.id])?.length > 0 &&
                                                                        Object.entries(allInvoices[method?.id])
                                                                            .map(([key, value], index) => 
                                                                                key !== 'name' && key !== "payment_method_id" && key !== "transactionId" && key !== "institution_name" && key !== "approval_date" && key !== "approval_code" && ( // Exclude 'transactionId' from dynamic rendering
                                                                                    <Grid key={index} item xs={12} sx={{marginTop: '24px'}}>
                                                                                        <FormControl fullWidth>
                                                                                            <FormLabel sx={labelStyle}>{key}</FormLabel>
                                                                                            <TextField
                                                                                                value={value || ''}
                                                                                                fullWidth
                                                                                                onChange={(e) => handleLabelsInputChange(e?.target?.value, key, method)}
                                                                                                sx={{...inputStyle}}
                                                                                                placeholder={`Enter ${key}`}
                                                                                                type='number'
                                                                                            />
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                ) 
                                                                            )
                                                                    }
                                                            
                                                                    { // Handle 'Transaction Id' separately
                                                                        method?.name === "E-Transfer/CC (External Device)" && (
                                                                            <Grid item xs={12} sx={{marginTop: '24px'}}>
                                                                                <FormControl fullWidth>
                                                                                    <FormLabel sx={labelStyle}>Transaction Id</FormLabel>
                                                                                    <TextField
                                                                                        value={allInvoices[method?.id]?.transactionId || ''}
                                                                                        fullWidth
                                                                                        onChange={(e) => handleLabelsInputChange(e.target.value, 'transactionId', method)} // Handle change specifically for 'transactionId'
                                                                                        sx={{...inputStyle}}
                                                                                        placeholder="Enter Transaction Id"
                                                                                    />
                                                                                </FormControl> 
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                    {console.log("Method Name:", method?.name)}
                                                                    {console.log("Selected User Card Data:", selectedUserCard?.data?.cards)}
                                                                    {/* {method?.name === "Card On File" && (
                                                                               
                                                                       <>
     <Box style={{ display: "flex", flexDirection: "column", height: "auto", width: "100%", marginTop: "15px" }}>
              {selectedUserCard?.data?.cards?.length > 0 ? (
                selectedUserCard?.data?.cards?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box sx={{ position: "relative", mt: 2 }}>
                      <Box sx={{ position: "absolute", top: -11, right: -3 }}>
                        <CloseIcon 
                          style={{ color: "#EB5757" }}
                          onClick={() => deleteusercard(item?.id)}
                        />
                      </Box>
                      <Card
                        sx={{
                          mt: 2, 
                          border: `0.8px solid ${selectedCard === (item?.token || item?.id) || item?.status === "Active" ? "#128D64" : "#C4C4C4"}`,
                          borderRadius: "4px",
                          boxShadow: "none",
                          position: "relative",
                          overflow: "unset",
                          cursor: "pointer", 
                        }}
                        onClick={() => defaultusercard(item?.id)}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", px: "70px" }}>
                          <img src={visa} width="50px" style={{ marginLeft: 2 }} alt="" />
                          <Typography
                            variant="h5"
                            color="initial"
                            sx={{
                              ml: 3,
                              fontSize: "16px",
                              "@media (max-width:500px)": {     
                                marginLeft: "5px",     
                                textAlign: "center",
                                fontSize: "15px",        
                              },
                            }}
                          >
                            {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                          </Typography>
                          {(selectedCard === (item?.token || item?.id) || item?.status === "Active") && (
                            <Box sx={{ ml: "50px" }}>
                              <img src={successIcon} alt="successIcon" />
                            </Box>
                          )}
                        </Box>
                      </Card>  
                    </Box>
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                  There are no existing cards.
                </Typography>
              )}
            </Box> 
                                                                        </> 
                                                                            )
                                                                        } */}
                                                            
                                                                      {
                                                                            method?.name === "3rd Party Financing" && (
                                                                                <>
                                                                                <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                    <FormControl fullWidth>
                                                                                        <FormLabel sx={labelStyle}>
                                                                                            Institution Name
                                                                                        </FormLabel>
                                                                                        <TextField
                                                                                            value={allInvoices[method?.id]?.institution_name || ''}
                                                                                            fullWidth
                                                                                            onChange={(e) => handleLabelsInputChange(e.target.value, 'institution_name', method, bookingType)}
                                                                                            sx={{ ...inputStyle }}
                                                                                            placeholder="Enter Institution Name"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                               <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                    <FormControl fullWidth> 
                                                                                        <FormLabel sx={labelStyle}>
                                                                                            Approval Date
                                                                                        </FormLabel>
                                                                                        <TextField 
                                                                                            value={allInvoices[method?.id]?.approval_date || ''}
                                                                                            fullWidth
                                                                                            onChange={(e) => handleLabelsInputChange(e.target.value, 'approval_date', method, bookingType)}
                                                                                            sx={{ ...inputStyle }}
                                                                                             type='date'
                                                                                            placeholder="Enter Approval Date"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                               <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                    <FormControl fullWidth>
                                                                                        <FormLabel sx={labelStyle}>
                                                                                            Approval Code
                                                                                        </FormLabel>
                                                                                        <TextField
                                                                                            value={allInvoices[method?.id]?.approval_code || ''}
                                                                                            fullWidth
                                                                                            onChange={(e) => handleLabelsInputChange(e.target.value, 'approval_code', method, bookingType)}
                                                                                            sx={{ ...inputStyle }}
                                                                                           
                                                                                            placeholder="Enter Approval Code"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                                        
                                                                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', mt: '24px'}}>
                                                                        <CustomButton handleClick={() => handleConfirm(allInvoices[method?.id], method)} val={"Confirm"}/>
                                                                    </Grid>
                                                                </Grid>
                                                                    :
                                                                    <Grid
                                                                        container
                                                                        spacing={1}
                                                                        sx={{alignItems: "center",}}
                                                                    >
                                                                        <Grid item>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "18px",
                                                                                    textAlign: "center"
                                                                                }}
                                                                            >
                                                                                Payment Method Not configure. Contact
                                                                                your provider
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </Box>
                                                    </Modal>
                                                }
                                            })}
                                            <Box sx={{display:"flex",gap:"3"}}>
                                            <Box>
                                                <FormControl fullWidth sx={{mb: "16px"}}>
                                                    <FormLabel sx={labelStyle}>Invoice Date (Optional)</FormLabel>
                                                    <TextField
                                                        fullWidth
                                                        sx={inputStyle}
                                                        type={'date'}
                                                        placeholder={"Select Invoice Date"}
                                                        value={invoiceDate}
                                                        onChange={(e) => setInvoiceDate(e?.target?.value)}
                                                    />
                                                     {error && <Typography color="error">{error}</Typography>} 
                                                </FormControl>
                                            </Box>
                                      {/* <Button variant="contained" onClick={handleOpen}>
                                      Card on File
                                     </Button> */}
                                     <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            width: "80%", 
            margin: "15px auto",
            bgcolor: "background.paper",
            p: 4,
            boxShadow: 24,
            borderRadius: "8px",
          }}
        >
         
            <Box style={{ display: "flex", flexDirection: "column", height: "auto", width: "100%", marginTop: "15px" }}>
              {selectedUserCard?.data?.cards?.length > 0 ? (
                selectedUserCard?.data?.cards?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box sx={{ position: "relative", mt: 2 }}>
                      <Box sx={{ position: "absolute", top: -11, right: -3 }}>
                        <CloseIcon
                          style={{ color: "#EB5757" }}
                          onClick={() => deleteusercard(item?.id)}
                        />
                      </Box>
                      <Card
                        sx={{
                          mt: 2,
                          border: `0.8px solid ${selectedCard === (item?.token || item?.id) || item?.status === "Active" ? "#128D64" : "#C4C4C4"}`,
                          borderRadius: "4px",
                          boxShadow: "none",
                          position: "relative",
                          overflow: "unset",
                          cursor: "pointer",
                        }}
                        onClick={() => defaultusercard(item?.id)}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", px: "70px" }}>
                          <img src={visa} width="50px" style={{ marginLeft: 2 }} alt="" />
                          <Typography
                            variant="h5"
                            color="initial"
                            sx={{
                              ml: 3,
                              fontSize: "16px",
                              "@media (max-width:500px)": {     
                                marginLeft: "5px",     
                                textAlign: "center",
                                fontSize: "15px",       
                              },
                            }}
                          >
                            {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                          </Typography>
                          {(selectedCard === (item?.token || item?.id) || item?.status === "Active") && (
                            <Box sx={{ ml: "50px" }}>
                              <img src={successIcon} alt="successIcon" />
                            </Box>
                          )}
                        </Box>
                      </Card>  
                    </Box>
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                  There are no existing cards.
                </Typography>
              )}
            </Box>
        
        </Box>
      </Modal>
                                     </Box>
      
                                         
                                        </Box>
                                        <Box sx={{marginTop: "10px", px: 2,}}>
                                            <CustomButton handleClick={handleClick}  size={'large'} mainColor={'#27AE60'}   
                                            style={{width:"200px", "&:hover": {
                                            borderColor:"#27AE60",
                                          },}} val={'Confirm Sale'}/>
                                            {/*<InvoiceModal/>*/} 
                                        </Box>
                                    </Box>
                                }
                                {futurePayment === "future" && (
                                    <Box sx={{p: 2}}>
                                        <CustomButton    size={'large'} mainColor={'#27AE60'}   
                                           style={{width:"200px", "&:hover": {
                                           borderColor:"#27AE60",
                                         },}}
                                         handleClick={handleBookAppointment} val={'Pending Sale'}/>
                                    </Box>
                                )}
                            </Card>
                        ) : (
                            <Box sx={{
                                p: "12px",
                                minHeight: '150px',
                                display: 'flex',
                                alignItems: 'center',  
                                justifyContent: 'center',
                                borderRadius: "4px",
                                mt: 2,
                                background: '#fff',
                            }}> 
                                <Typography sx={{fontFamily: "helvetica-lt-std-bold"}}>
                                    Select Appointment Date and Time
                                </Typography> 
                            </Box> 
                        )}
                    </Box>
                    :
                    <Box sx={{background: "#EDF2F7", p: '12px', borderRadius: "4px", mt: 2,}}>
                        <Box sx={{
                            p: "12px",
                            minHeight: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: "4px",
                            background: '#fff',

                        }}>
                            <Typography sx={{fontFamily: "helvetica-lt-std-bold"}}>Select Service or plan</Typography>
                        </Box>
                    </Box>
                }
            </Card>
        </Box>
    )
};
export default InvoicePayment;