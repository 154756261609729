import React, {useEffect, useState} from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  FormControlLabel,
  IconButton,
  TablePagination,  
  Tooltip,
} from "@mui/material";
import { scrollCSS } from "../../App";
import { IOSSwitch } from "../switch/IOSSwitch";
import {confirmation, handleDelete, handleEditConfiguration, successConfirmation} from "../../services/utills";
import { useDispatch } from "react-redux";
import Loader from "../loader/loader";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { AiFillSetting } from "react-icons/ai";  
import {useParams} from "react-router-dom";
import moment from "moment";
import Waveloading from "../../pages/loading/Waveloading";  
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import therapeutic from "../../assets/images/therapeutic.png"
export const escapeRegExp = value => { 
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D8F3FF",
    color: "#27272E",
    fontSize: "16px",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}   

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover, 
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const GhlData = ({
  headRow,
  tableData,
  isStatus,
  isClinic,
  handleEdit,
  isGateway,
  search,
  type,
    isInput,
}) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("calories")

  const dispatch = useDispatch();
  const params = useParams()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  console.log("tabeldata", tableData?.data)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGatewayChange = async (e, row) => {
    setLoading(true)
    let finalData = {
      id: row?.provider_id,
      is_gateway: e.target.checked,
    };
    if (type === "paymentMethod") {
      finalData["status"] = row?.status || "In-Active";
      finalData["labels"] = row?.labels || [];
      finalData["name"] = row?.name;
    }
    await handleEditConfiguration(finalData, dispatch, type)
    setLoading(false);
  };

  const handleStatusChange = async (e, row) => {
    const {checked} = e.target
    setLoading(true);
    let finalData = {
      id: row?.provider_id,
      status: checked ? "Active" : 'In-Active',
    };

    if (type === "paymentMethod") {
      finalData["is_gateway"] = row?.is_gateway;
      finalData["labels"] = row?.labels || [];
    }
   
    if (
        type === "role" ||
        type === "permission" || 
        type === "menu" ||
        type === "language" ||
        type === "paymentMethod" ||
        type === "room" ||
        type === "treatment" ||
        type === "treatmentForm"||
        type ==="ghl"
    ) {
      finalData["name"] = row?.name;
    }
    if (type === "room") {

      finalData["room_no"] = row?.room_no;
      finalData["provider_id"] = params?.clinic_id
    }
    if (type === "treatmentForm") {
      finalData["type"] = row?.type;
      finalData["description"] = row?.description;
    }
    if (type === "treatmentGroup") {
      finalData["provider_id"] = row?.id;
      finalData["title"] = row?.title;
      finalData["file_path"] = row?.file_path;
   
      finalData["treatment_id"] = row?.treatment_id;
      console.log("row.file_path:", row.file_path);
    }

    if (type === "treatment" || type === "treatmentForm" || type === "treatmentGroup") {
      finalData["provider_id"] = params?.clinic_id;
    }

    if (type === "permission") {
      finalData["menu_id"] = row?.menu_id;
    }
    if (type === "menu") {
      finalData["icon"] = row?.icon;
      finalData["key"] = row?.key;
      finalData["url"] = row?.url;    
    }
    if (type ==="ghl") {
        finalData["ghl_id"] = row?.provider_id;
        // finalData["key"] = row?.key;
        finalData["url"] = row?.url;
    }
   
    await handleEditConfiguration(finalData, dispatch, type);
    setLoading(false);
  };

  const handleDeleteClick = async (item) => {  
    if (await confirmation()) {
      setLoading(true);

      let finalData = {
        id: item?.id,
      }
      if (params?.clinic_id) {
        finalData['provider_id'] = params?.clinic_id
      }
      await handleDelete(dispatch, type, finalData);

      setLoading(false);
    }
  };

  const handleInputSwitch =async () => {
   await  successConfirmation('Warning',"You cannot update it here.You can change it by editing",'warning')
  }

  useEffect(() => {
    setFilterData(tableData)
  }, [tableData])    

  useEffect(() => {
    if (search !== '' || search?.length) {
      const searchRegex = new RegExp(escapeRegExp(search), 'i')
      let filteredRows

      filteredRows = tableData?.filter(row => {
        return Object.keys(row).some(field => { 
          return row[field] !== null && searchRegex.test(row[field].toString()) 
        })
      })

      if (filteredRows?.length > 0) {
        setFilterData(filteredRows)        
      } else {
        setFilterData([])
      }   
    } else {
      setFilterData(tableData)
    }
  }, [search])


  console.log("data tabel",filterData)
  return (
      <Paper    
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select": {
              lineHeight: "1.1375em",
            },
          }}
      >
        <TableContainer sx={{height: "calc(100vh - 280px)", ...scrollCSS}}>
          {loading === true && <Waveloading fullSize/>}
          <Table stickyHeader aria-label="customized table">
            <TableHead>   
              <TableRow>
                {headRow.map((item, index) => (       
                    <StyledTableCell          
                        key={index}  
                        align={item?.key === "action" ? "right" : "left"}   
                    >
                      {item?.value} 
                    </StyledTableCell>     
                ))}  
              </TableRow>
            </TableHead>
            {filterData?.length > 0 ? (
                <TableBody>
                  {filterData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                          <StyledTableRow hover key={index}>    
                            {headRow.map(
                                (item, i) => {   
                                  // if (item?.key === "created_at") {
                                  //   return ( 
                                  //       <StyledTableCell key={i} component="th" scope="row">
                                  //         {moment(row?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                  //       </StyledTableCell>
                                  //   )
                                  // }
                                  // if (item.key === "file_path" && item.isImage) {
                                  //   return (
                                  //     <StyledTableCell key={i} component="th" scope="row">
                                  //       <img
                                  //         src={row.file_path} // Assuming row.file_path is the image URL
                                  //         alt="Image"   
                                  //         width={50} // Adjust the width as needed
                                  //         height={50} // Adjust the height as needed
                                  //       />
                                  //     </StyledTableCell>
                                  //   );
                                  // }
                                  // if (item.key === "file_path" && item.isImage) {
                                  //   return (
                                  //     <StyledTableCell key={i} component="th" scope="row">
                                  //       {(row.file_path) ? (
                                  //         <img
                                  //           src={row.file_path}    
                                  //           alt="Image"
                                  //           width={50}   
                                  //           height={50}
                                  //         />  
                                  //       ) : (   
                                  //         <img        
                                  //           src={therapeutic} 
                                  //           alt="Image default"
                                  //           width={50}
                                  //           height={50}    
                                  //         />
                                  //       )}
                                  //     </StyledTableCell>    
                                  //   );  
                                  // }     
                                  if (item.key === "provider_id") {
                                    return (
                                      <StyledTableCell key={i} component="th" scope="row">
                                        {row.provider_name}
                                      </StyledTableCell>
                                    );
                                  }
                                  if (item?.isObject) {
                                    return (
                                        <StyledTableCell key={i} component="th" scope="row">
                                          {/* {row[item?.key]?.title || ""} */}
                                        </StyledTableCell>
                                    )
                                  } else if (item?.key !== "action" && item?.key !== "status" && item?.key !== "is_gateway"&& item?.key !== "is_input") {
                                    return (
                                        <StyledTableCell key={i} component="th" scope="row">
                                          {item?.key === "plan_price" && type === 'membership' ? '$' : ''}{row[item?.key] || ""}
                                        </StyledTableCell>
                                    )        
                                  }          
                                })}  
                            {/* {isGateway && (   
                                <StyledTableCell>
                                  <FormControlLabel  
                                      control={  
                                        <IOSSwitch
                                            sx={{m: 1}}
                                            checked={row?.is_gateway !== 0}
                                            onChange={(e) => handleGatewayChange(e, row)}
                                        />
                                      }
                                      label={""}  
                                  />   
                                </StyledTableCell>    
                            )}   */}
                            {/* {isStatus && (
                                <StyledTableCell>
                                  <FormControlLabel
                                      control={   
                                        <IOSSwitch
                                            sx={{m: 1}}
                                            checked={row?.status === "Active"}   
                                            onChange={(e) => handleStatusChange(e, row)}
                                        />
                                      } 
                                      label={""}
                                  />
                                </StyledTableCell>
                            )} */} 
                            {isInput && (
                                <StyledTableCell>
                                  {row?.labels?.length || 0}
                                </StyledTableCell>
                            )}
                            <StyledTableCell align="right" sx={{ 
                              minWidth: "100px",
                              pointerEvents: type === 'paymentMethod' && row?.id <= 4 ? "none" : "auto"
                            }}>
                             
                              {type !== "membership" && 
                              <Tooltip title="Edit">      
                                <IconButton
                                    size="small"
                                    onClick={() => handleEdit(row, true)}  
                                >  
                                  <MdModeEditOutline color={"#12B104"} fontSize={'20px'}/>     
                                </IconButton>   
                              </Tooltip>   
                              }

                              <Tooltip title="Delete">
                                <IconButton
                                    size="small"  
                                    onClick={() => handleDeleteClick(row)}
                                >
                                  <MdDeleteOutline color={'#E53E31'} fontSize={'20px'}/>
                                </IconButton>
                              </Tooltip>     
                            </StyledTableCell>   
                          </StyledTableRow>     
                      ))}
                </TableBody>
            ) : (
                <TableBody>
                  <TableRow sx={{height: "300px", textAlign: "center"}}>
                    <TableCell
                        colSpan={headRow?.length}
                        align={"center"}
                        sx={{
                          fontWeight: "bold",
                          borderBottom: "none",
                          fontSize: "20px",
                        }}
                    >
                      No Data  
                    </TableCell>    
                  </TableRow>
                </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={filterData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
  );
};

export default GhlData;
