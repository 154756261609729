import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router";
import Hidden from "@mui/material/Hidden";
import { ListItemIcon } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router";
import logo from "../../assets/images/logo.jpg";

import { MdCardMembership, MdFileCopy, MdLanguage, MdLocalHospital, MdPowerSettingsNew, MdSupervisedUserCircle, MdVpnKey } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import { confirmation } from "../../services/utills";
import { removeUser } from "../../store/auth";
import { AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';



const sideData = [
    {
      name: "Roles",
      logo: <MdSupervisedUserCircle style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/roles",
        active:['roles',"edit-role",'add-role']
    },
    {
      name: "Permissions",
      logo: <MdVpnKey style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/permissions",
        active:['permissions',"edit-permissions",'add-permissions']
    },
    {
      name: "Menus",
      logo: <AiOutlineMenu style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/menus",
        active:['menus',"edit-menus",'add-menus']
    },
    {
      name: "Membership Plans",
      logo: <MdCardMembership style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/all-membership-plans",
        active:['all-membership-plans',"edit-membership",'add-membership']
    },
    {
      name: "Payment Methods",
      logo: <MdOutlinePayment style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/payment-methods",
        active:['payment-methods',"edit-payment-methods",'add-payment-methods']
    },
    {
      name: "Languages",
      logo: <MdLanguage style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/languages",
        active:['languages',"edit-language",'add-language']
    },
    {
      name: "Notification Template",
      logo: <MdFileCopy style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/notification-templates/customer-signup/1",
        active:[
            'customer-signup',
            "customer-created-from-crm",
            "membership-signup",
            "membership-upgraded",
            "appointment-booked",
            "appointment-rescheduled",
            "appointment-canceled",
            "reminder-for-appointment-before",
            "reminder-for-appointment-day",
            "check-in",
            "receipt-generated",
            "ask-for-review",
            "customer-no-show",
            "clinic-cancel-membership",
            "credit-card-decline",
        ]
    },
    {
      name: "Clinics",
      logo: <MdLocalHospital style={{ fontSize: "1.5rem" }} />,
      url: "/admin/configuration/clinics",
        active:['clinics',"edit-clinic",'add-clinic']
    },
  ];



const AdminDrawer = ({ sidebarOpen}) => {  
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {pathname} = location;


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };






    const handleButtonClick = (val) => {
        navigate(val);
    };




    const checkActive =(activeArray)=> {
        // console.log(pathname)
        // console.log(activeArray)
        let res = pathname.split('/')
        let lastValue
        if (pathname.includes('/admin/configuration/notification-templates/')){
            lastValue = res[res?.length - 2];
        }else if (pathname.includes('edit-')) {
            lastValue = res[res?.length - 2];
  
        } else {
            lastValue = res[res?.length - 1];
        }
        return !!activeArray.includes(lastValue)
    }


    const handleLogout = async () => {
        if (await confirmation("Are you want to logout", "Yes, Logout!")) {
          localStorage.removeItem("token");
          dispatch(removeUser());
          const cookies = document.cookie.split(";");
                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i];
                    const eqPos = cookie.indexOf("=");
                    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
                }
        
                // Navigate to login page
                navigate("/login");
        
                // Optionally, force a page reload
                window.location.reload();
        }
      };
  return (
    <>
      <Hidden mdUp implementation="css">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          
            // border: '1px solid #000', 
            borderRadius: '4px', 
            padding: '4px', 
            width:"61px"    
          }}
          variant="outlined"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
      
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Box
          sx={{
            display: 'block',
            maxHeight: "100vh",
            minHeight: '50vh',
            overflowY: "auto",
            background: '#fff',
            width: '250px',
            p: '0',
            borderRadius: '7px',
          }}
        >
           <Box>
           <Box>
           <Link to={""}>
                  <img src={logo} alt={"logo"} style={{ marginTop:'20px', padding:'10px' }} height={"fit-content"} width={"100%"}/>
                </Link>
            <List
                sx={{
                  width: "100%",
                  ".MuiListItemIcon-root ": {
                    minWidth: "37px",
                  },
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
              {sideData?.map((item, index) => (
                  <ListItemButton
                      key={index}
                      onClick={() => handleButtonClick(item?.url)}
                      sx={{
                        borderRadius: "7px",
                        mb: "7px",
                        backgroundColor: checkActive(item?.active) ? "#C9EDFC" : "#fff",
                        "&:hover": {
                          backgroundColor: "#C9EDFC",
                        },
                      }}
                  >
                    <ListItemIcon>{item?.logo}</ListItemIcon>
                    <ListItemText primary={item?.name}/>
                  </ListItemButton>
              ))}
              <ListItemButton
                  onClick={() => handleLogout()}
                  sx={{
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "#C9EDFC",
                    },
                  }}
              >
                <ListItemIcon>
                  <MdPowerSettingsNew style={{fontSize: "1.5rem"}}/>
                </ListItemIcon>
                <ListItemText primary="Logout"/>
              </ListItemButton>
            </List>
          </Box>
          </Box>
        </Box>
      </Drawer>
      </Hidden>
    </>
  );
};

export default AdminDrawer;
