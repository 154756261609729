import React, {useEffect, useState} from 'react';
import Loader from "../../../../components/loader/loader";
import {Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import FormInput, {labelStyle} from "../../../../components/form/formInput";
import CustomButton from "../../../../components/custom-button/customButton";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import toast from "react-hot-toast";
import {
    getClinicsRoomsByID,
    storeClinicsRooms,
    updateClinicsRooms
} from "../../../../store/clinics/clinicSettings/clinic-rooms";
import * as Yup from "yup";

const AddRooms = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const user = useSelector(state => state?.authStore?.user)


    const validationSchema = yup.object({
        name: yup.string().min(2, "Name is Too Short").required('Rooms name is required'),
        room_no: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Only Integer Allowed"
        ).typeError("Room number is required").required('Room number is required'),
        status: yup.string().required('Status is required'),
    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            name: '',
            room_no: '',
            status: 'In-Active',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            let res
            if (params?.room_id) {
                res = await dispatch(updateClinicsRooms({...data, provider_id: params?.clinic_id}))
            } else {
                res = await dispatch(storeClinicsRooms({...data,provider_id: params?.clinic_id}))
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.room_no) {
                    setFieldTouched("room_no", true)
                    setFieldError("room_no", errors?.room_no)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Room ${params?.room_id ? "updated" : "Stored"} Successfully`, {duration: 3000})
                navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/rooms`)
            }

            setLoading(false)
        },
    });


    useEffect(() => {
        (async () => {
            if (params?.room_id) {
                setLoading(true)
                let res = await dispatch(getClinicsRoomsByID(params?.room_id))
                if (res?.payload?.data?.id) {
                    await setFieldValue("name", res?.payload?.data?.name)
                    await setFieldValue("room_no", res?.payload?.data?.room_no)
                    await setFieldValue('status', res?.payload?.data?.status || 'In-Active')
                    await setFieldValue('id', res?.payload?.data?.id)
                }
                setLoading(false)
            }

        })()
    }, [params?.room_id])

    return (
        
loading ? <Loader/> :
            <Grid container p={5}>
                <Grid item xs={12} lg={8}>
                    <form onSubmit={handleSubmit}>
                        <FormInput
                            name={"name"}
                            label={"Name"}
                            placeholder={'Type  role name'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                        <FormInput
                            name={"room_no"}
                            label={"Room Number"}
                            placeholder={'Type  room number'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />

                        <FormControl sx={{mb: '16px'}}>
                            <FormLabel sx={labelStyle}>Status</FormLabel>
                            <RadioGroup
                                row
                                name="status"
                                value={values?.status}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="In-Active" control={<Radio/>} label="InActive"/>
                                <FormControlLabel value="Active" control={<Radio/>} label="Active"/>
                            </RadioGroup>
                        </FormControl>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{mr: '16px'}}>
                                <CustomButton
                                    mainColor={'#ED1E45'}
                                    val={'Cancel'}
                                    handleClick={() => {
                                        navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/rooms`)
                                    }}
                                />
                            </Box>
                            <SubmitButton val={params?.room_id ? 'Update Room' : 'Add Room'}/>
                        </Box>
                    </form>
                </Grid>
            </Grid>
    );
};

export default AddRooms;