import {Box, Breadcrumbs, Button, Divider, Grid, Link as MuiLink, Paper, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react'
import logo  from '../../../../assets/logo/3d-logo.png'
import Loader from '../../../../components/loader/loader';
import {getBookingInvoice, getbookinginvoice} from '../../../../store/crm/pos';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
// import html2pdf from 'html2pdf.js';
import ReactToPrint from "react-to-print";
import {Link} from "react-router-dom";
import ArrowBack from "../../../../assets/icon/chevron-back-outline.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Waveloading from '../../../loading/Waveloading';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const wrapper = {
   background:'white',
   padding:'15px 28px',
   borderTop:"12px solid  #72D5FF",
   borderBottom:"12px solid  #72D5FF",
   borderRadius:"13px"

  };
  export const logowrap ={

    display:'flex',
    justifyContent:"space-between",alignItems:'center'
  };
  export const heading ={
    fontFamily:'helvetica-lt-std-light',
    fontWeight:'700!important',
    fontSize:'30px!important'

  };
  export const content ={
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'400!important',
    fontSize:'18px!important',marginBottom:'3px',
  };
  export const subheading ={
    marginBottom:'3px',
    fontFamily:'helvetica-world-bold',
    fontWeight:'700!important',
    fontSize:'19px!important'
  };

  export const contentwrapper ={
    marginTop:'40px',
    display:'flex',
    alignItems:'center',

    // border:'1px solid red',
  // gap:"40px",
  justifyContent:"space-between",
    '@media (max-width: 768px)': {

      flexDirection: 'column',
      // alignItems: 'center',
      alignItems:'start',
    }

  }
  
  export const tablewrapper ={

    display:'flex',
    justifyContent:"space-between",
    marginTop:'15px',
    marginBottom:'15px',
    '@media (max-width: 1200px)': {
    display:'flex',
    justifyContent:'left',

    }


  }
  export const subfooter ={
    margin:'0 auto',
    width:"50%",
    display:'flex',
    justifyContent :'space-between',
    alignItems:'center',
    '@media (max-width: 768px)': {
      width: '80%',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }
export const btnwrapper= {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    margin: "15px 2px",
}
export const printstyle= {
    background: "#219653",
    border: "1px solid #219653",
    color: "white",
    textTransform: "capitalize",
    ml: '10px',
    borderRadius: "3px",
    "&:hover": {
        color: "#219653",
    }

}

const Invoices = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {booking_id} = useParams()


    const crmPOSStore = useSelector(state => state?.crmPOSStore)
    const {bookingInvoice = {}} = crmPOSStore

    const componentRef = useRef();
    const fixedHeight = '250px';
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(getBookingInvoice(booking_id));
            setLoading(false);
        }

        fetchData();
    }, []);
    console.log(bookingInvoice?.customer?.name)
    const navigate = useNavigate();

    const handleNavigation = () => {
      const customerName = bookingInvoice?.customer?.name;
      console.log('Navigating with customer name:', customerName); // This line is just for debugging purposes
  
      // Check if the customer name is not undefined or null
      if (customerName) {
        navigate('/crm/customer-relationship-management', { state: { value: customerName } });
      } else {
        console.error('Customer name is undefined or null');
        // Handle the case where customer name is not available (e.g., show an alert or notification)
      }
    };
  
    return (
      // Log to ensure it's not undefined
        <>
            <Box sx={btnwrapper}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Paper
                        component={Link}
                        to={'/crm/appointments'}
                        sx={{
                            width: 'max-content',
                            p: '5px',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            cursor: 'pointer',
                            mr: '20px',
                        }}
                        elevation={3}
                    >
                        <img src={ArrowBack} alt={'close'}/>
                    </Paper>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small"/>}
                        aria-label="breadcrumb"
                    >
                        <MuiLink component={Link} to={'/'} underline="hover" color="inherit">
                            Home
                        </MuiLink>,
                        <MuiLink
                            component={Link} to={'/crm/point-of-sale'}
                            underline="hover"     
                            color="inherit"
                        >
                            (POS) Point of Sale
                        </MuiLink>,
{/*                        

                        <MuiLink   
                            component={Link}  
                            to={{
                                pathname: '/crm/customer-relationship-management',
                                state: {value: bookingInvoice?.customer?.name }, 
                              }}
                            underline="hover"
                            color="inherit"  
                        >       
                         {bookingInvoice?.customer?.name}           
                        </MuiLink>, */}
                        <Typography
        color="inherit"
        underline="hover"
        style={{ cursor: 'pointer' }}
        onClick={handleNavigation}
      >
        {bookingInvoice?.customer?.name }
      </Typography>


                       <Typography key="3"  color="#2F80ED" sx={{fontFamily: 'helvetica-lt-std-bold', textDecoration: 'underline'}}>
                            View Invoice
                        </Typography> 
                    </Breadcrumbs>
                </Box>

                <ReactToPrint 
                    trigger={() => <Button sx={{...printstyle}}>print</Button>}
                    content={() => componentRef.current}
                    pageStyle="@page { size: A4; }"
                />
                {/*<Button sx={{...printstyle}} onClick={() => handleDownload(booking_id)}>Download PDF</Button>*/}
            </Box>
            {loading ? <Waveloading fullSize/> : ''}
            <div ref={componentRef}>
                <Box sx={wrapper}>
                    <>
                        <Grid container>
                            <Grid item lg={12} sx={logowrap} style={{}}>
                                <div style={{display:"flex",alignItems:"center"}}>

                                <Typography sx={heading} style={{marginRight:"4px"}} >Invoice</Typography>
                                </div>


                                <img src={logo} alt={"loading"} width={'20%'} height={"fit-content"}/>
                            </Grid>
                            <Grid item sm={12} sx={contentwrapper}>
                            <Grid item xl={4} lg={4} md={12} xs={12}  sx={{ height: fixedHeight }}>
                                    <Typography sx={subheading}>From:</Typography>
                                    <Typography sx={subheading}>{bookingInvoice?.provider?.name}</Typography>
                                    <Typography sx={content}>{bookingInvoice?.provider?.phone}</Typography>
                                    <Typography sx={content}>{bookingInvoice?.provider?.address?.address}</Typography>
                                    <Typography>{bookingInvoice?.provider?.address?.city} {bookingInvoice?.provider?.address?.country}</Typography>
                                </Grid>
                                <Grid item xl={4} lg={4} md={12} xs={12}  sx={{ height: fixedHeight, mt: { xs: 5,lg:0} }}>
                                    <Typography sx={subheading}>To:</Typography>
                                    <Typography sx={subheading}>{bookingInvoice?.customer?.name}</Typography>
                                    <Typography sx={content}>{bookingInvoice?.customer?.phone}</Typography>
                                    <Typography sx={content}>{bookingInvoice?.customer?.email}</Typography>
                                    <Typography sx={content}>{bookingInvoice?.customer?.address?.address}</Typography>
                                </Grid>
                               
                                <Grid item xl={4} lg={4} md={12} xs={12} sx={{ height: fixedHeight, mt: { xs: 5, lg: 0 } }}>
  <Typography sx={subheading}>Info:</Typography>
  <Typography sx={subheading}>Amount: ${bookingInvoice?.grand_total}</Typography>
  <Typography sx={content}>Date: {bookingInvoice?.date}</Typography>
  <Box style={{ display: "flex" }}>
    <Typography sx={content}>Invoice ID:</Typography>
    {bookingInvoice?.invoices_methods?.length > 0 && bookingInvoice.invoices_methods[0]?.id && (
      <Typography sx={content}>{bookingInvoice.invoices_methods[0]?.id}</Typography>
    )}
  </Box>
  {bookingInvoice?.invoices_methods?.[0]?.payment_methods === "Finance Company" && (
    <Box style={{ display: "flex" }}>
      <Typography sx={content}>Institution Name:</Typography>
      {bookingInvoice?.invoices?.[0]?.institution_name && (
        <Typography sx={content}>{bookingInvoice.invoices[0]?.institution_name}</Typography>
      )}
    </Box>
  )}
  {bookingInvoice?.invoices_methods?.[0]?.payment_methods === "Finance Company" && (
    <Box style={{ display: "flex" }}>
      <Typography sx={content}>Approval Code:</Typography>
      {bookingInvoice?.invoices?.[0]?.approval_code && (
        <Typography sx={content}>{bookingInvoice.invoices[0]?.approval_code}</Typography>
      )}
    </Box>
  )}
  {bookingInvoice?.invoices_methods?.[0]?.payment_methods === "Finance Company" && (
    <Box style={{ display: "flex" }}>
      <Typography sx={content}>Approval Date:</Typography>
      {bookingInvoice?.invoices?.[0]?.approval_date && (
        <Typography sx={content}>{bookingInvoice.invoices[0]?.approval_date}</Typography>
      )}
    </Box>
  )}
  <Box style={{ display: "flex" }}>
    {bookingInvoice?.invoices?.some(invoice => invoice.transaction_id) && (
      <>
        <Typography sx={content}>Transaction ID:</Typography>
        {bookingInvoice.invoices.find(invoice => invoice.transaction_id)?.transaction_id && (
          <Typography sx={content}>{bookingInvoice.invoices.find(invoice => invoice.transaction_id)?.transaction_id}</Typography>
        )}
      </>
    )}
  </Box>
  <Typography sx={{ ...content }}>
    Status:
    <Typography
      as={'span'}
      sx={{
        background: bookingInvoice?.payment_status === 'Paid' || bookingInvoice?.payment_status === 'Pre Paid' ? "#1C7E46" : '#C64949',
        ml: '1px',
        color: "#fff",
        padding: '2px 22px',
        borderRadius: "22px"
      }}>
      {bookingInvoice?.payment_status}
    </Typography>
  </Typography>
</Grid>

                            </Grid>   
                        </Grid>

                        {/* <Grid container>
                            <Grid item lg={12} sx={tablewrapper} style={{marginTop: '60px'}}>
                                <Grid item lg={5}>
                                    <Typography sx={subheading}>Service</Typography>
                                </Grid>
                                <Grid item lg={3}>

                                </Grid>   
                                <Grid item lg={2}>
                                    <Typography sx={subheading}>Qty</Typography>
                                </Grid>
                                {bookingInvoice.services && bookingInvoice.services[0]?.extra_quantity !== null && (
                                    <Grid item lg={2}>
                                        <Typography sx={subheading}>Extra Qty</Typography>
                                    </Grid>
                                )}
                                <Grid item lg={2}>
                                    <Typography sx={subheading}>Amount</Typography>   
                                </Grid>
                            </Grid>
                        </Grid> */}
  <Grid container>
                <Grid item lg={12} sm={12} xs={12} sx={{...tablewrapper,marginTop: '40px',mb:"13px"}}>
                    <Grid item lg={5} sm={5.8} xs={6}>
                        <Typography  sx={{...subheading, color: '#04B4FF'}}>Service</Typography>
                    </Grid>
                    <Grid item lg={2.9}></Grid>
                    <Grid item lg={2} sm={3.1} xs={3.5}>
                        <Typography  sx={{...subheading, color: '#04B4FF'}}>Qty</Typography>
                    </Grid>
                    <Grid item lg={2} sm={3} xs={3.2} sx={{ textAlign:{sm:'unset', xs:'end'} }}>
                        <Typography  sx={{...subheading, color: '#04B4FF',  textAlign:{md:'left', sm:'start', xs:'start'}}}>Amount</Typography>
                    </Grid>
                </Grid>
            </Grid>
                        <Box>
                            <Divider style={{borderColor: "#000000", width: "100%", height: '5px'}}/>
                        </Box>
                        {bookingInvoice.services && bookingInvoice?.services.map((item, index) => (
                            <Grid container key={index}>
                                <Grid item lg={12} xs={12} sx={tablewrapper} key={index}>
                                    <Grid item xs={6}   lg={5}>
                                        <Typography sx={content}>{item?.name}</Typography>
                                    </Grid>
                                    {/* <Grid item xs={1.2} md={0} lg={3}></Grid> */}
                                    {item?.extra_quantity !== null && item?.extra_quantity !== 0 ? (
                                        <Grid item xs={2} lg={4}>
                                            <Typography sx={content}>{item?.extra_quantity}</Typography>
                                        </Grid>
                                    ) : (             
                                        <>
                                        <Grid item lg={3}> </Grid> 
                                        <Grid item xs={3} lg={2}>
                                            <Typography sx={content}>{item?.service_quantity || '1'}</Typography>
                                        </Grid>
                                        </>
                                    )}

                                    <Grid item xs={3} lg={2}>
                                        <Typography sx={content}>${item?.booking_cost}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={12}>
                                    <Divider style={{borderColor: "#000000", width: "100%"}}/>
                                </Grid>
                            </Grid>
                            ))}
                        {/* <Box>
                            <Divider style={{borderColor: "#000000", width: "100%"}}/>
                        </Box> */}
<Grid container>
  <Grid item lg={12} xs={12} sx={tablewrapper}>
    <Grid item lg={5.8} xs={false}>
    <Typography sx={content}></Typography>
    </Grid>   
    <Grid item lg={3} xs={4}>
      <Typography sx={{ content }}>Sub Total (CAD)</Typography>
    </Grid>
    <Grid item lg={2} xs={5}>
      <Typography sx={content}></Typography>
    </Grid>
    <Grid item lg={2.2} xs={2}>
      <Typography sx={{ ...content }}>
        ${bookingInvoice?.service_cost}
      </Typography>
    </Grid>
  </Grid>
</Grid>

                        <Grid container>
                            <Grid item lg={5}></Grid>
                        <Grid item lg={7}>

                            <Divider style={{borderColor: "#9A9797", width: "100%"}}/>
                            </Grid>

                        </Grid>



                        <Grid container >
  <Grid item lg={12} xs={12} sx={tablewrapper}>
    <Grid item lg={5} xs={false}></Grid>
    <Grid item lg={3} xs={4}>
      <Typography sx={content}>Discount</Typography>
    </Grid>
    <Grid item lg={1} xs={5}>
      <Typography sx={content}></Typography>
    </Grid>
    <Grid item lg={2} xs={2}>
      <Typography sx={content}>
        {parseFloat(bookingInvoice?.discount) > 0 && "-"} ${bookingInvoice?.discount}
      </Typography>
    </Grid>
  </Grid>
</Grid>


                        <Grid container>
                            <Grid item lg={5}></Grid>
                        <Grid item lg={7}>

                            <Divider style={{borderColor: "#9A9797", width: "100%"}}/>
                            </Grid>

                        </Grid>


                        <Grid container>
  <Grid item lg={12} xs={12} sx={tablewrapper}>
    <Grid item lg={5} xs={false}></Grid>
    <Grid item lg={3} xs={4}>
      <Typography sx={{ ...content, fontFamily: 'helvetica-world-bold' }}>Amount</Typography>
    </Grid>
    <Grid item lg={1} xs={5}>
      <Typography sx={content}></Typography>
    </Grid>
    <Grid item lg={2} xs={2}>
      <Typography sx={{ ...content, fontFamily: 'helvetica-world-bold' }}>
        ${bookingInvoice?.amount_after_discount}
      </Typography>
    </Grid>
  </Grid>
</Grid>

                        <Grid container>
                            <Grid item lg={5}></Grid>
                        <Grid item lg={7}>

                            <Divider style={{borderColor: "#9A9797", width: "100%"}}/>
                            </Grid>

                        </Grid>

                        <Grid container>
                            <Grid item lg={12} xs={12} sx={tablewrapper}>
                                <Grid item lg={5.7} xs={false}></Grid>
                                <Grid item lg={3} xs={4}>
                                    <Typography sx={content}>Tax</Typography>
                                </Grid>
                                <Grid item lg={2} xs={5}>
                                    <Typography sx={content}></Typography>
                                </Grid>
                                <Grid item lg={2.15} xs={2}>
                                    <Typography sx={content}>${bookingInvoice?.tax}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={5}></Grid>
                        <Grid item lg={7}>

                            <Divider style={{borderColor: "#9A9797", width: "100%"}}/>
                            </Grid>

                        </Grid>



                        <Grid container>
                            <Grid item lg={12} xs={12} sx={tablewrapper}>
                                <Grid item lg={5.7} xs={false}></Grid>
                                <Grid item lg={3} xs={4}>
                                    <Typography sx={content}>Tip</Typography>
                                </Grid>
                                <Grid item lg={2} xs={5}>
                                    <Typography sx={content}></Typography>
                                </Grid>
                                <Grid item lg={2.15} xs={2}>
                                    <Typography sx={content}>${bookingInvoice?.tip}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={5}></Grid>
                        <Grid item lg={7}>

                            <Divider style={{borderColor: "#000000", width: "100%"}}/>
                            </Grid>

                        </Grid>


                        <Grid container>
                            <Grid item lg={12} xs={12} sx={tablewrapper}>
                                <Grid item lg={5.7} xs={false}></Grid>
                                <Grid item lg={3} xs={4}>
                                    <Typography sx={{...content, fontFamily:'helvetica-world-bold',}}>Grand Total</Typography>
                                </Grid>
                                <Grid item lg={2} xs={5}>
                                    <Typography sx={content}></Typography>
                                </Grid>
                                <Grid item lg={2.15} xs={3}>
                                    <Typography sx={{...content, fontFamily:'helvetica-world-bold',}}>${bookingInvoice?.amount_after_tax_and_tip}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={5}></Grid>
                        <Grid item lg={7}>

                            <Divider style={{borderColor: "#9A9797", width: "100%"}}/>
                            </Grid>

                        </Grid>
                        {bookingInvoice?.invoices_methods?.map((method,ind)=>(
                            <Box key={ind}>
                                <Grid container>
                                    <Grid item lg={12} xs={12} sx={tablewrapper}>
                                        <Grid item lg={5.7} xs={false}></Grid>
                                        <Grid item lg={3} xs={4}>
                                            <Typography sx={content}>{method?.payment_methods}</Typography>
                                        </Grid>
                                        <Grid item lg={2} xs={5}>
                                            <Typography sx={content}></Typography>
                                        </Grid>
                                        <Grid item lg={2.15} xs={3}>
                                            <Typography sx={content}> {method?.payment_methods !== 'Future Payment' && "-"}${method?.amount}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                            <Grid item lg={5}></Grid>
                        <Grid item lg={7}>

                            <Divider style={{borderColor: "#9A9797", width: "100%"}}/>
                            </Grid>

                        </Grid>
                            </Box>
                        )) }

                        <Grid container style={{marginBottom: '60px'}}>
                            <Grid item lg={12} xs={12} sx={tablewrapper}>
                                <Grid item lg={5.7} xs={false}></Grid>
                                <Grid item lg={3} xs={4}>
                                    <Typography sx={subheading}>Due Amount</Typography>
                                </Grid>
                                <Grid item lg={2} xs={5}>
                                    <Typography sx={subheading}></Typography>
                                </Grid>
                                <Grid item lg={2.15} xs={2}>
                                    <Typography sx={subheading}>${bookingInvoice?.due_amount}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>

                    <div style={{width: "100%"}}>
                        <Box sx={subfooter}>
                            <Typography>3dlifestyle.ca</Typography>
                            <Divider
                                orientation="vertical"
                                sx={{borderColor: "#9A9797", width: "20px", height: "20px"}}
                            />
                            <Typography>care@3dlifestyle.ca </Typography>
                            <Divider
                                orientation="vertical"
                                sx={{borderColor: "#9A9797", width: "20px", height: "20px"}}
                            />
                            <Typography>416-850-0231</Typography>
                        </Box>
                    </div>
                </Box>
            </div>
        </>
    );
};
  export default Invoices;